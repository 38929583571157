import * as type from '../actions/types';
import { getWidgetSettings } from '../models/Restaurant';

const initialState = {
  data: {},
  error: '',
  pending: true,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.RESTAURANT_FOR_SLUG_PENDING: {
      return {
        ...state,
        error: '',
        pending: true,
        success: false,
      };
    }

    case type.RESTAURANT_FOR_SLUG_SUCCESS: {
      return {
        ...state,
        data: getWidgetSettings(action.payload.data),
        pending: false,
        success: true,
      };
    }

    case type.RESTAURANT_FOR_SLUG_FAILURE: {
      return {
        ...state,
        data: getWidgetSettings(), // let's use the default theming for the error screen
        pending: false,
        error: action.payload,
      };
    }

    case type.SET_RESTAURANT: {
      return {
        ...state,
        data: getWidgetSettings(action.payload),
      };
    }

    default: {
      return state;
    }
  }
};
