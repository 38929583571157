import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { updateBookingAction } from '../../data/actions/bookingAction';
import { fetchObeeAvailabilitySuggestionsAction } from '../../data/actions/obeeAvailabilitySuggestionsAction';
import { freeBookingAction, holdBookingAction } from '../../data/actions/obeeBookingAction';
import { graphQLQuery } from '../../data/api/ApiClient';
import { availabilitySuggestions } from '../../data/graphql/queries';
import { bookingKeys, bookingPropTypes } from '../../data/models/Booking';
import { restaurantPropTypes } from '../../data/models/Restaurant';
import { useDidUpdateEffect } from '../../utils/hooks';

/**
 * Modal when the user's booking hold has timed out.
 * Tries to continue their booking with a fresh table matching the time, if possible
 *
 * @param open
 * @param booking
 * @param fetchObeeAvailabilitySuggestions
 * @param updateBooking
 * @returns {JSX.Element}
 * @constructor
 */
const TimedOut = ({
  open,
  onReset,
  booking,
  updateBooking,
  holdBooking,
  freeBooking,
  restaurant,
}) => {
  const [refreshingAvailability, setRefreshingAvailability] = useState(false);
  const loading = refreshingAvailability;

  const startOver = () => {
    // Avoid edge case if both buttons clicked in quick succession
    if (loading) {
      return;
    }

    window.location.reload();
  };

  // Do a fresh availability check and see if there are any tables for the same time and area as before
  const refreshAvailability = async () => {
    // If no time selected, just reset
    if (!booking?.availabilitySuggestion?.time) {
      startOver();
      return;
    }

    setRefreshingAvailability(true);

    // Note: We're not using redux here because we don't want to refresh the list of availabilities shown to the user
    const response = await graphQLQuery(
      availabilitySuggestions,
      {
        areaId: booking.availabilitySuggestion.table.areaId,
        date: booking.date,
        times: [booking.availabilitySuggestion.time],
        size: booking.guests,
        length: 100,
        public: true, // public is for online booking / general availability
      },
      true,
    );

    setRefreshingAvailability(false);

    const suggestion = response.data.availabilitySuggestions.find(
      (availabilitySuggestion) =>
        availabilitySuggestion?.time === booking?.availabilitySuggestion.time,
    );

    // if preferred time availability suggestion exists let's set it for them
    if (suggestion) {
      updateBooking(bookingKeys.availabilitySuggestion, {
        ...suggestion,
        allAreas: booking.areaId === -1,
        waitlist: false,
      });

      if (booking.heldBookingId) {
        // Hold the new booking
        // TODO optimise this to not need to import areas. e.g. use a helper
        holdBooking(
          booking,
          suggestion.table.areaId,
          suggestion.table.tableNumber,
          restaurant.data.areas.find((area) => area.id === suggestion.table.areaId)?.duration,
        );
      }

      // If failed to hold the new booking, reset the process
      onReset();
    } else {
      startOver();
    }
  };

  // Free the booking when this opens
  useDidUpdateEffect(() => {
    if (open) {
      freeBooking(booking?.heldBookingId);
    }
  }, [open]);

  return (
    <Modal
      showHeader={false}
      title='Session has timed out'
      open={open}
      onClose={refreshAvailability}
    >
      <Spacer direction='vertical' gap={48}>
        <Typography>
          Your session has timed out. Do you still want to continue making your booking?
        </Typography>
        <Spacer style={{ alignItems: 'flex-end' }}>
          <Spacer direction='vertical'>
            <Typography style={{ opacity: loading ? 1 : 0, transition: 'opacity 0.5s' }}>
              Checking availability...
            </Typography>
            <Button size='small' color='primary' onClick={refreshAvailability} loading={loading}>
              Continue booking
            </Button>
          </Spacer>
          <Button variant='outlined' size='small' onClick={startOver} disabled={loading}>
            Start over
          </Button>
        </Spacer>
      </Spacer>
    </Modal>
  );
};

TimedOut.defaultProps = {
  open: false,
};

TimedOut.propTypes = {
  booking: bookingPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  holdBooking: PropTypes.func.isRequired,
  freeBooking: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  booking: state.booking,
  obeeAvailabilitySuggestions: state.obeeAvailabilitySuggestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchObeeAvailabilitySuggestions: fetchObeeAvailabilitySuggestionsAction,
      updateBooking: updateBookingAction,
      holdBooking: holdBookingAction,
      freeBooking: freeBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimedOut);
