import { ThemeProvider } from '@material-ui/core';
import { ErrorBoundary } from '@rollbar/react';
import { Amplify } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import packageInfo from '../package.json';
import './App.css';
import eatclubIco from './assets/favicons/eatclub.ico';
import obeeIco from './assets/favicons/obee.ico';
import awsconfig from './aws-exports';
import { LegacyRedirect } from './components/LegacyRedirect';
import { Toast } from './components/Toast';
import {
  fetchObeeIntegrationsAction,
  initializeFacebookAction,
} from './data/actions/integrationsAction';
import { integrationsPropTypes } from './data/models/Integrations';
import { restaurantPropTypes } from './data/models/Restaurant';
import SwitchRoutes from './routes/SwitchRoutes';
import { ErrorDisplay } from './screens/ErrorDisplay';
import { createTheme, typography } from './theme';
import { trackGooglePageview } from './utils/analytics';
import { isObee } from './utils/constants';
import { isEmpty, setFavicon } from './utils/helpers';

Amplify.configure(awsconfig);

const App = ({ integrations, initializeFacebook, fetchObeeIntegrations, restaurant }) => {
  useEffect(() => {
    if (restaurant.data.backgroundType === 'image') {
      window.document.body.style.backgroundImage = `url(${restaurant.data.backgroundImageUrl})`;
      window.document.body.style.backgroundSize = `cover`;
      window.document.body.style.backgroundRepeat = 'no-repeat';
      window.document.body.style.backgroundPosition = 'center';
      window.document.body.style.backgroundAttachment = 'fixed';
    } else {
      window.document.body.style.background = restaurant.data.backgroundValue;
    }
  }, [
    restaurant.data.backgroundType,
    restaurant.data.backgroundValue,
    restaurant.data.backgroundImageUrl,
  ]);

  useEffect(() => {
    window.document.body.style.fontFamily = `'${restaurant.data.generalFontFamilies}', 'Open Sans', 'Helvetica', 'sans-serif'`;
  }, [restaurant.data.generalFontFamilies]);

  useEffect(() => {
    window.document.body.style.color = restaurant.data.mainTextColour;
  }, [restaurant.data.mainTextColour]);

  useEffect(() => {
    // set some defaults
    window.document.body.style.fontFamily = `'${typography.generalFontFamily}', 'Open Sans', 'Helvetica', 'sans-serif'`;
    setFavicon(isObee ? obeeIco : eatclubIco);
  }, []);

  /**
   * When a restaurant is loaded, try to initialize GTM
   */
  useEffect(() => {
    if (restaurant.success && !isEmpty(restaurant.data)) {
      if (restaurant.data.googleTagManager) {
        ReactGA.initialize(restaurant.data.googleTagManager, {
          name: 'venueGA',
          appVersion: packageInfo.version,
        });
      } else {
        // fallback to ec gtm
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
          name: 'ECGA',
          appVersion: packageInfo.version,
        });
      }
      trackGooglePageview();
    }
  }, [restaurant.success]);

  /**
   * When a restaurant is loaded, try to get its facebook pixel
   */
  useEffect(() => {
    if (restaurant.success && !isEmpty(restaurant.data)) {
      if (isEmpty(integrations.data)) {
        fetchObeeIntegrations();
      }
    }
  }, [restaurant.success]);

  /**
   * Initialize facebook tracking once we have the data
   */
  useEffect(() => {
    if (integrations?.facebookPixel) {
      return;
    }

    // If facebook integration available, initialise the tracking
    const facebookIntegration = integrations?.data?.find(
      (integration) => integration?.id === 'Meta',
    );

    if (facebookIntegration?.active && facebookIntegration?.data?.pixel_id) {
      initializeFacebook(facebookIntegration?.data?.pixel_id);
    }
  }, [integrations?.data]);

  // theme is dependant on what the booking widget prem settings provide
  const appTheme = useMemo(
    () =>
      createTheme(
        restaurant.data.generalFontFamilies,
        restaurant.data.availabilityFontFamilies,
        restaurant.data.availabilityMainButtonBgColour,
        restaurant.data.availabilityMainButtonTextColour,
        restaurant.data.availabilityMainButtonCornerRadius,
        restaurant.data?.mainTextColour,
        restaurant.data?.outlineColour,
        restaurant.data?.activeOutlineColour,
        restaurant.data?.availabilityBackgroundBoxBgColour,
        restaurant.data.darkTheme,
        restaurant.data?.widgetHeaderBgColor,
        restaurant.data?.secondaryTextColour,
        restaurant.data?.secondaryTextOpacity,
        restaurant.data?.outlineOpacity,
        restaurant.data?.availabilityFieldsActiveStateColour,
        restaurant.data?.availabilityFieldsColourOpacity,
      ),
    [
      restaurant.data.generalFontFamilies,
      restaurant.data.availabilityFontFamilies,
      restaurant.data.availabilityMainButtonBgColour,
      restaurant.data.availabilityMainButtonTextColour,
      restaurant.data.availabilityMainButtonCornerRadius,
      restaurant.data?.mainTextColour,
      restaurant.data?.outlineColour,
      restaurant.data?.activeOutlineColour,
      restaurant.data?.availabilityBackgroundBoxBgColour,
      restaurant.data?.darkTheme,
      restaurant.data?.widgetHeaderBgColor,
      restaurant.data?.secondaryTextColour,
      restaurant.data?.secondaryTextOpacity,
      restaurant.data?.outlineOpacity,
    ],
  );

  return (
    <ThemeProvider theme={appTheme}>
      <ErrorBoundary fallbackUI={({ error }) => <ErrorDisplay error={error} />}>
        <LegacyRedirect>
          <SwitchRoutes />
        </LegacyRedirect>
        <Toast />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

App.propTypes = {
  integrations: integrationsPropTypes.isRequired,
  fetchObeeIntegrations: PropTypes.func.isRequired,
  initializeFacebook: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  integrations: state.integrations,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initializeFacebook: initializeFacebookAction,
      fetchObeeIntegrations: fetchObeeIntegrationsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
