/**
 * Gets the flex direction based on the direction given
 * @param {"vertical"|"horizontal"|string} flexDirection
 * @returns
 */
export const getDirection = (flexDirection) => {
  switch (flexDirection) {
    case 'vertical':
      return 'column';
    case 'horizontal':
    default:
      return 'row';
  }
};

/**
 * Gets the flex gap size in px based on the size given
 * @param {"l"|"m"|"s"|number} size
 * @returns
 */
export const getGap = (size) => {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  switch (size) {
    case 'l':
      return '32px';
    case 'm':
      return '16px';
    case 's':
    default:
      return '8px';
  }
};
