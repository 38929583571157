import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    margin: '12px auto',
    maxWidth: '644px',
    width: '100%',
  },
}));

export default useStyles;
