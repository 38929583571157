import { graphQLQuery } from '../api/ApiClient';
import { availabilitySuggestionsForMultiVenue } from '../graphql/queries';
import { cancelPendingApiAction, makeApiAction } from './shared/helpers';
import * as type from './types';

/**
 * Availability suggestion search for multi venues
 * @param {string} date
 * @param {[int]} times The list of session start times
 * @param {int} size The amount of guests
 * @returns
 */
export const fetchObeeAvailabilitySuggestionsForMultiVenueAction =
  (date, times, size) => (dispatch) => {
    const request = (variables) => graphQLQuery(availabilitySuggestionsForMultiVenue, variables);

    makeApiAction(
      'FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS',
      request,
      {
        date,
        times,
        size,
        length: 100,
      },
      'availabilitySuggestionsForMultiVenue',
      "an error occurred while retrieving the linked venue's available times.",
      dispatch,
      null,
      null,
      true,
      true,
    );
  };

/**
 * Clears the state and cancels pending requests (we don't want the request to resolve if we're making changes to the booking)
 */
export const clearObeeAvailabilitySuggestionsForMultiVenueAction = () => (dispatch) => {
  const now = new Date().getTime();
  cancelPendingApiAction('FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS', now);

  dispatch({
    type: type.CLEAR_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS,
  });
};
