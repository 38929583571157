import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { PageLayout } from '../../layouts/PageLayout';
import { WidgetLayout } from '../../layouts/WidgetLayout';

const UserErrorDisplay = ({ title, description }) => {
  const rollbar = useRollbar();
  rollbar.warn(title);

  return (
    <PageLayout>
      <WidgetLayout>
        <Spacer direction='vertical' gap='m' centered>
          {/* icon */}
          <Typography centered variant='h2'>
            {title}
          </Typography>
          <Typography centered>{description}</Typography>
        </Spacer>
      </WidgetLayout>
    </PageLayout>
  );
};

UserErrorDisplay.propTypes = {
  title: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired,
};

export default UserErrorDisplay;
