import { Box } from '@eatclub-apps/ec-component-library';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../components/Button';
import { ConditionalWrapper } from '../../../components/ConditionalWrapper';
import { Spacer } from '../../../components/Spacer';
import { Typography } from '../../../components/Typography';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import { fontWeights } from '../../../theme';
import { isEmptyString } from '../../../utils/helpers';
import { useIsMobile } from '../../../utils/hooks';
import { FAQsPopover } from './FAQsPopover';
import useStyles from './RestaurantDetailsStyles';

const RestaurantDetails = ({ restaurant }) => {
  const classes = useStyles({
    navigationBgColour: restaurant.data.navigationBgColour,
    navigationBgColourTransparency: restaurant.data.navigationBgColourTransparency,
  });
  const isMobile = useIsMobile();
  const [contactAnchorEl, setContactAnchorEl] = useState(null);

  const isLoading = restaurant.pending;

  const onContactClose = () => setContactAnchorEl(null);

  const getLogo = (direction = 'horizontal', centered = false) => {
    return (
      <Spacer wrap style={{ gap: '8px 16px' }} direction={direction} centered={centered}>
        <img
          alt={`${restaurant.data.name} logo`}
          src={restaurant.data.navigationLogoUrl}
          style={{ maxHeight: isMobile ? '48px' : '56px', maxWidth: '200px', objectFit: 'contain' }}
          title={restaurant.data.name}
        />

        {restaurant.data.navigationShowVenueName && (
          <ConditionalWrapper
            condition={isLoading}
            wrapper={(children) => <Skeleton>{children}</Skeleton>}
          >
            <Typography style={{ color: restaurant.data.navigationVenueNameTextColour }}>
              {restaurant.data.name}
            </Typography>
          </ConditionalWrapper>
        )}
      </Spacer>
    );
  };

  const getLiteVenueName = () => {
    return (
      <ConditionalWrapper
        condition={isLoading || !isEmptyString(restaurant.error)}
        wrapper={() => null}
      >
        <Typography
          variant='h3'
          style={{
            color: restaurant.data.navigationVenueNameTextColour,
            fontWeight: fontWeights.medium,
            textAlign: 'left',
          }}
        >
          {restaurant.data.name}
        </Typography>
      </ConditionalWrapper>
    );
  };

  const getNeedHelpBtn = () => {
    return (
      <ConditionalWrapper
        //  todo: if restaurant error have bookings support contact?
        condition={
          isLoading || !restaurant.data.navigationShowNeedHelp || !isEmptyString(restaurant.error)
        }
        wrapper={() => null}
      >
        <Button
          onClick={(e) => setContactAnchorEl(e.currentTarget)}
          style={{
            color: restaurant.data.navigationNeedHelpButtonTextColour,
            border: `1px solid ${restaurant.data.navigationNeedHelpButtonBorderColour}`,
          }}
          variant='outlined'
        >
          Need help?
        </Button>
      </ConditionalWrapper>
    );
  };

  const getMobileHeader = () => {
    return (
      <Box className={classes.items} style={{ gridTemplateColumns: 'auto auto' }}>
        <Box style={{ margin: 'auto auto auto 0' }}>
          {restaurant.data.navigationLogoUrl ? getLogo() : getLiteVenueName()}
        </Box>

        <Box style={{ margin: 'auto 0 auto auto' }}>{getNeedHelpBtn()}</Box>
      </Box>
    );
  };

  const getDesktopHeader = () => {
    return (
      <Box className={classes.items} style={{ gridTemplateColumns: '1fr auto 1fr' }}>
        <Box style={{ margin: 'auto auto auto 0' }}>
          {restaurant.data.navigationLogoUrl &&
          restaurant.data.navigationLogoAlignment !== 'middle' ? (
            getLogo()
          ) : (
            <ConditionalWrapper
              condition={isLoading || !restaurant.data.navigationShowHeaderText}
              wrapper={() => null}
            >
              <Typography
                variant='h3'
                style={{ color: restaurant.data.navigationHeaderTextColour }}
              >
                {restaurant.data.navigationHeaderText}
              </Typography>
            </ConditionalWrapper>
          )}
        </Box>

        <Box style={{ margin: 'auto', textAlign: 'center' }}>
          {restaurant.data.navigationLogoUrl
            ? restaurant.data.navigationLogoAlignment === 'middle' && getLogo('vertical', true)
            : getLiteVenueName()}
        </Box>

        <Box style={{ margin: 'auto 0 auto auto' }}>{getNeedHelpBtn()}</Box>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      {isMobile ? getMobileHeader() : getDesktopHeader()}

      {restaurant.success && <FAQsPopover anchorEl={contactAnchorEl} close={onContactClose} />}
    </Box>
  );
};

RestaurantDetails.propTypes = {
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantDetails);
