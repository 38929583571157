import { useRollbar } from '@rollbar/react';
import { useEffect } from 'react';
// import { Spacer } from '../../components/Spacer';
// import { Typography } from '../../components/Typography';
// import { PageLayout } from '../../layouts/PageLayout';
import { devLog } from '../../utils/devLog';

// TODO: Should this be an actual landing page? bookings introduction, search for venues?
const Landing = () => {
  const rollbar = useRollbar();
  rollbar.warning('Navigated to /');

  devLog('warning', 'Whoops!', 'Navigated to /');

  useEffect(() => {
    // for dev purposes
    if (process.env.REACT_APP_MODE === 'development' && process.env.REACT_APP_DEFAULT_SLUG) {
      window.location.href = process.env.REACT_APP_DEFAULT_SLUG;
      return null;
    }

    window.location.replace(process.env.REACT_APP_BOOKINGS_REDIRECT);
    return null;
  }, []);

  return null;

  // return (
  //   <PageLayout>
  //     <Spacer direction='vertical' centered>
  //       {/* icon */}
  //       <Typography variant='h3'>Whoops, something went wrong.</Typography>
  //       <Typography centered>
  //         Please either check the URL is correct or contact the restaurant if there is an issue.
  //       </Typography>
  //     </Spacer>
  //   </PageLayout>
  // );
};

export default Landing;
