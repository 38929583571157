import { combineReducers } from 'redux';
import bookingReducer from './bookingReducer';
import bookingTermsReducer from './bookingTermsReducer';
import integrationsReducer from './integrationsReducer';
import loadingReducer from './loadingReducer';
import messagesReducer from './messagesReducer';
import obeeAvailabilitySuggestionsReducer from './obeeAvailabilitySuggestionsReducer';
import obeeBookingReducer from './obeeBookingReducer';
import obeeMultiVenueAvailabilitySuggestionsReducer from './obeeMultiVenueAvailabilitySuggestionsReducer';
import paymentSettingsReducer from './paymentSettingsReducer';
import restaurantReducer from './restaurantReducer';
import restaurantsReducer from './restaurantsReducer';

// App's top-level reducers
const appReducer = combineReducers({
  booking: bookingReducer,
  bookingTerms: bookingTermsReducer,
  integrations: integrationsReducer,
  loading: loadingReducer,
  messages: messagesReducer,
  obeeAvailabilitySuggestions: obeeAvailabilitySuggestionsReducer,
  obeeMultiVenueAvailabilitySuggestions: obeeMultiVenueAvailabilitySuggestionsReducer,
  obeeBooking: obeeBookingReducer,
  paymentSettings: paymentSettingsReducer,
  restaurant: restaurantReducer,
  restaurants: restaurantsReducer,
});

export default appReducer;
