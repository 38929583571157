import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useRollbar } from '@rollbar/react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ConditionalWrapper } from '../../../../components/ConditionalWrapper';
import { Spacer } from '../../../../components/Spacer';
import { TextField } from '../../../../components/TextField';
import { Typography } from '../../../../components/Typography';
import { createPaymentMethodAction } from '../../../../data/actions/stripeAction';
import { trackEvent } from '../../../../utils/analytics';
import { ERROR_MESSAGES } from '../../../../utils/constants';
import { isEmpty } from '../../../../utils/helpers';
import { useIsMobile } from '../../../../utils/hooks';
import useStyles from '../ReviewStyles';

/* eslint-disable */
const StripeInput = ({ component: Component, disabled, inputRef, ...props }) => {
  const theme = useTheme();
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      options={{
        showIcon: true,
        placeholder: '',
        disabled,
        style: {
          base: {
            fontSize: '16px',
            fontFamily: `'${theme.baseTypography.availabilityFontFamily}', 'Open Sans', 'Helvetica', 'sans-serif'`,
            color: disabled
              ? 'rgba(0, 0, 0, 0.38)'
              : theme.colors.mainTextColour ?? theme.colors.charcoal,
          },
        },
      }}
      {...props}
    />
  );
};

const StripeFields = ({ paymentRef, phone, email, paymentMethodName, confirmingBooking }) => {
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useIsMobile();
  const rollbar = useRollbar();

  const [cardName, setCardName] = useState(null);

  // Errors
  const [cardNameErrors, setCardNameErrors] = useState(null);
  const [cardNumberErrors, setCardNumberErrors] = useState(null);
  const [cardExpiryErrors, setCardExpiryErrors] = useState(null);
  const [cardCCVErrors, setCardCCVErrors] = useState(null);

  const classes = useStyles();

  const hasPaymentMethod = !isEmpty(paymentMethodName);

  const handlePaymentClicked = async () => {
    let valid = true;

    if (!stripe || !elements) {
      throw new Error('stripe is undefined');
    }

    if (isEmpty(cardName)) {
      valid = false;
      rollbar.warning(`Invalid card name: ${cardName}`);
      setCardNameErrors(ERROR_MESSAGES.requiredField);
    } else {
      setCardNameErrors(null);
    }

    if (!isEmpty(cardNumberErrors)) {
      valid = false;
    }

    if (!isEmpty(cardExpiryErrors)) {
      valid = false;
    }

    if (!isEmpty(cardCCVErrors)) {
      valid = false;
    }

    if (!valid) {
      return { valid: false };
    }

    const [paymentMethodId, paymentMethodName, setupIntentId] = await createPaymentMethodAction(
      cardName,
      phone,
      email,
      null,
      null,
      stripe,
      elements.getElement(CardNumberElement),
    );

    return {
      valid,
      cardName,
      token: paymentMethodId,
      cardNumber: paymentMethodName,
      setupIntentId,
    };
  };

  // IoC so each payment provider has its own way of submitting
  useImperativeHandle(paymentRef, () => ({
    submit: async () => handlePaymentClicked(),
  }));

  const textFieldStyles = {
    input: { fontSize: 'inherit', padding: '8px 10px', paddingLeft: '10px' },
  };

  return (
    <Spacer direction='vertical'>
      <TextField
        onFocus={() => trackEvent('Booking', 'Updating Credit Card Name')}
        autoFocus
        required
        error={!isEmpty(cardNameErrors)}
        helperText={<Typography variant='caption'>{cardNameErrors}</Typography>}
        label='Name on card'
        value={hasPaymentMethod ? '****' : cardName}
        onChange={(e) => setCardName(e.target.value)}
        InputProps={{
          disableUnderline: true,
        }}
        onBlur={(e) => {
          if (!isEmpty(cardNameErrors) && !isEmpty(e.target.value)) {
            setCardNameErrors(null);
          }
        }}
        disabled={hasPaymentMethod || confirmingBooking}
        autoComplete='cc-name'
        name='cardName'
      />
      <Box>
        <Spacer wrap={isMobile}>
          <TextField
            onFocus={() => trackEvent('Booking', 'Updating Credit Card Number')}
            value={hasPaymentMethod ? paymentMethodName : null}
            required
            error={!isEmpty(cardNumberErrors)}
            label='Card number'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              disableUnderline: true,
              ...(hasPaymentMethod
                ? {
                    inputProps: { disabled: confirmingBooking },
                  }
                : {
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardNumberElement,
                      onChange: (e) => setCardNumberErrors(e.error?.message || null),
                      disabled: hasPaymentMethod || confirmingBooking,
                    },
                  }),
            }}
            disabled={hasPaymentMethod || confirmingBooking}
          />
          <ConditionalWrapper
            condition={isMobile}
            wrapper={(children) => <Spacer style={{ width: '100%' }}>{children}</Spacer>}
          >
            <TextField
              onFocus={() => trackEvent('Booking', 'Updating Credit Card MM/YY')}
              value={hasPaymentMethod ? '**/**' : null}
              required
              style={{ maxWidth: isMobile ? '100%' : '90px' }}
              error={!isEmpty(cardExpiryErrors)}
              label='MM/YY'
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                ...(hasPaymentMethod
                  ? {
                      inputProps: { disabled: confirmingBooking },
                    }
                  : {
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardExpiryElement,
                        onChange: (e) => setCardExpiryErrors(e.error?.message || null),
                        disabled: hasPaymentMethod || confirmingBooking,
                      },
                    }),
              }}
              disabled={hasPaymentMethod || confirmingBooking}
            />
            <TextField
              onFocus={() => trackEvent('Booking', 'Updating Credit Card CCV')}
              value={hasPaymentMethod ? '***' : null}
              required
              style={{ maxWidth: isMobile ? '100%' : '90px' }}
              error={!isEmpty(cardCCVErrors)}
              label='CCV'
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                ...(hasPaymentMethod
                  ? {
                      inputProps: { disabled: confirmingBooking },
                    }
                  : {
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardCvcElement,
                        onChange: (e) => setCardCCVErrors(e.error?.message || null),
                        disabled: hasPaymentMethod || confirmingBooking,
                      },
                    }),
              }}
              disabled={hasPaymentMethod || confirmingBooking}
            />
          </ConditionalWrapper>
        </Spacer>

        {/* Stripe errors */}
        <Typography variant='caption' className={classes.errorText}>
          {cardNumberErrors || cardExpiryErrors || cardCCVErrors}
        </Typography>
      </Box>
    </Spacer>
  );
};

StripeFields.defaultProps = {
  paymentRef: null,
  phone: null,
  email: null,
  confirmingBooking: null,
  paymentMethodName: null,
};

StripeFields.propTypes = {
  paymentRef: PropTypes.shape({}),
  phone: PropTypes.string,
  email: PropTypes.string,
  confirmingBooking: PropTypes.string,
  paymentMethodName: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StripeFields);
