// Export prop types for easy import in components that use this data
import PropTypes from 'prop-types';

export const paymentSettingPropTypes = PropTypes.shape({
  id: PropTypes.number,
  areaId: PropTypes.number,
  date: PropTypes.string,
  dateMax: PropTypes.string,
  day: PropTypes.string,
  timeOption: PropTypes.string,
  time: PropTypes.string,
  timeMax: PropTypes.string,
  sizeOption: PropTypes.string,
  size: PropTypes.number,
  sizeMax: PropTypes.number,
  source: PropTypes.string,
  mode: PropTypes.string,
  noShowAmount: PropTypes.number,
  noShowOption: PropTypes.string,
  depositAmount: PropTypes.number,
  depositOption: PropTypes.string,
  fullAmount: PropTypes.number,
  fullOption: PropTypes.string,
  currency: PropTypes.string,
  incGst: PropTypes.bool,
  title: PropTypes.string,
  shortDesc: PropTypes.string,
  policyUrl: PropTypes.string,
  policyText: PropTypes.string,
  staffPayRequired: PropTypes.bool,
  usedBy: PropTypes.string,
  priority: PropTypes.number,
  deleted: PropTypes.bool,
  publishableKey: PropTypes.string,
  pinMode: PropTypes.string,
});

export const paymentSettingsPropTypes = PropTypes.shape({
  data: paymentSettingPropTypes,
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});
