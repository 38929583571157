import { Checkbox, Divider } from '@eatclub-apps/ec-component-library';
import { Box, useTheme } from '@material-ui/core';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../components/Button';
import { Spacer } from '../../../components/Spacer';
import { Typography } from '../../../components/Typography';
import {
  batchUpdateBookingAction,
  updateBookingStepAction,
} from '../../../data/actions/bookingAction';
import { fetchBookingTermsAction } from '../../../data/actions/bookingTermsAction';
import { addMessageAction } from '../../../data/actions/messagesAction';
import {
  bookingUpdateCheckAction,
  createObeeBookingAction,
  updateObeeBookingAction,
} from '../../../data/actions/obeeBookingAction';
import { fetchPaymentSettingsAction } from '../../../data/actions/paymentSettingsAction';
import {
  bookingKeys,
  bookingPropTypes,
  bookingSteps,
  isEditing,
} from '../../../data/models/Booking';
import { bookingTermsPropTypes } from '../../../data/models/BookingTerms';
import { paymentSettingsPropTypes } from '../../../data/models/PaymentSettings';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import { obeeBookingPropTypes } from '../../../data/models/obeeBooking';
import { PageLayout } from '../../../layouts/PageLayout';
import { WidgetLayout } from '../../../layouts/WidgetLayout';
import { trackCompletion } from '../../../utils/analytics';
import { isObee } from '../../../utils/constants';
import { devLog } from '../../../utils/devLog';
import {
  deepEqual,
  findObjectByProperty,
  formatCurrency,
  formatDateToMoment,
  getBookingDuration,
  getSessionEndTime,
  getSessionForBooking,
  isEmpty,
  minutesToTimeString,
  sortByProperty,
} from '../../../utils/helpers';
import { useIsMobile } from '../../../utils/hooks';
import CardFields from './CardFields/CardFields';
import PaymentPolicyModal from './PaymentPolicyModal';

const Review = ({
  addMessage,
  batchUpdateBooking,
  booking, // The local booking being created/edited
  bookingTerms,
  createObeeBooking,
  obeeBooking, // The booking fetched from the API when editing
  restaurant,
  updateBookingStep,
  updateObeeBooking,
  paymentSettings,
  fetchPaymentSettings,
  fetchBookingTerms,
}) => {
  const theme = useTheme();

  // eslint-disable-next-line no-unused-vars
  const [cardName, _setCardName] = useState('');
  const [confirmingBooking, setConfirmingBooking] = useState(false);

  const paymentRef = useRef(null); // So each payment provider can handle their own validation

  const canSubscribe = restaurant.data?.showMarketingOptIn;
  const [subscribe, setSubscribe] = useState(false);
  const [showPaymentPolicyModal, setShowPaymentPolicyModal] = useState(false);

  const isMobile = useIsMobile();

  const dateFormatted = formatDateToMoment(booking.date).format('dddd, Do of MMMM');
  const timeFormatted = minutesToTimeString(booking.availabilitySuggestion?.time);

  const waitlist = booking?.waitlist;

  /**
   * Fetch payment settings if not already done (e.g. navigated directly to cc screen)
   */
  useEffect(() => {
    if (!isObee || !isEmpty(paymentSettings.data)) {
      return;
    }

    const { date, availabilitySuggestion, guests } = booking;

    const areaId = availabilitySuggestion?.table?.areaId || booking?.areaId;

    if (!areaId) {
      // todo: confirm that if we should fetch payments for waitlist, theres a flow where joining the waitlist doesn't assign a table/area, areaId is required
      return;
    }

    fetchPaymentSettings({
      paymentSettings: {
        date,
        time: availabilitySuggestion?.time,
        size: guests,
        public: 1,
        areaId,
      },
      restId: restaurant.data.objectId,
    });
  }, [
    booking?.availabilitySuggestion?.time,
    booking?.availabilitySuggestion?.table?.areaId,
    booking?.areaId,
    booking?.guests,
    booking?.date,
  ]);

  /**
   * Fetch booking terms if haven't already (e.g. navigated directly to this page)
   */
  useEffect(() => {
    // note: EatClub has the terms included in the restaurant object
    if (!isObee || !isEmpty(bookingTerms?.data?.terms_conditions)) {
      return;
    }

    const { date, availabilitySuggestion, guests } = booking;

    const areaId = availabilitySuggestion?.table?.areaId || booking?.areaId;

    const newVariables = {
      restId: restaurant.data.objectId,
      areaId,
      date,
      time: availabilitySuggestion?.time,
      size: guests,
    };

    if (deepEqual(newVariables, bookingTerms.lastUsedVariables)) {
      // the terms have been fetched
      return;
    }

    fetchBookingTerms(areaId, date, availabilitySuggestion?.time, guests);
  }, [
    booking?.availabilitySuggestion?.time,
    booking?.availabilitySuggestion?.table?.areaId,
    booking?.areaId,
    booking?.guests,
    booking?.date,
  ]);

  // TODO use same logic as bookings for paymentSettings
  const ccCapture = () => {
    if (isObee) {
      // TODO support mandatory/optional payment settings
      return !waitlist && !isEmpty(paymentSettings?.data?.publishableKey);
    }

    return (
      (!waitlist && restaurant.data.ccCapture) ||
      (waitlist && restaurant.data.ccCapture && restaurant.data.ccWaitlistShow === 'mandatory')
    );
  };

  const hasPaymentMethod = !isEmpty(obeeBooking.data.paymentMethodName);

  const getOptimalArea = () => {
    const areaForBooking = restaurant.data.areas.find(
      (area) => area.id === booking.availabilitySuggestion?.table?.areaId ?? booking?.areaId,
    );

    if (areaForBooking) {
      return areaForBooking;
    }

    // NOTE: Find the optimal area for "all areas" waitlist
    const potentialAreas = restaurant.data.areas.filter(
      (area) => area.minGroup <= booking.guests && area.maxGroup >= booking.guests,
    );

    return potentialAreas.sort((a, b) => sortByProperty(a, b, 'priority'))[0];
  };

  const getAreaId = () => {
    if (booking.availabilitySuggestion?.table) {
      return booking.availabilitySuggestion?.table?.areaId;
    }

    if (booking.areaId !== -1) {
      return booking.areaId;
    }

    return getOptimalArea()?.id;
  };

  // Pay through payment provider
  const showCreditCardFields = isEditing(booking)
    ? ccCapture() && !hasPaymentMethod && obeeBooking.data?.requiresCC // Editing and needs CC details
    : ccCapture(); // Not editing, depends on payment method fetched

  const confirmBooking = async () => {
    setConfirmingBooking(true);

    const areaId = getAreaId();
    const area = getOptimalArea(areaId);
    let tableNumber = booking.availabilitySuggestion?.table?.tableNumber;

    const duration = getBookingDuration(
      area,
      restaurant.data.areas,
      booking.availabilitySuggestion.time,
    );

    let { paymentMethodId, paymentMethodName, setupIntentId } = booking;

    try {
      if (booking.editBooking) {
        const { available, tables } = await bookingUpdateCheckAction(
          obeeBooking.data.id,
          areaId,
          booking.date,
          booking.availabilitySuggestion?.time,
          booking.guests,
          tableNumber,
          obeeBooking.data.duration,
        );

        if (!available) {
          if (isEmpty(tables)) {
            // No other tables available
            addMessage(
              `CONFIRM_BOOKING_${new Date().getTime()}`,
              `Sorry, the booking on ${dateFormatted} for ${booking.guests} guests at ${timeFormatted} is currently unavailable.`,
              'error',
              'Booking update unavailable',
              'confirmBooking',
              {
                bookingId: obeeBooking.data.id,
                areaId,
                date: booking.date,
                time: booking.availabilitySuggestion?.time,
                size: booking.guests,
                tableNumber,
                duration,
              },
            );

            setConfirmingBooking(false);
            return;
          }
          // Another table is available
          tableNumber = tables?.[0]?.table?.table_number;
        }
      }

      if (showCreditCardFields) {
        const paymentResponse = await paymentRef?.current?.submit();

        paymentMethodId = paymentResponse?.token;
        paymentMethodName = paymentResponse?.cardNumber;
        setupIntentId = paymentResponse?.setupIntentId;

        if (!paymentResponse?.valid) {
          devLog('info', 'paymentResponse', 'invalid response...');
          setConfirmingBooking(false);
          addMessage(
            `CONFIRM_BOOKING_${new Date().getTime()}`,
            `Failed to submit credit card details`,
            'error',
            `Failed to submit credit card details`,
            'confirmBooking',
            { cardName, ...booking },
          );
          return;
        }

        batchUpdateBooking({
          [bookingKeys.cardName]: paymentResponse?.cardName,
          [bookingKeys.paymentMethodId]: paymentMethodId,
          [bookingKeys.paymentMethodName]: paymentMethodName,
          [bookingKeys.setupIntentId]: setupIntentId,
        });
      }

      const OBBooking = {
        areaId: waitlist ? null : areaId,
        date: booking.date,
        time: booking.availabilitySuggestion?.time,
        size: booking.guests,
        guests: {
          firstName: booking.firstName,
          lastName: booking.lastName,
          email: booking.email,
          mobile: booking.mobile,
          smsPromo: subscribe,
          emailPromo: subscribe,
        },
        name: `${booking.firstName} ${booking.lastName}`,
        customerComments: booking.specialRequirements,
        tableNumber: tableNumber || null,
        highchairs: booking.highchairs,
        kids: booking.kids,
        waitlist,
        paymentMethodId,
        paymentMethodName,
        setupIntentId,
        ...(isObee && {
          cardToken: paymentMethodId,
          paymentSettingId: paymentSettings?.data?.id || null,
          ccMode: paymentSettings?.data?.mode || null,
        }),
        duration,
        ...(booking.heldBookingId && {
          heldBookingId: booking.heldBookingId,
        }),
      };

      if (booking.editBooking) {
        updateObeeBooking(obeeBooking.data.id, OBBooking);
      } else {
        createObeeBooking(OBBooking);
      }
    } catch (error) {
      addMessage(
        `CONFIRM_BOOKING_${new Date().getTime()}`,
        `${error}`,
        'error',
        `${error}`,
        'confirmBooking',
        { cardName, ...booking },
      );

      setConfirmingBooking(false);
    }
  };

  useEffect(() => {
    if (confirmingBooking && obeeBooking.success) {
      setConfirmingBooking(false);

      updateBookingStep(bookingSteps.confirm);
    }
  }, [obeeBooking.success]);

  useEffect(() => {
    if (confirmingBooking && obeeBooking.error) {
      addMessage(
        `CONFIRM_BOOKING_${new Date().getTime()}`,
        "Sorry, we're unable to confirm the booking right now. Please try again later.",
        'error',
      );

      setConfirmingBooking(false);
    }
  }, [obeeBooking.error]);

  const getTerms = () => {
    if (isObee) {
      return (
        <Typography style={{ whiteSpace: 'pre-line' }}>
          <div dangerouslySetInnerHTML={{ __html: bookingTerms.data.terms_conditions }} />
        </Typography>
      );
    }
    try {
      const contentState = convertFromRaw(JSON.parse(restaurant.data.terms));
      const editorState = EditorState.createWithContent(contentState);

      return <Editor editorState={editorState} readOnly />;
    } catch {
      return null;
    }
  };

  const hasImportantInformation =
    (isObee && bookingTerms.data.terms_exist) || (!isObee && !isEmpty(restaurant.data.terms));

  const hasCCTerms = waitlist
    ? !isEmpty(restaurant.data.ccWaitlistPolicy)
    : !isEmpty(restaurant.data.ccPaymentPolicy);

  const hasTerms = hasImportantInformation || hasCCTerms;

  /**
   * Work out how much to charge the guest.
   * TODO test this for ECB
   */
  const getDepositAmount = () => {
    if (paymentSettings.data.mode === 'full') {
      if (paymentSettings.data.fullOption === 'per_person') {
        return paymentSettings.data.fullAmount * booking.guests;
      }

      return paymentSettings.data.fullAmount;
    }

    if (paymentSettings.data.mode === 'deposit') {
      if (paymentSettings.data.depositOption === 'per_person') {
        return paymentSettings.data.depositAmount * booking.guests;
      }

      return paymentSettings.data.depositAmount;
    }

    return 0;
  };

  const getDepositMessage = () => {
    if (paymentSettings.data.mode === 'full') {
      return `You will be charged the full amount of ${formatCurrency(getDepositAmount())}`;
    }
    if (paymentSettings.data.mode === 'deposit') {
      return `You will be charged the deposit amount of ${formatCurrency(getDepositAmount())}`;
    }

    return null;
  };

  return (
    <PageLayout>
      <WidgetLayout showDetails showBackButton={!obeeBooking.data?.requiresCC}>
        <Spacer direction='vertical' gap='m'>
          {obeeBooking.data?.requiresCC && <h2>Provide your credit card to secure your booking</h2>}

          {hasImportantInformation && (
            <>
              <Spacer direction='vertical' gap='m'>
                <Typography variant='h3'>Important information</Typography>

                <Box
                  style={{
                    fontFamily: theme.baseTypography.availabilityFontFamily,
                    lineHeight: '1.4em',
                    maxHeight: '240px',
                    overflow: 'auto',
                  }}
                >
                  {getTerms()}
                </Box>
              </Spacer>
              <Divider color={theme.colors.outlineColour} />
            </>
          )}

          {showCreditCardFields && (
            <>
              <Spacer direction='vertical' gap='m'>
                <Typography variant='h3'>Payment Policy: {paymentSettings.data.title}</Typography>
                {waitlist
                  ? !isEmpty(restaurant.data.ccWaitlistPolicy) && (
                      <Typography style={{ lineHeight: '1.4em' }}>
                        {restaurant.data.ccWaitlistPolicy}
                      </Typography>
                    )
                  : !isEmpty(
                      restaurant.data.ccPaymentPolicy || paymentSettings.data.shortDescription,
                    ) && (
                      <Typography style={{ lineHeight: '1.4em', whiteSpace: 'pre-wrap' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              restaurant.data.ccPaymentPolicy ||
                              paymentSettings.data.shortDescription,
                          }}
                        />
                      </Typography>
                    )}
                <>
                  {paymentSettings.data.policyText && (
                    <Button
                      onClick={() => setShowPaymentPolicyModal(true)}
                      variant='outlined'
                      style={{
                        fontWeight: theme.fontWeights.regular,
                        width: 'fit-content',
                        fontSize: '13px',
                      }}
                      size='small'
                    >
                      Show full payment policy
                    </Button>
                  )}
                </>

                {/* Show payment info */}
                {getDepositMessage()}

                <CardFields
                  paymentRef={paymentRef}
                  phone={booking?.mobile}
                  email={booking?.email}
                  paymentMethodName={booking?.paymentMethodName}
                  confirmingBooking={confirmingBooking}
                  booking={booking}
                />
              </Spacer>
              <Divider color={theme.colors.outlineColour} />

              {showPaymentPolicyModal && (
                <PaymentPolicyModal
                  open={showPaymentPolicyModal}
                  onClose={() => setShowPaymentPolicyModal(false)}
                  text={paymentSettings.data.policyText}
                />
              )}
            </>
          )}

          {canSubscribe && (
            <Spacer>
              <Checkbox
                label={
                  <Typography variant='small'>
                    {!isEmpty(restaurant?.data?.marketingOptInMessage)
                      ? restaurant?.data?.marketingOptInMessage
                      : `I would like to receive marketing updates from ${restaurant.data.name}`}
                  </Typography>
                }
                color={theme.colors.charcoal}
                onChange={setSubscribe}
                selected={subscribe}
              />
            </Spacer>
          )}

          <Box>
            <Button
              color='primary'
              fullWidth={isMobile}
              loading={confirmingBooking}
              onClick={() => {
                trackCompletion();
                confirmBooking();
              }}
              size='large'
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {booking.editBooking
                ? 'Update booking'
                : waitlist
                ? 'Join the waitlist'
                : 'Submit booking'}
            </Button>
          </Box>

          {showCreditCardFields && hasTerms && (
            <Typography variant='caption' style={{ color: theme.colors.secondaryTextColour }}>
              By clicking submit you agree to the venue&apos;s terms of service.
            </Typography>
          )}
        </Spacer>
      </WidgetLayout>
    </PageLayout>
  );
};

Review.propTypes = {
  addMessage: PropTypes.func.isRequired,
  batchUpdateBooking: PropTypes.func.isRequired,
  booking: bookingPropTypes.isRequired,
  bookingTerms: bookingTermsPropTypes.isRequired,
  createObeeBooking: PropTypes.func.isRequired,
  obeeBooking: obeeBookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
  updateObeeBooking: PropTypes.func.isRequired,
  paymentSettings: paymentSettingsPropTypes.isRequired,
  fetchPaymentSettings: PropTypes.func.isRequired,
  fetchBookingTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  bookingTerms: state.bookingTerms,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
  paymentSettings: state.paymentSettings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addMessage: addMessageAction,
      batchUpdateBooking: batchUpdateBookingAction,
      createObeeBooking: createObeeBookingAction,
      updateBookingStep: updateBookingStepAction,
      updateObeeBooking: updateObeeBookingAction,
      fetchPaymentSettings: fetchPaymentSettingsAction,
      fetchBookingTerms: fetchBookingTermsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Review);
