import { Box } from '@eatclub-apps/ec-component-library';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from '../../../../components/Alert';
import { Button } from '../../../../components/Button';
import { ConditionalWrapper } from '../../../../components/ConditionalWrapper';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import { updateBookingStepAction } from '../../../../data/actions/bookingAction';
import {
  fetchObeeAvailabilitySuggestionsAction,
  fetchObeeAvailabilitySuggestionsForAllAreasAction,
} from '../../../../data/actions/obeeAvailabilitySuggestionsAction';
import { setRestaurantAction } from '../../../../data/actions/restaurantAction';
import { bookingPropTypes, bookingSteps } from '../../../../data/models/Booking';
import { restaurantPropTypes, restaurantsPropTypes } from '../../../../data/models/Restaurant';
import { obeeMultiVenueAvailabilitySuggestionsPropTypes } from '../../../../data/models/obeeMultiVenueAvailabilitySuggestions';
import { trackEvent } from '../../../../utils/analytics';
import { isNil } from '../../../../utils/helpers';
import { useIsMobile, useIsSmallDevice } from '../../../../utils/hooks';
import { Carousel } from '../Carousel';
import useStyles from './MultiVenueNextAvailableTimesStyles';

// look into dumbing down this component so we can reuse NextAvailableTimes
/**
 * Variation on NextAvailableTimes, there are some nuances for multi venue so separate the two into separate components
 */
const MultiVenueNextAvailableTimes = ({
  booking,
  obeeMultiVenueAvailabilitySuggestions,
  restId,
  restaurant,
  restaurants,
  setRestaurant,
  updateBookingStep,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const isSmallDevice = useIsSmallDevice(); // for the carousel to account for small mobile devices
  const [submitting, setSubmitting] = useState(false);

  const multiVenue = restaurants.data.find(
    (restaurantToFind) => restaurantToFind.objectId === restId,
  );

  // we need to also wait on restaurants pending for areas information on the multi-venue
  const isLoading = obeeMultiVenueAvailabilitySuggestions.pending || restaurants.pending;

  const submit = () => {
    trackEvent('Navigation', 'Updating Step', 'Next Button');
    setSubmitting(true);

    setRestaurant(restId, () => {
      updateBookingStep(bookingSteps.details);
      setSubmitting(false);
    });
  };

  const venueAvailabilitySuggestions = obeeMultiVenueAvailabilitySuggestions.data.filter(
    (availabilitySuggestion) => availabilitySuggestion.restId === restId,
  );

  const carouselColumns = (isSmallDevice && 2) || (isMobile && 3) || 4;
  const carouselRows = (isSmallDevice && 4) || (isMobile && 4) || 3;

  const renderSuggestions = () => {
    // Fetching state
    if (isLoading) {
      return <Carousel columns={carouselColumns} loading rows={carouselRows} />;
    }

    // Error state
    if (obeeMultiVenueAvailabilitySuggestions.error) {
      return (
        <Alert heading='An error occurred retrieving the available times'>
          {/* <Button
            onClick={fetchAvailabilitySuggestions}
            style={{ width: 'fit-content' }}
            variant='outlined'
          >
            <Typography style={{ fontWeight: theme.fontWeights.regular }}>Try again</Typography>
          </Button> */}
        </Alert>
      );
    }

    if (obeeMultiVenueAvailabilitySuggestions.success) {
      // Success with suggestions
      if (venueAvailabilitySuggestions.length > 0) {
        return (
          <Carousel
            columns={carouselColumns}
            items={venueAvailabilitySuggestions}
            rows={carouselRows}
          />
        );
      }

      // Success with no suggestions
      return <Alert context='warning' heading='No availability' />;
    }

    // Initial state
    return null;
  };

  return (
    <Spacer direction='vertical' gap='m'>
      <Spacer gap='m' wrap>
        <ConditionalWrapper
          condition={restaurants.pending}
          wrapper={(children) => (
            <Skeleton style={{ borderRadius: '6px' }} variant='rect'>
              {children}
            </Skeleton>
          )}
        >
          <>
            {(restaurants.pending || multiVenue?.multiVenueImageUrl) && (
              <Box className={classes.multiVenueImageContainer}>
                <img
                  alt={`${multiVenue?.name} hero`}
                  className={classes.multiVenueImage}
                  src={multiVenue?.multiVenueImageUrl}
                />
              </Box>
            )}
          </>
        </ConditionalWrapper>

        <Spacer direction='vertical'>
          <ConditionalWrapper
            condition={restaurants.pending}
            wrapper={() => (
              <Skeleton>
                {/* Use the parent venue's name for the skeleton ui calculation */}
                <Typography variant='h2'>{restaurant.data.name}</Typography>
              </Skeleton>
            )}
          >
            <Typography variant='h2'>{multiVenue?.name}</Typography>
          </ConditionalWrapper>

          <ConditionalWrapper
            condition={restaurants.pending}
            wrapper={() => (
              <Skeleton>
                {/* Use the parent venue's venueDescription for the skeleton ui calculation */}
                <Typography>{restaurant.data.venueDescription}</Typography>
              </Skeleton>
            )}
          >
            <Typography>{multiVenue?.venueDescription}</Typography>
          </ConditionalWrapper>

          <ConditionalWrapper
            condition={restaurants.pending}
            wrapper={() => (
              <Skeleton>
                {/* Use the parent venue's address for the skeleton ui calculation */}
                <Typography>{restaurant.data.address}</Typography>
              </Skeleton>
            )}
          >
            <Typography>{multiVenue?.address}</Typography>
          </ConditionalWrapper>
        </Spacer>
      </Spacer>

      <Spacer
        direction='vertical'
        gap='m'
        style={{ width: '100%', minHeight: isMobile ? '236px' : '148px' }}
      >
        {renderSuggestions()}
      </Spacer>

      <Spacer style={{ justifyContent: 'space-between', flexDirection: isMobile && 'column' }}>
        <Button
          onClick={submit}
          size='large'
          style={{ minWidth: '144px', marginLeft: 'auto' }}
          disabled={
            submitting ||
            obeeMultiVenueAvailabilitySuggestions.pending ||
            isNil(booking.availabilitySuggestion) ||
            booking.availabilitySuggestion?.restId !== restId ||
            booking.availabilitySuggestion?.waitlist
          }
          color='primary'
          fullWidth={isMobile}
          loading={submitting && booking.availabilitySuggestion?.restId === restId}
        >
          Continue at this venue
        </Button>
      </Spacer>
    </Spacer>
  );
};

MultiVenueNextAvailableTimes.propTypes = {
  booking: bookingPropTypes.isRequired,
  obeeMultiVenueAvailabilitySuggestions: obeeMultiVenueAvailabilitySuggestionsPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  restaurants: restaurantsPropTypes.isRequired,
  setRestaurant: PropTypes.func.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeMultiVenueAvailabilitySuggestions: state.obeeMultiVenueAvailabilitySuggestions,
  restaurant: state.restaurant,
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchObeeAvailabilitySuggestions: fetchObeeAvailabilitySuggestionsAction,
      fetchObeeAvailabilitySuggestionsForAllAreas:
        fetchObeeAvailabilitySuggestionsForAllAreasAction,
      updateBookingStep: updateBookingStepAction,
      setRestaurant: setRestaurantAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultiVenueNextAvailableTimes);
