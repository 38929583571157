import * as type from './types';

export const clearBookingAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_BOOKING,
  });
};

export const editBookingAction = () => (dispatch) => {
  dispatch({
    type: type.EDIT_BOOKING,
  });
};

export const editWaitlistAction = () => (dispatch) => {
  dispatch({
    type: type.EDIT_WAITLIST,
  });
};

export const updateBookingAction = (key, value) => (dispatch) => {
  dispatch({
    type: type.UPDATE_BOOKING,
    payload: { key, value },
  });
};

export const batchUpdateBookingAction = (keyValues) => (dispatch) => {
  dispatch({
    type: type.BATCH_UPDATE_BOOKING,
    payload: keyValues,
  });
};

export const updateBookingStepAction = (step) => (dispatch) => {
  dispatch({
    type: type.UPDATE_BOOKING_STEP,
    payload: step,
  });
};
