import React from 'react';
import { Booking } from '../screens/Booking';
import { Landing } from '../screens/Landing';

const routes = [
  {
    path: '/',
    main: () => <Landing />,
  },
  {
    path: '/:path',
    main: () => <Booking />,
  },
  {
    // could be a magical link
    // This route MIGHT be deprecated (for ecb widget v2 release)
    path: '*',
    main: () => <Booking />,
  },
];

export default routes;
