import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.colors.redOrange,
    margin: '4px 0 0 0',
  },
}));

export default useStyles;
