import * as type from './types';

export const restaurantForId = /* GraphQL */ `
  query RestaurantForId($restId: String!) {
    restaurantForId(restId: $restId) {
      ${type.Restaurant}
    }
  }
`;

export const restaurantForSlug = /* GraphQL */ `
  query RestaurantForSlug($slug: String!) {
    restaurantForSlug(slug: $slug) {
      ${type.Restaurant}
    }
  }
`;

export const restaurantsByIds = /* GraphQL */ `
  query RestaurantsByIds($restIds: [String]!) {
    restaurantsByIds(restIds: $restIds) {
      ${type.Restaurant}
    }
  }
`;

// TODO remove these when sync is complete
export const restaurantForIdEatClub = /* GraphQL */ `
  query RestaurantForId($restId: String!) {
    restaurantForId(restId: $restId) {
      ${type.EatClubRestaurant}
    }
  }
`;

export const restaurantForSlugEatClub = /* GraphQL */ `
  query RestaurantForSlug($slug: String!) {
    restaurantForSlug(slug: $slug) {
      ${type.EatClubRestaurant}
    }
  }
`;

export const restaurantsByIdsEatClub = /* GraphQL */ `
  query RestaurantsByIds($restIds: [String]!) {
    restaurantsByIds(restIds: $restIds) {
      ${type.EatClubRestaurant}
    }
  }
`;

export const bookingForId = /* GraphQL */ `
  query BookingForId($restId: String!, $bookingId: Int!) {
    bookingForId(restId: $restId, bookingId: $bookingId) {
      ${type.OBBooking}
    }
  }
`;

export const bookingForCode = /* GraphQL */ `
  query BookingForCode($restId: String!, $code: String!) {
    bookingForCode(restId: $restId, code: $code) {
      ${type.OBBooking}
    }
  }
`;

export const areas = /* GraphQL */ `
  query Areas($restId: String, $slug: String) {
    areas(restId: $restId, slug: $slug) {
      ${type.OBArea}
    }
  }
`;

export const sessions = /* GraphQL */ `
  query Sessions($restId: String!) {
    sessions(restId: $restId) {
      ${type.OBSession}
    }
  }
`;

export const availabilityCheck = /* GraphQL */ `
  query AvailabilityCheck(
    $restId: String!
    $areaId: Int!
    $date: AWSDate!
    $time: Int!
    $size: Int!
  ) {
    availabilityCheck(restId: $restId, areaId: $areaId, date: $date, time: $time, size: $size) {
      ${type.OBTable}
    }
  }
`;

export const availabilitySuggestions = /* GraphQL */ `
  query AvailabilitySuggestions(
    $restId: String!
    $areaId: Int!
    $date: AWSDate!
    $times: [Int]!
    $size: Int!
    $length: Int
    $public: Boolean
  ) {
    availabilitySuggestions(
      restId: $restId
      areaId: $areaId
      date: $date
      times: $times
      size: $size
      length: $length
      public: $public
    ) {
      ${type.OBAvailabilitySuggestion}
    }
  }
`;

export const availabilitySuggestionsForAllAreas = /* GraphQL */ `
  query AvailabilitySuggestionsForAllAreas(
    $restId: String!
    $date: AWSDate!
    $times: [Int]!
    $size: Int!
    $length: Int
    $public: Boolean
  ) {
    availabilitySuggestionsForAllAreas(
      restId: $restId
      date: $date
      times: $times
      size: $size
      length: $length
      public: $public
    ) {
      ${type.OBAvailabilitySuggestion}
    }
  }
`;

export const availabilitySuggestionsForMultiVenue = /* GraphQL */ `
  query AvailabilitySuggestionsForMultiVenue(
    $restId: String!
    $date: AWSDate!
    $times: [Int]!
    $size: Int!
    $length: Int
    $public: Boolean
  ) {
    availabilitySuggestionsForMultiVenue(
      restId: $restId
      date: $date
      times: $times
      size: $size
      length: $length
      public: $public
    ) {
      ${type.OBAvailabilitySuggestion}
    }
  }
`;

export const lookupPaymentMethodName = /* GraphQL */ `
  query LookupPaymentMethodName($paymentMethodId: String!) {
    lookupPaymentMethodName(paymentMethodId: $paymentMethodId)
  }
`;

export const bookingUpdateCheck = /* GraphQL */ `
  query BookingUpdateCheck(
    $restId: String!
    $bookingId: Int!
    $areaId: Int
    $date: String
    $time: Int
    $size: Int
    $tableNumber: String
    $duration: Int
    $public: Boolean
  ) {
    bookingUpdateCheck(
      restId: $restId
      bookingId: $bookingId
      areaId: $areaId
      date: $date
      time: $time
      size: $size
      tableNumber: $tableNumber
      duration: $duration
      public: $public
    )
  }
`;

export const obeeIntegrations = /* GraphQL */ `
  query ObeeIntegrations($restId: String!) {
    obeeIntegrations(restId: $restId) {
      ${type.Integration}
    }
  }
`;

export const bookingTerms = /* GraphQL */ `
  query bookingTerms($restId: String!, $areaId: Int, $date: AWSDate, $time: Int, $size: Int) {
    bookingTerms(restId: $restId, areaId: $areaId, date: $date, time: $time, size: $size)
  }
`;

export const obeePaymentSettingsByCriteria = /* GraphQL */ `
  query ObeePaymentSettingsByCriteria($restId: String!, $paymentSettings: OBPaymentSettingsInput!) {
    obeePaymentSettingsByCriteria(restId: $restId, paymentSettings: $paymentSettings) {
      ${type.PaymentSettings}
    }
  }
`;
