import { Box } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../../components/Button';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import {
  updateBookingAction,
  updateBookingStepAction,
} from '../../../../data/actions/bookingAction';
import {
  fetchObeeAvailabilitySuggestionsAction,
  fetchObeeAvailabilitySuggestionsForAllAreasAction,
} from '../../../../data/actions/obeeAvailabilitySuggestionsAction';
import { freeBookingAction, holdBookingAction } from '../../../../data/actions/obeeBookingAction';
import {
  clearObeeAvailabilitySuggestionsForMultiVenueAction,
  fetchObeeAvailabilitySuggestionsForMultiVenueAction,
} from '../../../../data/actions/obeeMultiVenueAvailabilitySuggestionsAction';
import {
  bookingKeys,
  bookingPropTypes,
  bookingSteps,
  isEditing,
} from '../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import {
  getAvailabilitySuggestions,
  getAvailabilitySuggestionsForSession,
  obeeAvailabilitySuggestionsPropTypes,
} from '../../../../data/models/obeeAvailabilitySuggestions';
import { obeeBookingPropTypes } from '../../../../data/models/obeeBooking';
import { ALL_SESSIONS_LABEL, getSessionsForBooking } from '../../../../data/models/obeeSessions';
import { trackEvent } from '../../../../utils/analytics';
import {
  findObjectByProperty,
  isEmpty,
  isNil,
  sortByProperty,
  unique,
} from '../../../../utils/helpers';
import { useDidUpdateEffect, useIsMobile, useIsSmallDevice } from '../../../../utils/hooks';
import { Carousel } from '../Carousel';
import { JoinWaitlistModal } from './JoinWaitlistModal';
import useStyles from './NextAvailableTimesStyles';
import { Sessions } from './Sessions';
import UnavailabilityCard from './UnavailabilityCard';

const NextAvailableTimes = ({
  booking,
  clearObeeAvailabilitySuggestionsForMultiVenue,
  fetchObeeAvailabilitySuggestions,
  fetchObeeAvailabilitySuggestionsForAllAreas,
  fetchObeeAvailabilitySuggestionsForMultiVenue,
  obeeAvailabilitySuggestions,
  obeeBooking,
  restaurant,
  updateBooking,
  updateBookingStep,
  holdBooking,
  freeBooking,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const isSmallDevice = useIsSmallDevice(); // for the carousel to account for small mobile devices

  // Cache previous suggestion
  const previousSuggestion = useMemo(() => booking?.availabilitySuggestion, []);

  // If an availability is selected on the first load, set it on the booking too
  useEffect(() => {
    if (isEditing(booking)) {
      const selectedAvailability = obeeAvailabilitySuggestions.data.find(
        (availabilitySuggestion) =>
          booking.availabilitySuggestion?.time === availabilitySuggestion.time &&
          booking.availabilitySuggestion?.tables
            ?.map((table) => table?.tableNumber)
            .includes(availabilitySuggestion.tableNumber) &&
          (!booking.availabilitySuggestion?.restId ||
            booking.availabilitySuggestion?.restId === availabilitySuggestion.restId),
      );

      const tableForBooking = findObjectByProperty(
        obeeAvailabilitySuggestions.data.flatMap((suggestion) => suggestion.tables),
        booking?.tableNumber,
        'tableNumber',
      );

      if (!isEmpty(selectedAvailability)) {
        updateBooking(bookingKeys.availabilitySuggestion, {
          restId: booking.initialRestId,
          time: booking.time,
          table: tableForBooking,
          preferredArea: booking?.areaId,
        });
      }
    }
  }, [obeeAvailabilitySuggestions.data]);

  const submit = () => {
    trackEvent('Navigation', 'Updating Step', 'Next Button');

    // TODO move to helper or somewhere
    const getOptimalArea = () => {
      const areaForBooking = restaurant.data.areas.find(
        (area) => area.id === booking.availabilitySuggestion?.table?.areaId ?? booking?.areaId,
      );

      if (areaForBooking) {
        return areaForBooking;
      }

      // NOTE: Find the optimal area for "all areas" waitlist
      const potentialAreas = restaurant.data.areas.filter(
        (area) => area.minGroup <= booking.guests && area.maxGroup >= booking.guests,
      );

      return potentialAreas.sort((a, b) => sortByProperty(a, b, 'priority'))[0];
    };

    // If creating booking and booking ID was already held, free that booking
    const hasChanged =
      previousSuggestion?.table?.areaId !== booking?.availabilitySuggestion?.table?.areaId ||
      previousSuggestion?.table?.tableNumber !==
        booking?.availabilitySuggestion?.table?.tableNumber ||
      previousSuggestion?.time !== booking?.availabilitySuggestion?.time;

    // TODO currently there is no support for holding a timeslot while editing a booking
    // Free the old booking (if any) and hold the new
    if (hasChanged && !isEditing(booking) && booking?.heldBookingId && !booking?.waitlist) {
      freeBooking(booking?.heldBookingId);

      holdBooking(
        booking,
        booking?.availabilitySuggestion?.table?.areaId,
        booking?.availabilitySuggestion?.table?.tableNumber,
        getOptimalArea()?.duration,
      );
    }

    updateBookingStep(bookingSteps.details);
  };

  const sessions = getSessionsForBooking(restaurant.data.areas, booking).sort((a, b) =>
    sortByProperty(a, b, 'startTime'),
  );

  const sessionsWithUniqueStartTimes = unique(sessions, 'startTime');

  const carouselColumns = (isSmallDevice && 2) || (isMobile && 3) || 4;
  const carouselRows = (isSmallDevice && 4) || (isMobile && 4) || 3;
  const gridSize = carouselColumns * carouselRows;

  const fetchAvailabilitySuggestions = () => {
    // HACK: if there is only one area, fetch for specific area
    if (restaurant?.data?.areas?.length === 1) {
      fetchObeeAvailabilitySuggestions(
        restaurant?.data?.areas?.[0]?.id,
        booking.date,
        sessionsWithUniqueStartTimes.map((session) => session.startTime),
        booking.guests,
        gridSize,
      );
      return;
    }

    if (booking?.preferredAreaId === -1) {
      fetchObeeAvailabilitySuggestionsForAllAreas(
        booking.date,
        sessionsWithUniqueStartTimes.map((session) => session.startTime),
        booking.guests,
        gridSize,
      );
    } else {
      fetchObeeAvailabilitySuggestions(
        booking?.preferredAreaId,
        booking.date,
        sessionsWithUniqueStartTimes.map((session) => session.startTime),
        booking.guests,
        gridSize,
      );
    }
  };

  useDidUpdateEffect(
    () => {
      // clear the multi venue suggestions when the params change and we do another parent fetch
      clearObeeAvailabilitySuggestionsForMultiVenue();

      fetchAvailabilitySuggestions();
    },
    [fetchObeeAvailabilitySuggestions, booking?.preferredAreaId, booking.date, booking.guests],
    isEditing(booking) || !obeeAvailabilitySuggestions.success, // force a fetch if coming from the edit flow
  );

  useDidUpdateEffect(() => {
    if (obeeAvailabilitySuggestions.success) {
      // don't fetch multi venue availability for free accounts
      if (!restaurant.data.premium) {
        return;
      }

      // don't fetch multi venue availability if there are no linked venues
      if (!restaurant.data.linkedVenues?.length > 0) {
        return;
      }

      // don't fetch multi venue availability for edit flow
      if (isEditing(booking)) {
        return;
      }

      const hasNoAvailability = obeeAvailabilitySuggestions.data.every((availabilitySuggestion) =>
        isNil(availabilitySuggestion.table),
      );

      if (
        (restaurant.data.suggestAvailability === 'no_availability' &&
          (hasNoAvailability || obeeAvailabilitySuggestions.data.length === 0)) ||
        restaurant.data.suggestAvailability === 'always'
      ) {
        fetchObeeAvailabilitySuggestionsForMultiVenue(
          booking.date,
          [-1], // note: Sends an array of 1 start time, which is ignored on the backend
          booking.guests,
          gridSize,
        );
      }
    }
  }, [obeeAvailabilitySuggestions.success]);

  useEffect(() => {
    if (booking.preferredTime !== -1) {
      const suggestion = obeeAvailabilitySuggestions.data.find(
        (availabilitySuggestion) =>
          availabilitySuggestion.time === booking.preferredTime &&
          !isNil(availabilitySuggestion.table),
      );

      // if preferred time availability suggestion exists let's set it for them
      if (suggestion) {
        updateBooking(bookingKeys.availabilitySuggestion, {
          ...suggestion,
          allAreas: booking?.preferredAreaId === -1,
          waitlist: false,
        });
      }
    }
  }, [booking.preferredTime, obeeAvailabilitySuggestions.data]);

  // TODO test this doesn't cause problems. What if a user is on the waitlist then returns to the first screen?
  useEffect(() => {
    updateBooking('waitlist', false);
  }, []);

  // Remove sessions that don't match the filtered area or session
  // This prevents it getting confused if there are/aren't sitting times in other sessions
  const sessionsForFilter = sessions.filter((session) => {
    return (
      (session?.areaId <= 0 ||
        booking.preferredAreaId <= 0 ||
        booking?.preferredAreaId === session?.areaId) &&
      (booking.sessionLabel === ALL_SESSIONS_LABEL ||
        `${booking.sessionLabel}`.toLowerCase() === `${session?.label}`.toLowerCase())
    );
  });

  const suggestionsForSession = getAvailabilitySuggestionsForSession(
    booking,
    sessionsForFilter,
    obeeAvailabilitySuggestions.data,
    obeeBooking.data,
    restaurant.data,
    sessions,
    booking.sessionLabel,
  );

  const preferredSuggestionsForSession = suggestionsForSession.filter((suggestionForSession) => {
    if (booking.preferredTime !== -1) {
      return suggestionForSession.time === booking.preferredTime;
    }

    return true;
  });

  const preferredAvailableSuggestionsForSession = preferredSuggestionsForSession.filter(
    (suggestion) => !suggestion.waitlist && !isEmpty(suggestion?.tables),
  );

  const availableSuggestionsForSession = suggestionsForSession.filter(
    (suggestion) => !suggestion.waitlist && !isEmpty(suggestion?.tables),
  );

  // TODO if area changes and the filtered session has no bookings, switch back to All

  // TODO this is very expensive and should be optimized
  const suggestionsBySession = useMemo(() => {
    // Try to find all the sessions that would have bookings. This ends up being a bit weird, since the availabilities can change quite a bit.
    // We need to treat each session as if it was filtered to that session, then run all of the logic of finding bookings for that session.
    return (
      sessions
        .filter((session) => session.id !== 0)
        // Include other sessions such as area id 0
        .map((session) => {
          return {
            mainSession: session,
            linkedSessions: sessions.filter((linkedSession) => {
              return (
                (linkedSession?.areaId <= 0 ||
                  session.areaId <= 0 ||
                  session.areaId === linkedSession?.areaId) &&
                `${session.label}`.toLowerCase() === `${linkedSession?.label}`.toLowerCase()
              );
            }),
          };
        })
        .map((session) => {
          const availabilitySuggestionsForLinkedSessions = getAvailabilitySuggestionsForSession(
            booking,
            session.linkedSessions,
            obeeAvailabilitySuggestions.data,
            obeeBooking.data,
            restaurant.data,
            sessions,
            session.mainSession.label,
          );

          const suggestionsForSpecificSession = getAvailabilitySuggestions(
            availabilitySuggestionsForLinkedSessions,
            booking,
            obeeBooking.data,
            restaurant.data,
            session.mainSession,
          );

          return {
            sessionId: session.mainSession.id,
            availabilitySuggestions: suggestionsForSpecificSession,
          };
        })
    );
  }, [suggestionsForSession, preferredAvailableSuggestionsForSession, sessions]);

  const showWaitlist =
    !obeeAvailabilitySuggestions.pending &&
    !booking.editBooking &&
    availableSuggestionsForSession.length === 0 &&
    restaurant.data.waitlist;

  const renderSuggestions = () => {
    // Fetching state
    if (obeeAvailabilitySuggestions.pending) {
      return <Carousel columns={carouselColumns} loading rows={carouselRows} />;
    }

    if (booking.date && obeeAvailabilitySuggestions.success) {
      // Success with no suggestions
      return (
        <>
          {/* Success with suggestions */}
          {preferredAvailableSuggestionsForSession.length > 0 && (
            <>
              <Carousel
                columns={carouselColumns}
                items={preferredAvailableSuggestionsForSession}
                rows={Math.min(
                  availableSuggestionsForSession.length / carouselColumns,
                  carouselRows,
                )}
              />
              {!booking.editBooking && showWaitlist && (
                <Spacer className={classes.joinWaitlistText}>
                  <Typography>Can&apos;t find availability?</Typography>
                </Spacer>
              )}
            </>
          )}

          {/* /!* Show sitting times that have labelled subgroups *!/ */}
          {/* {sittingTimeAvailabilitiesWithTables.map((sittingTime) => ( */}
          {/*   <Spacer direction='vertical' gap={12} key={sittingTime.label}> */}
          {/*     {(booking.sessionLabel === ALL_SESSIONS_LABEL || */}
          {/*       booking.sessionLabel !== sittingTime?.label) && <Box>{sittingTime.label}</Box>} */}
          {/*     <Carousel */}
          {/*       columns={carouselColumns} */}
          {/*       items={sittingTime.availabilities} */}
          {/*       rows={Math.min(sittingTime.availabilities.length / carouselColumns, carouselRows)} */}
          {/*     /> */}
          {/*   </Spacer> */}
          {/* ))} */}

          {/* If no availability was found */}
          {isEmpty(preferredAvailableSuggestionsForSession) && (
            <UnavailabilityCard fetchAvailabilitySuggestions={fetchAvailabilitySuggestions} />
          )}

          {/* Default suggestions or something? */}
          {preferredAvailableSuggestionsForSession.length <= 0 &&
            availableSuggestionsForSession.length >= 0 && (
              <>
                <Carousel
                  columns={carouselColumns}
                  items={availableSuggestionsForSession}
                  rows={Math.min(
                    availableSuggestionsForSession.length / carouselColumns,
                    carouselRows,
                  )}
                />
              </>
            )}

          {showWaitlist && (
            <Box className={classes.joinWaitlistText}>
              <Typography>Can&apos;t find availability?</Typography>
            </Box>
          )}
        </>
      );
    }

    // Initial state
    return null;
  };

  return (
    <Spacer direction='vertical' gap={40}>
      <Spacer direction='vertical' gap={20}>
        <Spacer centered style={{ justifyContent: 'space-between', minHeight: '33px' }} wrap>
          <Spacer direction='vertical'>
            <Typography variant='h3'>Select a time</Typography>
          </Spacer>

          <Sessions suggestionsBySession={suggestionsBySession} />
        </Spacer>

        <Spacer
          direction='vertical'
          gap='m'
          style={{ width: '100%', minHeight: isMobile ? '236px' : '148px' }}
        >
          {renderSuggestions()}
        </Spacer>
      </Spacer>

      <Spacer style={{ justifyContent: 'space-between', flexDirection: isMobile && 'column' }}>
        {showWaitlist && (
          <Button
            onClick={() => setOpen(true)}
            variant='outlined'
            size='large'
            style={{ minWidth: '144px' }}
            fullWidth={isMobile}
          >
            Join the waitlist
          </Button>
        )}

        <Button
          onClick={submit}
          size='large'
          style={{ minWidth: '144px', marginLeft: 'auto' }}
          disabled={
            obeeAvailabilitySuggestions.pending ||
            isNil(booking.availabilitySuggestion) ||
            (booking.availabilitySuggestion?.restId &&
              booking.availabilitySuggestion?.restId !== restaurant.data.objectId) ||
            booking.availabilitySuggestion?.waitlist
          }
          color='primary'
          fullWidth={isMobile}
        >
          Next
        </Button>
      </Spacer>

      {showWaitlist && <JoinWaitlistModal open={open} close={() => setOpen(false)} />}
    </Spacer>
  );
};

NextAvailableTimes.propTypes = {
  booking: bookingPropTypes.isRequired,
  clearObeeAvailabilitySuggestionsForMultiVenue: PropTypes.func.isRequired,
  fetchObeeAvailabilitySuggestions: PropTypes.func.isRequired,
  fetchObeeAvailabilitySuggestionsForAllAreas: PropTypes.func.isRequired,
  fetchObeeAvailabilitySuggestionsForMultiVenue: PropTypes.func.isRequired,
  obeeAvailabilitySuggestions: obeeAvailabilitySuggestionsPropTypes.isRequired,
  obeeBooking: obeeBookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
  holdBooking: PropTypes.func.isRequired,
  freeBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeAvailabilitySuggestions: state.obeeAvailabilitySuggestions,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearObeeAvailabilitySuggestionsForMultiVenue:
        clearObeeAvailabilitySuggestionsForMultiVenueAction,
      fetchObeeAvailabilitySuggestions: fetchObeeAvailabilitySuggestionsAction,
      fetchObeeAvailabilitySuggestionsForAllAreas:
        fetchObeeAvailabilitySuggestionsForAllAreasAction,
      fetchObeeAvailabilitySuggestionsForMultiVenue:
        fetchObeeAvailabilitySuggestionsForMultiVenueAction,
      updateBooking: updateBookingAction,
      updateBookingStep: updateBookingStepAction,
      holdBooking: holdBookingAction,
      freeBooking: freeBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NextAvailableTimes);
