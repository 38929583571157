import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { restaurantPropTypes } from '../../data/models/Restaurant';
import { PageLayout } from '../../layouts/PageLayout';

const FetchingDetails = ({ message, restaurant }) => {
  return (
    <PageLayout>
      <Spacer
        centered
        gap='m'
        style={{ color: restaurant.data.bodyTextColour, margin: '24px 12px' }}
      >
        <CircularProgress color='inherit' size={14} />
        <Typography variant='small'>{message}</Typography>
      </Spacer>
    </PageLayout>
  );
};

FetchingDetails.defaultProps = {
  message: 'Please wait a moment to make a booking.',
};

FetchingDetails.propTypes = {
  message: PropTypes.string,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FetchingDetails);
