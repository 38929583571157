import { useEffect, useLayoutEffect, useRef } from 'react';

const tenMinutesInMs = 10 * 1000 * 60;

/**
 *
 * @param callback
 * @param delay
 * @param heldBookingId
 */
export const useTimeout = (callback, delay = tenMinutesInMs, heldBookingId = null) => {
  const clear = useRef(() => {});
  const savedCallback = useRef(callback);

  const resetTimeout = () => {
    const timerId = setTimeout(() => savedCallback.current(), delay);
    clear.current = () => clearTimeout(timerId);
  };

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      clear.current();
      return null;
    }

    const timerId = setTimeout(() => savedCallback.current(), delay);

    clear.current = () => clearTimeout(timerId);
    return () => clearTimeout(timerId);
  }, [delay, heldBookingId]);

  return { clear: clear.current, reset: resetTimeout };
};
