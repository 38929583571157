import { Box } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconCalPrev } from '../../../../../../assets/icons/caret_left.svg';
import { ReactComponent as IconCalNext } from '../../../../../../assets/icons/caret_right.svg';
import { IconButton } from '../../../../../../components/IconButton';
import { Spacer } from '../../../../../../components/Spacer';
import { Swipe } from '../../../../../../components/Swipe';
import { trackEvent } from '../../../../../../utils/analytics';
import { useIsMobile } from '../../../../../../utils/hooks';
import useStyles from './SliderStyles';

/**
 * A generic Slider for showing a list of items horizontally.
 *
 * TODO move this to the component library
 */
const Slider = ({ items }) => {
  const classes = useStyles();

  const ref = useRef(null);
  const swipeRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const scrollAmount = clientWidth || 300;

  const isMobile = useIsMobile();

  const maxScrollPosition = scrollWidth - clientWidth;

  const handleScroll = () => {
    setScrollPosition(ref.current.scrollLeft);
  };

  useEffect(() => {
    setScrollWidth(swipeRef.current.scrollWidth);
    setClientWidth(ref.current.clientWidth);
  }, [swipeRef, ref, items.length]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  const next = () => {
    ref.current.scrollLeft += scrollAmount;
  };

  const prev = () => {
    ref.current.scrollLeft -= scrollAmount;
  };

  const scrollLeft = () => {
    trackEvent('Scroll', 'Scrolled Left', 'Button');
    prev();
  };

  const scrollRight = () => {
    trackEvent('Scroll', 'Scrolled Right', 'Button');
    next();
  };

  return (
    <Spacer direction='vertical' gap={0}>
      <Spacer style={{ position: 'relative' }}>
        {!isMobile && (
          <Box className={classes.prevBtnWrapper}>
            {scrollPosition > 0 && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollLeft}>
                <IconCalPrev />
              </IconButton>
            )}
          </Box>
        )}

        <Swipe
          ref={ref}
          onNext={next}
          onPrev={prev}
          style={{ height: '96px', overflowX: 'scroll' }}
          smoothTransition={false}
          trackTouch={false}
        >
          <Box ref={swipeRef} className={classes.items}>
            {[items]}
          </Box>
        </Swipe>

        {!isMobile && (
          <Box className={classes.nextBtnWrapper}>
            {scrollPosition < maxScrollPosition && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollRight}>
                <IconCalNext />
              </IconButton>
            )}
          </Box>
        )}
      </Spacer>

      {isMobile && (
        <Spacer style={{ justifyContent: 'space-between' }}>
          <Box>
            {scrollPosition > 0 && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollLeft}>
                <IconCalPrev />
              </IconButton>
            )}
          </Box>

          <Box>
            {scrollPosition < maxScrollPosition && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollRight}>
                <IconCalNext />
              </IconButton>
            )}
          </Box>
        </Spacer>
      )}
    </Spacer>
  );
};

Slider.defaultProps = {
  items: [],
};

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

export default Slider;
