import { Box } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useSwipe } from '../../utils/hooks';
import useStyles from './SwipeStyles';

const Swipe = forwardRef(
  ({ children, onNext, onPrev, smoothTransition, style, trackMouse, trackTouch }, ref) => {
    const [handleSwipeStart, handleSwipeMove, handleSwipeEnd, diff] = useSwipe(onNext, onPrev, 50);
    const classes = useStyles({ smoothTransition, diff, style });

    const props = {
      className: classes.container,
      ref,

      // Track desktop
      ...(trackMouse
        ? {
            onMouseDown: handleSwipeStart,
            onMouseMove: handleSwipeMove,
            onMouseUp: handleSwipeEnd,
            onMouseLeave: handleSwipeEnd, // failsafe if mouse has dragged outside of the element (on mouse up is no longer reliable at this point)
          }
        : {}),

      // Track mobile
      ...(trackTouch
        ? {
            onTouchStart: handleSwipeStart,
            onTouchMove: handleSwipeMove,
            onTouchEnd: handleSwipeEnd,
          }
        : {}),
    };

    return <Box {...props}>{children}</Box>;
  },
);

Swipe.defaultProps = {
  children: null,
  onNext: () => {},
  onPrev: () => {},
  smoothTransition: true,
  style: {},
  trackMouse: true,
  trackTouch: true,
};

Swipe.propTypes = {
  children: PropTypes.node,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  smoothTransition: PropTypes.bool,
  style: PropTypes.shape({}),
  trackMouse: PropTypes.bool,
  trackTouch: PropTypes.bool,
};

export default Swipe;
