// Allow adding addresses that are not supported by mapbox
const customAddresses = [
  {
    address: 'Australian National Botanic Gardens, Canberra, ACT 2601',
    coordinates: [149.1095538438, -35.2778188717],
  },
];

/**
 * Fetches the latitude and longitude coordinates for a given address using the Mapbox Geocoding API.
 *
 * @param {string} address - The address for which to obtain the coordinates.
 * @returns {Promise<Array<number>>} - A Promise that resolves to an array containing the latitude and longitude coordinates, or `undefined` if not available.
 * @throws {Error} - Throws an error if the API request fails or if the address is not found.
 */
export const getCoordinates = async (address) => {
  const customAddress = customAddresses.find((item) => item?.address === address);
  if (customAddress) {
    return customAddress.coordinates;
  }

  const endpoint = `https://api.mapbox.com/search/geocode/v6/forward?q=${address}&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&country=AU&limit=1&types=street,address,place`;

  try {
    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error(`Failed to fetch coordinates. Status: ${response.status}`);
    }

    const results = await response.json();
    const coordinates = results.features[0]?.geometry.coordinates ?? [];

    if (coordinates.length === 0) {
      throw new Error('Coordinates not found for the provided address.');
    }

    return coordinates;
  } catch (error) {
    throw new Error(`Error during coordinate retrieval: ${error.message}`);
  }
};
