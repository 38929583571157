const awsmobile = {
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export default awsmobile;
