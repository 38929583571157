import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: ({ smoothTransition, diff, style }) => ({
    position: 'relative',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    transform: smoothTransition && `translateX(${diff * -1}px)`,
    transition: smoothTransition && 'transform 0.1s',
    width: '100%',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '&::-moz-scrollbar': {
      display: 'none',
    },

    '&:hover': {
      // cursor: 'pointer',
    },
    ...style,
  }),
}));

export default useStyles;
