import { makeStyles } from '@material-ui/core';
import { calcHexTransparency } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  items: {
    alignItems: 'center',
    display: 'grid',
    gap: '8px',
    margin: 'auto',
    maxWidth: '1920px',
    width: '100%',
  },
  container: ({ navigationBgColour, navigationBgColourTransparency }) => ({
    background: calcHexTransparency(navigationBgColour, navigationBgColourTransparency),
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '8px 32px',
    minHeight: '56px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '48px',
      padding: '8px 12px',
    },
  }),
}));

export default useStyles;
