export const baseColors = {
  americanSilver: '#cfcfcf',
  black: '#0F0F0F',
  champagne: '#EED5B6',
  charcoal: '#313131',
  chardon: '#F9F2E9',
  christine: '#C46E2A',
  cloud: '#F0F0F0',
  coralCandy: '#F7CAC9',
  darkGrey: '#B0B0B0',
  deepTeal: '#193D38',
  eatclubRed: '#E54439',
  fireBush: '#E7A049',
  gainsboro: '#E6E6E6',
  linen: '#F5F2ED',
  linenDark: '#EFEAE3',
  nero: '#1A1919',
  nimbus: '#AEAEAE',
  obeeGreen: '#78BEA1',
  paleMint: '#F1F3F1',
  patina: '#5A9488',
  peach: '#EFD1B6',
  redOrange: '#f44336',
  roquette: '#5D995C',
  sazerac: '#E8A263',
  shark: '#DCDCDC',
  smoke: '#6E6E6D',
  suvaGrey: '#8E8E8E',
  tumeric: '#EAD16A',
  valencia: '#DA4E45',
  white: '#FFFFFF',
  whiteSmoke: '#F7F7F7',
};

export const baseFontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};

export const baseTypography = {
  h1: {
    fontSize: '1.75em',
    fontWeight: baseFontWeights.bold,
  },
  h2: {
    fontSize: '1.5em',
    fontWeight: baseFontWeights.bold,
  },
  h3: {
    fontSize: '1rem',
    fontWeight: baseFontWeights.medium,
  },
  h4: {
    fontSize: '1em',
    fontWeight: baseFontWeights.medium,
  },
  p: {
    fontSize: '1em',
  },
  large: {
    fontSize: '1.125em',
  },
  small: {
    fontSize: '14px', // note: em makes the font too small on mobile, have it fixed at 14px
  },
  caption: {
    fontSize: '12px', // note: em makes the font too small on mobile, have it fixed at 12px
  },
};
