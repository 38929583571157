import { Box } from '@eatclub-apps/ec-component-library';
import { Dialog, DialogContent, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as CloseMarkSVG } from '../../assets/icons/close_mark.svg';
import { restaurantPropTypes } from '../../data/models/Restaurant';
import { IconButton } from '../IconButton';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import useStyles from './ModalStyles';

const Modal = ({
  centeredTitle,
  children,
  footer,
  headerTitle,
  onClose,
  open,
  restaurant,
  showHeader,
  title,
}) => {
  const theme = useTheme();
  const classes = useStyles({ modalHeaderColour: restaurant.data.modalHeaderColour });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          margin: '12px',
          color: theme.colors.darkMode ? theme.colors.white : theme.colors.charcoal,
        },
      }}
    >
      {showHeader && (
        <Box className={classes.header}>
          <Box>{headerTitle && <Typography variant='h3'>{headerTitle}</Typography>}</Box>
          <IconButton onClick={onClose}>
            <CloseMarkSVG />
          </IconButton>
        </Box>
      )}

      <Box className={classes.content}>
        {title && (
          <Box className={classes.title}>
            <Typography variant='h1' centered={centeredTitle}>
              {title}
            </Typography>
          </Box>
        )}

        <DialogContent>{children}</DialogContent>
      </Box>

      {footer && <Spacer className={classes.footer}>{footer}</Spacer>}
    </Dialog>
  );
};

Modal.defaultProps = {
  centeredTitle: false,
  children: null,
  footer: null,
  headerTitle: null,
  showHeader: true,
  title: null,
};

Modal.propTypes = {
  centeredTitle: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.node,
  headerTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
