export const SET_ID_LOADING = 'SET_ID_LOADING';
export const REMOVE_ID_LOADING = 'REMOVE_ID_LOADING';

// Booking
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const EDIT_BOOKING = 'EDIT_BOOKING';
export const EDIT_WAITLIST = 'EDIT_WAITLIST';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const BATCH_UPDATE_BOOKING = 'BATCH_UPDATE_BOOKING';
export const UPDATE_BOOKING_STEP = 'UPDATE_BOOKING_STEP';

// Log Message
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// Obee areas
export const FETCH_OBEE_AREAS_PENDING = 'FETCH_OBEE_AREAS_PENDING';
export const FETCH_OBEE_AREAS_SUCCESS = 'FETCH_OBEE_AREAS_SUCCESS';
export const FETCH_OBEE_AREAS_FAILURE = 'FETCH_OBEE_AREAS_FAILURE';

// Obee Availability Suggestions
export const FETCH_OBEE_AVAILABILITY_SUGGESTIONS_PENDING =
  'FETCH_OBEE_AVAILABILITY_SUGGESTIONS_PENDING';
export const FETCH_OBEE_AVAILABILITY_SUGGESTIONS_SUCCESS =
  'FETCH_OBEE_AVAILABILITY_SUGGESTIONS_SUCCESS';
export const FETCH_OBEE_AVAILABILITY_SUGGESTIONS_FAILURE =
  'FETCH_OBEE_AVAILABILITY_SUGGESTIONS_FAILURE';

// Obee Multi-Venue Availability Suggestions
export const FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_PENDING =
  'FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_PENDING';
export const FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_SUCCESS =
  'FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_SUCCESS';
export const FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_FAILURE =
  'FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_FAILURE';
export const CLEAR_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS =
  'CLEAR_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS';

// Obee booking
export const CREATE_OBEE_BOOKING_PENDING = 'CREATE_OBEE_BOOKING_PENDING';
export const CREATE_OBEE_BOOKING_SUCCESS = 'CREATE_OBEE_BOOKING_SUCCESS';
export const CREATE_OBEE_BOOKING_FAILURE = 'CREATE_OBEE_BOOKING_FAILURE';

export const UPDATE_OBEE_BOOKING_PENDING = 'UPDATE_OBEE_BOOKING_PENDING';
export const UPDATE_OBEE_BOOKING_SUCCESS = 'UPDATE_OBEE_BOOKING_SUCCESS';
export const UPDATE_OBEE_BOOKING_FAILURE = 'UPDATE_OBEE_BOOKING_FAILURE';

export const CANCEL_OBEE_BOOKING_PENDING = 'CANCEL_OBEE_BOOKING_PENDING';
export const CANCEL_OBEE_BOOKING_SUCCESS = 'CANCEL_OBEE_BOOKING_SUCCESS';
export const CANCEL_OBEE_BOOKING_FAILURE = 'CANCEL_OBEE_BOOKING_FAILURE';

export const FETCH_OBEE_BOOKING_PENDING = 'FETCH_OBEE_BOOKING_PENDING';
export const FETCH_OBEE_BOOKING_SUCCESS = 'FETCH_OBEE_BOOKING_SUCCESS';
export const FETCH_OBEE_BOOKING_FAILURE = 'FETCH_OBEE_BOOKING_FAILURE';

export const CONFIRM_OBEE_BOOKING_PENDING = 'CONFIRM_OBEE_BOOKING_PENDING';
export const CONFIRM_OBEE_BOOKING_SUCCESS = 'CONFIRM_OBEE_BOOKING_SUCCESS';
export const CONFIRM_OBEE_BOOKING_FAILURE = 'CONFIRM_OBEE_BOOKING_FAILURE';

export const HOLD_OBEE_BOOKING_SUCCESS = 'HOLD_OBEE_BOOKING_SUCCESS';
export const HOLD_OBEE_BOOKING_FAILURE = 'HOLD_OBEE_BOOKING_FAILURE';

// Restaurant
export const RESTAURANT_FOR_ID_PENDING = 'RESTAURANT_FOR_ID_PENDING';
export const RESTAURANT_FOR_ID_SUCCESS = 'RESTAURANT_FOR_ID_SUCCESS';
export const RESTAURANT_FOR_ID_FAILURE = 'RESTAURANT_FOR_ID_FAILURE';

export const RESTAURANT_FOR_SLUG_PENDING = 'RESTAURANT_FOR_SLUG_PENDING';
export const RESTAURANT_FOR_SLUG_SUCCESS = 'RESTAURANT_FOR_SLUG_SUCCESS';
export const RESTAURANT_FOR_SLUG_FAILURE = 'RESTAURANT_FOR_SLUG_FAILURE';

export const RESTAURANTS_BY_IDS_PENDING = 'RESTAURANTS_BY_IDS_PENDING';
export const RESTAURANTS_BY_IDS_SUCCESS = 'RESTAURANTS_BY_IDS_SUCCESS';
export const RESTAURANTS_BY_IDS_FAILURE = 'RESTAURANTS_BY_IDS_FAILURE';

export const SET_RESTAURANT = 'SET_RESTAURANT';

// Integrations
export const FETCH_OBEE_INTEGRATIONS_PENDING = 'FETCH_OBEE_INTEGRATIONS_PENDING';
export const FETCH_OBEE_INTEGRATIONS_SUCCESS = 'FETCH_OBEE_INTEGRATIONS_SUCCESS';
export const FETCH_OBEE_INTEGRATIONS_FAILURE = 'FETCH_OBEE_INTEGRATIONS_FAILURE';

export const INITIALIZE_FACEBOOK_PIXEL = 'INITIALIZE_FACEBOOK_PIXEL';

// Payment settings
export const FETCH_PAYMENT_SETTINGS_PENDING = 'FETCH_PAYMENT_SETTINGS_PENDING';
export const FETCH_PAYMENT_SETTINGS_SUCCESS = 'FETCH_PAYMENT_SETTINGS_SUCCESS';
export const FETCH_PAYMENT_SETTINGS_FAILURE = 'FETCH_PAYMENT_SETTINGS_FAILURE';

// Terms
export const FETCH_BOOKING_TERMS_PENDING = 'FETCH_BOOKING_TERMS_PENDING';
export const FETCH_BOOKING_TERMS_SUCCESS = 'FETCH_BOOKING_TERMS_SUCCESS';
export const FETCH_BOOKING_TERMS_FAILURE = 'FETCH_BOOKING_TERMS_FAILURE';
