import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { restaurantPropTypes } from '../../../../../data/models/Restaurant';
import { onlyUnique } from '../../../../../utils/helpers';
import { Date } from './Date';
import { Slider } from './Slider';

const DateScroll = ({ restaurant }) => {
  const today = moment().startOf('day');

  const sessions = restaurant.data.areas.flatMap((area) => area.sessions);

  const openDays = sessions.map((session) => session.day).filter(onlyUnique);
  const openDates = sessions.map((session) => session.date).filter(onlyUnique);

  return (
    <Slider
      items={Array.from({ length: restaurant.data.daysInAdvance + 1 }, (_, i) => i).map((day) => {
        const date = moment(today).add(day, 'days');

        const closed = restaurant.data.closures?.some((closure) => {
          return date.isBetween(moment(closure.startDate), moment(closure.endDate), 'day', '[]');
        });

        const disabled =
          closed ||
          (!openDays.includes(date.format('ddd').toLowerCase()) &&
            !openDates.includes(date.format('YYYY-MM-DD')));

        return (
          <Date key={`date-${date.unix()}`} today={date} disabled={disabled} isToday={day === 0} />
        );
      })}
    />
  );
};

DateScroll.propTypes = {
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DateScroll);
