import { baseColors } from './base';

// note: for each `eatclubColors` color, there should be an obee alternative (e.g if eatclubColors.formBg exists, so does obeeColors.formBg)
export const eatclubColors = {
  liteNavigationUnderlineColour: baseColors.linenDark,
  liteIconBgColour: baseColors.tumeric,
  liteAvailabilityMainButtonBgColour: baseColors.tumeric,
  liteBackgroundValue: baseColors.linen,
  liteAvailabilityFieldsActiveStateColour: baseColors.charcoal,
  premiumAvailabilityMainButtonBgColour: baseColors.charcoal,
  liteWidgetHeaderBgColor: baseColors.linenDark,
};

export const fontFamily = "Gordita, 'Open Sans', 'Helvetica', 'sans-serif'";

export const eatclubTypography = {
  availabilityFontFamily: fontFamily,
  generalFontFamily: fontFamily,
};
