import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconDate } from '../../../../assets/icons/date.svg';
import { ReactComponent as IconGuests } from '../../../../assets/icons/guests.svg';
import { ReactComponent as IconTime } from '../../../../assets/icons/time.svg';
import Button from '../../../../components/Button/Button';
import { Modal } from '../../../../components/Modal';
import Spacer from '../../../../components/Spacer/Spacer';
import { Typography } from '../../../../components/Typography';
import { cancelObeeBookingAction } from '../../../../data/actions/obeeBookingAction';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import { STATUS_TYPES, obeeBookingPropTypes } from '../../../../data/models/obeeBooking';
import { trackEvent } from '../../../../utils/analytics';
import { formatDateToMoment, minutesToTimeString } from '../../../../utils/helpers';
import { useIsMobile } from '../../../../utils/hooks';
import useStyles from './CancelBookingModalStyles';

const CancelBookingModal = ({ cancelObeeBooking, close, obeeBooking, open, restaurant }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [cancelling, setCancelling] = useState(false);

  const isWaitlist = obeeBooking.data.status === STATUS_TYPES.WAITLIST;

  useEffect(() => {
    if (cancelling && obeeBooking.success) {
      setCancelling(false);

      close();
    }
  }, [obeeBooking.success]);

  return (
    <Modal
      open={open}
      onClose={close}
      title={`Are you sure you want to cancel your ${
        isWaitlist ? 'waitlist request' : 'reservation'
      }?`}
      centeredTitle
      footer={[
        <Button
          size='large'
          variant='outlined'
          onClick={() => {
            trackEvent('Booking', `Keeping ${isWaitlist ? 'Waitlist' : 'Booking'}`);
            close();
          }}
          disabled={cancelling}
          fullWidth={isMobile}
        >
          {isWaitlist ? 'Stay on the waitlist' : 'Keep my booking'}
        </Button>,
        <Button
          size='large'
          onClick={() => {
            trackEvent('Booking', `Cancelling ${isWaitlist ? 'Waitlist' : 'Booking'}`);
            setCancelling(true);

            cancelObeeBooking(obeeBooking.data.id);
          }}
          loading={cancelling}
          fullWidth={isMobile}
          color='primary'
        >
          {`Cancel my ${isWaitlist ? 'waitlist' : 'booking'}`}
        </Button>,
      ]}
    >
      <Spacer direction='vertical' gap='l' centered>
        <Spacer direction='vertical' centered>
          <Typography variant='h3'>{restaurant.data.name}</Typography>
          <Typography centered style={{ color: theme.colors.suvaGrey }}>
            {restaurant.data.address}
          </Typography>
        </Spacer>
        <Box className={classes.details}>
          <Spacer centered>
            <IconDate fill={restaurant.data.iconBgColour} />
            <Typography>
              {formatDateToMoment(obeeBooking.data.date).format('ddd Do of MMM')}
            </Typography>
          </Spacer>
          <Spacer centered>
            <IconGuests fill={restaurant.data.iconBgColour} />
            <Typography>{`${obeeBooking.data.size} ${
              obeeBooking.data.size > 1 ? 'guests' : 'guest'
            }`}</Typography>
          </Spacer>
          <Spacer centered>
            <IconTime fill={restaurant.data.iconBgColour} />
            <Typography>{minutesToTimeString(obeeBooking.data.time)}</Typography>
          </Spacer>
        </Box>

        {/* Can't charge a customer if theres no credit card tied to the booking */}
        {!isWaitlist && obeeBooking.data.paymentMethodId && (
          <Typography centered>
            Please note, any cancellations made within 24 hours to the booking date may be subject
            to a cancellation fee.
          </Typography>
        )}
      </Spacer>
    </Modal>
  );
};

CancelBookingModal.propTypes = {
  cancelObeeBooking: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  obeeBooking: obeeBookingPropTypes.isRequired,
  open: PropTypes.bool.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelObeeBooking: cancelObeeBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CancelBookingModal);
