import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: ({ modalHeaderColour }) => ({
    padding: '4px 24px',
    background: modalHeaderColour,
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  title: {
    padding: '16px 24px',
  },
  content: {
    [theme.breakpoints.up('xs')]: {
      padding: '20px 0',
    },
    background: theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white',
  },
  footer: {
    justifyContent: 'space-between',
    padding: '12px 24px',
    borderTop: `1px solid ${theme.colors.outlineColour}`,
    gap: '8px',
    position: 'sticky',
    bottom: 0,
    background: theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    margin: '12px',
    color: theme.colors.darkMode ? theme.colors.white : theme.colors.charcoal,
  },
}));

export default useStyles;
