import { API } from 'aws-amplify';
import { devLog } from '../../utils/devLog';
import { getAppVersion } from '../../utils/helpers';
import { createPaymentSetupIntent } from '../graphql/mutations';
import { lookupPaymentMethodName } from '../graphql/queries';
import store from '../store';

/* eslint-disable */
export const createPaymentSetupIntentAction = async (
  name,
  phone,
  email,
  userId,
  stripeId,
  stripe,
  card,
) => {
  const state = store?.getState();
  const restId = state.restaurant.data?.objectId;

  const response = await API.graphql(
    {
      query: createPaymentSetupIntent,
      variables: { name, phone, email, userId, stripeId },
    },
    {
      'rest-id': restId,
      'app-version': getAppVersion(),
    },
  ).catch((error) => {
    throw new Error(error.errors[0].message);
  });

  devLog('success', 'createPaymentSetupIntent', response.data.createPaymentSetupIntent);

  const parsedResponse = JSON.parse(response.data.createPaymentSetupIntent);
  const { secret, setupIntentId } = parsedResponse;

  const result = await stripe.confirmCardSetup(secret, {
    payment_method: {
      card,
      billing_details: {
        name,
      },
    },
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return { paymentMethodId: result.setupIntent.payment_method, setupIntentId };
};

const lookupPaymentMethodNameAction = async (paymentMethodId) => {
  const state = store?.getState();
  const restId = state.restaurant.data?.objectId;

  const response = await API.graphql(
    {
      query: lookupPaymentMethodName,
      variables: { paymentMethodId },
    },
    {
      'rest-id': restId,
      'app-version': getAppVersion(),
    },
  ).catch((error) => {
    throw error.errors[0].message;
  });

  devLog('success', 'lookupPaymentMethodName', response.data.lookupPaymentMethodName);

  const parsedResponse = JSON.parse(response.data.lookupPaymentMethodName);
  const { paymentMethodName } = parsedResponse;

  return paymentMethodName;
};

export const createPaymentMethodAction = async (
  name,
  phone,
  email,
  userId,
  stripeId,
  stripe,
  card,
) => {
  const { paymentMethodId, setupIntentId } = await createPaymentSetupIntentAction(
    name,
    phone,
    email,
    userId,
    stripeId,
    stripe,
    card,
  );
  const paymentMethodName = await lookupPaymentMethodNameAction(paymentMethodId);

  return [paymentMethodId, paymentMethodName, setupIntentId];
};
