import { Popover } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../../../components/Button';
import { Spacer } from '../../../../../components/Spacer';
import { Typography } from '../../../../../components/Typography';
import { updateBookingAction } from '../../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../../data/models/Booking';
import { restaurantPropTypes, restaurantsPropTypes } from '../../../../../data/models/Restaurant';
import { obeeAvailabilitySuggestionPropTypes } from '../../../../../data/models/obeeAvailabilitySuggestions';
import { trackEvent } from '../../../../../utils/analytics';
import { isNil, minutesToTimeString } from '../../../../../utils/helpers';
import useStyles from './TimeStyles';

const Time = ({
  availabilitySuggestion,
  booking,
  restaurant,
  restaurants,
  updateBooking,
  skipAreaSelection,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [preferenceAnchorEl, setPreferenceAnchorEl] = useState(null);

  // Whether this time is selected. Times may appear multiple times so we also check the table/area matches
  const suggestionHasBookingTable =
    availabilitySuggestion?.tables &&
    availabilitySuggestion?.tables.some(
      (table) =>
        table.id === booking?.availabilitySuggestion?.table?.id &&
        table.areaId === booking?.availabilitySuggestion?.table?.areaId,
    );

  const isSelected =
    booking.availabilitySuggestion?.time === availabilitySuggestion.time &&
    suggestionHasBookingTable &&
    (!booking.availabilitySuggestion?.restId ||
      booking.availabilitySuggestion?.restId === availabilitySuggestion.restId);
  const close = () => setPreferenceAnchorEl(null);

  const updateAvailabilitySuggestion = (table, preferredArea = null) => {
    trackEvent('Booking', 'Updating Available Time');
    updateBooking(bookingKeys.availabilitySuggestion, {
      restId: availabilitySuggestion.restId,
      time: availabilitySuggestion.time,
      table,
      preferredArea,
    });
    close();
  };

  const handleOnClick = (e) => {
    // make this active so we have better knowledge which time we're picking for
    if (!isSelected) {
      // Select highest priority table by default
      updateAvailabilitySuggestion(availabilitySuggestion.tables?.[0]);
    }

    // Only show if there are multiple areas and we're not filtered
    if (!skipAreaSelection && restaurant.data.areas.length > 1 && booking?.preferredAreaId === -1) {
      // has multiple area suggestions for the time slot
      setPreferenceAnchorEl(e.currentTarget);
    }
  };

  const areas = restaurants.data.find(
    (restaurantToCheck) => restaurantToCheck.objectId === availabilitySuggestion.restId,
  )?.areas;

  return (
    <>
      <Button
        className={`${classes.container} ${isSelected ? classes.selected : ''}`}
        onClick={handleOnClick}
        variant='outlined'
      >
        <Spacer direction='vertical' gap={0} style={{ width: '100%' }}>
          <Typography
            style={{
              fontWeight: isSelected ? theme.fontWeights.medium : theme.fontWeights.regular,
              lineHeight: 'normal',
            }}
          >
            {availabilitySuggestion.time === -1
              ? 'Any time'
              : minutesToTimeString(availabilitySuggestion.time)}
          </Typography>
          {isSelected && !isNil(booking.availabilitySuggestion?.preferredArea) && (
            <Typography className={classes.preferredArea} variant='caption'>
              {booking.availabilitySuggestion.preferredArea}
            </Typography>
          )}
        </Spacer>
      </Button>

      <Popover
        isOpen={!isNil(preferenceAnchorEl)}
        onClose={close}
        anchorEl={preferenceAnchorEl}
        showArrow
        innerStyle={{
          borderRadius: '8px',
          marginTop: '16px',
          minWidth: '240px',
          padding: 0,
          position: 'relative',
          width: 'fit-content',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Spacer direction='vertical' gap={8} style={{ padding: '20px 12px 12px 12px' }}>
          <Typography
            variant='caption'
            style={{
              color: theme.colors.nimbus,
              fontWeight: theme.fontWeights.medium,
              margin: '0 12px',
            }}
          >
            Select an area
          </Typography>

          <Spacer direction='vertical' gap={4}>
            {/* No preference */}
            <Button
              onClick={() => updateAvailabilitySuggestion(availabilitySuggestion.table)}
              style={{
                fontWeight: theme.fontWeights.regular,
                color: theme.colors.charcoal,
                padding: '8px 12px',
              }}
              variant='text'
            >
              <Typography style={{ textAlign: 'left', width: '100%' }}>No preference</Typography>
            </Button>

            {/* Area suggestions */}
            {areas?.map((area) => {
              let isFullyBooked = true;

              const table = availabilitySuggestion.tables?.find(
                (tableToFind) => tableToFind.areaId === area.id,
              );

              if (!isNil(table)) {
                // The area has an availability suggestion
                isFullyBooked = false;
              }

              const areaLabel = table?.areaLabel ?? area.name;

              return (
                <Button
                  key={area.id}
                  disabled={isFullyBooked}
                  onClick={() => updateAvailabilitySuggestion(table, area.name)}
                  style={{
                    fontWeight: theme.fontWeights.regular,
                    color: theme.colors.charcoal,
                    padding: '8px 12px',
                  }}
                  variant='text'
                >
                  <Typography style={{ textAlign: 'left', width: '100%' }}>
                    {areaLabel}
                    {isFullyBooked ? ' - Unavailable' : ''}
                  </Typography>
                </Button>
              );
            })}
          </Spacer>
        </Spacer>
      </Popover>
    </>
  );
};

Time.defaultProps = {
  skipAreaSelection: false,
};

Time.propTypes = {
  availabilitySuggestion: obeeAvailabilitySuggestionPropTypes.isRequired,
  booking: bookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  restaurants: restaurantsPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
  skipAreaSelection: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Time);
