import { API } from 'aws-amplify';
import { devLog } from '../../utils/devLog';
import { getAppVersion, isNil } from '../../utils/helpers';
import { graphQLQuery } from '../api/ApiClient';
import {
  cancelObeeBooking,
  confirmObeeBooking,
  createObeeBooking,
  freeHeldBooking,
  holdBooking,
  updateObeeBooking,
} from '../graphql/mutations';
import { bookingForCode, bookingForId, bookingUpdateCheck } from '../graphql/queries';
import store from '../store';
import { makeApiAction } from './shared/helpers';

export const createObeeBookingAction = (booking) => async (dispatch) => {
  const request = (variables) => graphQLQuery(createObeeBooking, variables);
  makeApiAction(
    'CREATE_OBEE_BOOKING',
    request,
    { booking },
    'createObeeBooking',
    'an error occurred while creating the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

export const updateObeeBookingAction = (bookingId, booking) => async (dispatch) => {
  const request = (variables) => graphQLQuery(updateObeeBooking, variables);
  makeApiAction(
    'UPDATE_OBEE_BOOKING',
    request,
    { bookingId, booking },
    'updateObeeBooking',
    'an error occurred while updating the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

export const cancelObeeBookingAction = (bookingId) => async (dispatch) => {
  const request = (variables) => graphQLQuery(cancelObeeBooking, variables);
  makeApiAction(
    'CANCEL_OBEE_BOOKING',
    request,
    { bookingId },
    'cancelObeeBooking',
    'an error occurred while cancelling the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Fetch a booking
 * @param {string} bookingId
 */
export const fetchObeeBookingAction = (bookingId) => async (dispatch) => {
  const request = (variables) => graphQLQuery(bookingForId, variables);
  makeApiAction(
    'FETCH_OBEE_BOOKING',
    request,
    { bookingId },
    'bookingForId',
    'an error occurred while retrieving the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Fetch a booking via code
 * @param {string} code
 */
export const fetchObeeBookingByCodeAction = (code) => async (dispatch) => {
  const request = (variables) => graphQLQuery(bookingForCode, variables);
  makeApiAction(
    'FETCH_OBEE_BOOKING',
    request,
    { code },
    'bookingForCode',
    'an error occurred while retrieving the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Confirm a booking
 * @param {string} bookingId
 */
export const confirmObeeBookingAction = (bookingId) => async (dispatch) => {
  const request = (variables) => graphQLQuery(confirmObeeBooking, variables);
  makeApiAction(
    'CONFIRM_OBEE_BOOKING',
    request,
    { bookingId },
    'confirmObeeBooking',
    'an error occurred while confirming the booking.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Place a booking on hold so nobody else can steal the table
 * @param booking
 * @param areaId
 * @param tableNumber
 * @param duration
 */
export const holdBookingAction = (booking, areaId, tableNumber, duration) => async (dispatch) => {
  const bookingHoldData = {
    areaId,
    date: booking.date,
    time: booking.availabilitySuggestion?.time,
    size: booking.guests,
    // name: [booking.firstName, booking.lastName].filter((name) => name).join(' '),
    tableNumber: tableNumber || null,
    duration: duration || 60,
  };

  // TODO support passing table ID through to reducer
  const request = (variables) => graphQLQuery(holdBooking, variables);
  makeApiAction(
    'HOLD_OBEE_BOOKING',
    request,
    { booking: bookingHoldData },
    'holdBooking',
    'Unable to reserve a booking for that time and area. Please try again.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Free up the hold on the booking
 * @param bookingId
 */
export const freeBookingAction = (bookingId) => async (dispatch) => {
  if (isNil(bookingId)) {
    return;
  }

  const request = (variables) => graphQLQuery(freeHeldBooking, variables);
  makeApiAction(
    'FREE_HELD_OBEE_BOOKING',
    request,
    { bookingId },
    'freeHeldObeeBooking',
    null,
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Checks if the booking is valid to update, returns `true` if it's available, `false` if it's unavailable.
 * @param {int} bookingId
 * @param {int} areaId
 * @param {string} date
 * @param {int} time
 * @param {int} size
 * @param {string} tableNumber
 * @param {int} duration
 * @returns {boolean}
 */
export const bookingUpdateCheckAction = async (
  bookingId,
  areaId,
  date,
  time,
  size,
  tableNumber,
  duration,
) => {
  try {
    const state = store?.getState();
    const restId = state.restaurant.data?.objectId;

    const response = await API.graphql(
      {
        query: bookingUpdateCheck,
        variables: {
          restId,
          bookingId,
          areaId,
          date,
          time,
          size,
          tableNumber,
          duration,
          public: true,
        },
      },
      {
        'rest-id': restId,
        'app-version': getAppVersion(),
      },
    ).catch((error) => {
      throw error.errors[0].message;
    });

    devLog('success', 'bookingUpdateCheck', response.data.bookingUpdateCheck);

    const parsedResponse = JSON.parse(response.data.bookingUpdateCheck);
    const { available, tables } = parsedResponse;

    return { available, tables };
  } catch (error) {
    devLog('error', 'bookingUpdateCheck', error);

    throw error;
  }
};
