import PropTypes from 'prop-types';
import {
  filterArrayUnique,
  findObjectByProperty,
  getNearestInArrayOfObjects,
  isEmpty,
  isEmptyObject,
  isNil,
  minutesToTimeString,
  sortByProperty,
  unique,
  updateObjectByProperty,
} from '../../utils/helpers';
import { ALL_SESSIONS_LABEL, getSessionLabel } from './obeeSessions';

export const obeeTablePropTypes = PropTypes.shape({
  id: PropTypes.number,
  areaId: PropTypes.number,
  tableNumber: PropTypes.string,
  tableName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  priority: PropTypes.number,
});

export const obeeAvailabilitySuggestionPropTypes = PropTypes.shape({
  restId: PropTypes.string,
  time: PropTypes.number,
  table: obeeTablePropTypes,
  tables: PropTypes.arrayOf(obeeTablePropTypes),
});

export const obeeAvailabilitySuggestionsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(obeeAvailabilitySuggestionPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

/**
 * Checks if the created booking is within the area selected
 * @param {*} booking
 * @param {*} obeeBooking
 * @param {*} session
 */
const isBookingWithinArea = (booking, obeeBooking, session) => {
  return booking.areaId === -1 || obeeBooking.areaId === session.areaId;
};

/**
 * Checks if the created booking is within the time range of the selected session
 * @param {*} obeeBooking
 * @param {*} session
 */
const isBookingWithinTime = (obeeBooking, session) => {
  return obeeBooking.time >= session.startTime && obeeBooking.time <= session.endTime;
};

/**
 * Checks if the created booking has the same date as the selected date
 * @param {*} obeeBooking
 * @param {*} booking
 * @returns
 */
const isBookingForDate = (obeeBooking, booking) => {
  return obeeBooking.date === booking.date;
};

/**
 * Checks if the created booking has the same size as the selected guests size
 * @param {*} obeeBooking
 * @param {*} booking
 * @returns
 */
const isBookingForSize = (obeeBooking, booking) => {
  return obeeBooking.size === booking.guests;
};

/**
 * Gets the created booking if the criteria are met
 * @param {*} booking
 * @param {*} obeeBooking
 * @param {string} restId
 * @param {*} session
 * @returns
 */
const getBookedSuggestion = (booking, obeeBooking, restId, session) => {
  if (isEmptyObject(obeeBooking)) {
    return null;
  }

  if (!isBookingWithinArea(booking, obeeBooking, session)) {
    return null;
  }

  if (!isBookingWithinTime(obeeBooking, session)) {
    return null;
  }

  if (!isBookingForDate(obeeBooking, booking)) {
    return null;
  }

  if (!isBookingForSize(obeeBooking, booking)) {
    return null;
  }

  return {
    restId,
    table: {
      areaId: obeeBooking.areaId,
      tableNumber: obeeBooking.tableNumber,
    },
    tables: [
      {
        areaId: obeeBooking.areaId,
        tableNumber: obeeBooking.tableNumber,
      },
    ],
    time: obeeBooking.time,
    waitlist: obeeBooking.status === 'waitlist',
    allAreas: booking.areaId === -1,
  };
};

/**
 * Get all available suggestions depending on `session`, if `waitlist` is `on` and if `allAreas`
 * @param {*} availabilitySuggestions
 * @param {*} session
 * @param {*} restaurant
 * @param {*} booking
 * @param {*} obeeBooking
 * @returns
 */
export const getAvailabilitySuggestions = (
  availabilitySuggestions,
  booking,
  obeeBooking,
  restaurant,
  session,
) => {
  if (!session) {
    return [];
  }

  const { startTime, endTime } = session;

  // construct a suggestion to show so a user can reselect their current booking time in the edit flow
  const bookedSuggestion = getBookedSuggestion(booking, obeeBooking, restaurant.objectId, session);

  const sessionSuggestions = availabilitySuggestions.filter(
    (availabilitySuggestion) =>
      availabilitySuggestion.time >= startTime && availabilitySuggestion.time < endTime,
  );

  return [
    ...(isNil(bookedSuggestion) ? [] : [bookedSuggestion]), // place first so it has priority for `unique()`
    ...sessionSuggestions.map((sessionSuggestion) => ({
      ...sessionSuggestion,
      allAreas: booking.areaId === -1,
      session,
    })),
  ];
};

/**
 * Gets all the suggestions for a sessionId
 * @param {*} booking
 * @param {*} sessions
 * @param {*} availabilitySuggestions
 * @param {*} obeeBooking
 * @param {*} restaurant
 * @param allSessions
 * @returns
 */
export const getAvailabilitySuggestionsForSession = (
  booking,
  sessions,
  availabilitySuggestions,
  obeeBooking,
  restaurant,
  allSessions, // Used to know if this is overriding default sessions
  filteredLabel = null,
) => {
  const areasThatOverrideDefaultHours = filterArrayUnique(
    allSessions.map((session) => session.areaId),
  ).filter((area) => area.areaId !== 0);

  const availabilities = sessions
    .filter((session) => {
      if (!filteredLabel || filteredLabel === ALL_SESSIONS_LABEL) {
        // "All" sessions selected
        return true;
      }

      // Specific session selected
      return `${getSessionLabel(session.label)}`.toLowerCase() === `${filteredLabel}`.toLowerCase();
    })
    .flatMap((session) =>
      getAvailabilitySuggestions(
        availabilitySuggestions,
        booking,
        obeeBooking,
        restaurant,
        session,
      ),
    )
    .map((availabilitySuggestion) => {
      // Filter out some availabilities and modify the data where needed
      if (isEmpty(availabilitySuggestion)) {
        return null;
      }

      // Return the availability suggestion with only the tables it supports
      // Filter out tables that aren't in this session (e.g. overridden by another session)
      const tablesForAvailability = unique(
        (availabilitySuggestion?.tables || [])
          .filter(
            (table) =>
              (availabilitySuggestion?.session?.areaId <= 0 &&
                !areasThatOverrideDefaultHours.includes(table?.areaId)) ||
              table?.areaId === availabilitySuggestion?.session?.areaId,
          )
          .map((table) => ({ ...table, key: `${table?.areaId}-${table?.id}` })),
        'key',
      );

      // Add the sitting time label if this is a sitting time session
      const hasSittingTimes = !isEmpty(availabilitySuggestion.session?.sittingTimes);

      const timeAsObeeString = minutesToTimeString(availabilitySuggestion.time);
      const sittingTimeLabel =
        availabilitySuggestion?.session?.sittingTimes.find((sittingTimeGroup) =>
          sittingTimeGroup.times.includes(timeAsObeeString),
        )?.label ?? availabilitySuggestion?.session?.label;

      // This is where we'll add the sitting time labels
      const tablesWithAreaIncluded = tablesForAvailability.map((table) => {
        const areaLabel =
          findObjectByProperty(restaurant.areas, table?.areaId, 'id')?.name +
          (hasSittingTimes ? ` - ${sittingTimeLabel}` : '');

        return {
          ...table,
          areaLabel,
        };
      });

      const availabilityWithThisAreaOnly = {
        ...availabilitySuggestion,
        tables: tablesWithAreaIncluded,
      };

      return availabilityWithThisAreaOnly;
    })
    .filter((availability) => !isEmpty(availability.tables));

  // Merge all availabilities into each unique time
  const availabilitiesDeduped = availabilities.reduce((accum, availability) => {
    const existingObject = findObjectByProperty(accum, availability.time, 'time');

    if (isEmpty(existingObject)) {
      return [...accum, availability];
    }

    const availabilityMerged = {
      ...existingObject,
      tables: [...existingObject.tables, ...availability.tables],
      // session: null, // The merge could have availabilities from multiple sessions
    };
    return updateObjectByProperty(accum, availabilityMerged, 'time');
  }, []);

  // // Dedupe the tables though
  // const withTablesDeduped = availabilitiesDeduped
  //   .map((availability) => ({
  //     ...availability,
  //     tables: unique(availability.tables, 'id'),
  //   }))
  //   .filter((availability) => !isEmpty(availability.tables));

  return availabilitiesDeduped.sort((a, b) => sortByProperty(a, b, 'time'));
};

/**
 * Gets the nearest available suggestion before and after the preferredTime
 * @param {{}[]} availableSuggestions
 * @param {number} preferredTime
 * @returns
 */
export const getNearestAvailableSuggestions = (availableSuggestions, preferredTime) => {
  const nearestBeforePreferredTime = getNearestInArrayOfObjects(
    availableSuggestions,
    'time',
    preferredTime,
    '<',
  );

  const nearestAfterPreferredTime = getNearestInArrayOfObjects(
    availableSuggestions,
    'time',
    preferredTime,
    '>',
  );

  // make sure we don't we return two of the same times
  return unique([nearestBeforePreferredTime, nearestAfterPreferredTime], 'time');
};
