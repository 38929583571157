import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import { ReactComponent as IconMinus } from '../../../assets/icons/minus.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { Button } from '../../../components/Button';
import { IconButton } from '../../../components/IconButton';
import { Spacer } from '../../../components/Spacer';
import { TextField } from '../../../components/TextField';
import { Typography } from '../../../components/Typography';
import {
  batchUpdateBookingAction,
  updateBookingAction,
  updateBookingStepAction,
} from '../../../data/actions/bookingAction';
import { fetchBookingTermsAction } from '../../../data/actions/bookingTermsAction';
import { holdBookingAction } from '../../../data/actions/obeeBookingAction';
import { fetchPaymentSettingsAction } from '../../../data/actions/paymentSettingsAction';
import {
  bookingKeys,
  bookingPropTypes,
  bookingSteps,
  isEditing,
} from '../../../data/models/Booking';
import { bookingTermsPropTypes } from '../../../data/models/BookingTerms';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import { PageLayout } from '../../../layouts/PageLayout';
import { WidgetLayout } from '../../../layouts/WidgetLayout';
import { trackEvent } from '../../../utils/analytics';
import { ERROR_MESSAGES, isObee } from '../../../utils/constants';
import { deepEqual, isEmpty } from '../../../utils/helpers';
import { useIsMobile } from '../../../utils/hooks';

const Details = ({
  booking,
  bookingTerms,
  restaurant,
  fetchBookingTerms,
  fetchPaymentSettings,
  updateBooking,
  updateBookingStep,
  holdBooking,
  batchUpdateBooking,
}) => {
  const rollbar = useRollbar();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const [firstErrors, setFirstErrors] = useState(null);
  const [lastErrors, setLastErrors] = useState(null);
  const [mobileErrors, setMobileErrors] = useState(null);
  const [emailErrors, setEmailErrors] = useState(null);

  const highchairCap = 4; // TODO: don't forget about this, should the limit of highchairs be a setting or dependant on guest/kid size?

  const submit = () => {
    trackEvent('Navigation', 'Updating Step', 'Next Button');
    let valid = true;

    const trimFirstName = booking.firstName?.trim();
    if (isEmpty(trimFirstName)) {
      valid = false;
      rollbar.warning(`Invalid first name: ${trimFirstName}`);
      setFirstErrors(ERROR_MESSAGES.requiredField);
    } else {
      setFirstErrors(null);
      updateBooking(bookingKeys.firstName, trimFirstName);
    }

    const trimLastName = booking.lastName?.trim();
    if (isEmpty(trimLastName)) {
      valid = false;
      rollbar.warning(`Invalid last name: ${trimLastName}`);
      setLastErrors(ERROR_MESSAGES.requiredField);
    } else {
      setLastErrors(null);
      updateBooking(bookingKeys.lastName, trimLastName);
    }

    const trimMobile = booking.mobile?.trim();
    if (!validator.isMobilePhone(trimMobile || '')) {
      valid = false;
      rollbar.warning(`Invalid phone number: ${trimMobile}`);
      setMobileErrors(ERROR_MESSAGES.validMobile);
    } else {
      setMobileErrors(null);
      updateBooking(bookingKeys.mobile, trimMobile);
    }

    const trimEmail = booking.email?.trim();
    if (!validator.isEmail(trimEmail || '')) {
      valid = false;
      rollbar.warning(`Invalid email: ${trimEmail}`);
      setEmailErrors(ERROR_MESSAGES.validEmail);
    } else {
      setEmailErrors(null);
      updateBooking(bookingKeys.email, trimEmail);
    }

    if (valid) {
      updateBookingStep(bookingSteps.review);
    }
  };

  /**
   * Pre-fetch payment settings for next screen
   */
  useEffect(() => {
    if (!isObee) {
      return;
    }

    const { date, availabilitySuggestion, guests } = booking;

    const areaId = availabilitySuggestion?.table?.areaId || booking?.areaId;

    if (!areaId) {
      // todo: confirm that if we should fetch payments for waitlist, theres a flow where joining the waitlist doesn't assign a table/area, areaId is required
      return;
    }

    fetchPaymentSettings({
      paymentSettings: {
        date,
        time: availabilitySuggestion?.time,
        size: guests,
        public: 1,
        areaId,
      },
      restId: restaurant.data.objectId,
    });
  }, []);

  useEffect(() => {
    // note: EatClub has the terms included in the restaurant object
    if (!isObee) {
      return;
    }

    const { date, availabilitySuggestion, guests } = booking;

    const areaId = availabilitySuggestion?.table?.areaId;

    const newVariables = {
      restId: restaurant.data.objectId,
      areaId,
      date,
      time: availabilitySuggestion?.time,
      size: guests,
    };

    if (deepEqual(newVariables, bookingTerms.lastUsedVariables)) {
      // the terms have been fetched
      return;
    }

    fetchBookingTerms(areaId, date, availabilitySuggestion?.time, guests);
  }, []);

  // Hold the booking if we came from a linked venue
  useEffect(() => {
    const areaForBooking = restaurant.data.areas.find(
      (area) => area.id === booking.availabilitySuggestion?.table?.areaId,
    );

    if (!isEditing(booking) && !booking?.heldBookingId && !booking.waitlist) {
      holdBooking(
        booking,
        booking?.availabilitySuggestion?.table?.areaId,
        booking?.availabilitySuggestion?.table?.tableNumber,
        areaForBooking?.duration,
      );
    }
  }, []);

  return (
    <PageLayout>
      <WidgetLayout showDetails>
        <Spacer direction='vertical' gap='m' style={{ width: '100%' }}>
          <Typography variant='h3'>Provide your booking details</Typography>
          <Spacer direction='vertical'>
            <Spacer wrap={isMobile}>
              <TextField
                onFocus={() => trackEvent('Booking', 'Updating First Name')}
                autoFocus={!booking.firstName}
                required
                error={!isEmpty(firstErrors)}
                helperText={<Typography variant='caption'>{firstErrors}</Typography>}
                label='First name'
                value={booking.firstName || ''}
                onChange={(e) => updateBooking(bookingKeys.firstName, e.target.value)}
                InputProps={{ disableUnderline: true }}
                onBlur={(e) => {
                  if (!isEmpty(firstErrors) && !isEmpty(e.target.value)) {
                    setFirstErrors(null);
                  }
                }}
                autoComplete='given-name'
                name='firstName'
              />
              <TextField
                onFocus={() => trackEvent('Booking', 'Updating Last Name')}
                required
                error={!isEmpty(lastErrors)}
                helperText={<Typography variant='caption'>{lastErrors}</Typography>}
                label='Last name'
                value={booking.lastName || ''}
                onChange={(e) => updateBooking(bookingKeys.lastName, e.target.value)}
                InputProps={{ disableUnderline: true }}
                onBlur={(e) => {
                  if (!isEmpty(lastErrors) && !isEmpty(e.target.value)) {
                    setLastErrors(null);
                  }
                }}
                autoComplete='family-name'
                name='lastName'
              />
            </Spacer>
            <Box>
              <TextField
                onFocus={() => trackEvent('Booking', 'Updating Mobile Number')}
                required
                error={!isEmpty(mobileErrors)}
                helperText={<Typography variant='caption'>{mobileErrors}</Typography>}
                label='Mobile'
                value={booking.mobile || ''}
                onChange={(e) => updateBooking(bookingKeys.mobile, e.target.value)}
                type='tel'
                InputProps={{
                  disableUnderline: true,
                  // startAdornment: <InputAdornment position='start'>+61</InputAdornment>,
                }}
                onBlur={(e) => {
                  if (!isEmpty(mobileErrors) && validator.isMobilePhone(e.target.value)) {
                    setMobileErrors(null);
                  }
                }}
                autoComplete='tel-national'
                name='mobile'
              />
            </Box>
            <Box>
              <TextField
                onFocus={() => trackEvent('Booking', 'Updating Email')}
                required
                error={!isEmpty(emailErrors)}
                helperText={<Typography variant='caption'>{emailErrors}</Typography>}
                label='Email'
                value={booking.email || ''}
                onChange={(e) => updateBooking(bookingKeys.email, e.target.value)}
                type='email'
                InputProps={{ disableUnderline: true }}
                onBlur={(e) => {
                  if (!isEmpty(emailErrors) && validator.isEmail(e.target.value)) {
                    setEmailErrors(null);
                  }
                }}
                autoComplete='email'
                name='email'
              />
              <Typography
                variant='caption'
                style={{ margin: '4px 0 0 0', color: theme.colors.secondaryTextColour }}
              >
                For booking confirmation and reminders.
              </Typography>
            </Box>
            <Box>
              <TextField
                style={{ marginTop: '12px' }}
                onFocus={() => trackEvent('Booking', 'Updating Special Requirements')}
                label='Special requirements'
                multiline
                minRows={8}
                value={booking.specialRequirements || ''}
                onChange={(e) => updateBooking(bookingKeys.specialRequirements, e.target.value)}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
          </Spacer>

          {/* TODO - support sending to backend when creating a booking */}
          {restaurant.data.showChildren && (
            <Spacer style={{ alignItems: 'center', justifyContent: 'space-between' }} wrap>
              <Typography
                style={{
                  fontWeight: theme.fontWeights.medium,
                  color: theme.colors.secondaryTextColour,
                }}
              >
                Are any children on the booking?
              </Typography>
              <Spacer
                centered
                style={{
                  border: `1px solid ${theme.colors.outlineColour}`,
                  padding: '8px 12px',
                  borderRadius: '6px',
                  width: '132px',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{booking.kids === 0 ? 'None' : booking.kids}</Typography>
                <Spacer>
                  <IconButton
                    disabled={booking.kids === 0}
                    variant='outlined'
                    onClick={() => {
                      trackEvent('Booking', 'Updating Children', 'Decrement');
                      const newKids = Math.max(booking.kids - 1, 0); // Default in case it's NaN
                      batchUpdateBooking({
                        [bookingKeys.kids]: newKids,
                        [bookingKeys.highchairs]: Math.min(newKids, booking.highchairs),
                      });
                    }}
                  >
                    <IconMinus />
                  </IconButton>
                  <IconButton
                    disabled={booking.kids >= booking.guests}
                    variant='outlined'
                    onClick={() => {
                      trackEvent('Booking', 'Updating Children', 'Increment');
                      if (Number.isNaN(booking.kids)) {
                        updateBooking(bookingKeys.kids, 1);
                      } else {
                        updateBooking(bookingKeys.kids, Math.min(booking.kids + 1, booking.guests));
                      }
                    }}
                  >
                    <IconPlus />
                  </IconButton>
                </Spacer>
              </Spacer>
            </Spacer>
          )}

          {booking.kids > 0 && restaurant.data.showHighchairs && (
            <Spacer style={{ alignItems: 'center', justifyContent: 'space-between' }} wrap>
              <Typography
                style={{
                  fontWeight: theme.fontWeights.medium,
                  color: theme.colors.secondaryTextColour,
                }}
              >
                Do you need any high chairs?
              </Typography>
              <Spacer
                centered
                style={{
                  border: `1px solid ${theme.colors.outlineColour}`,
                  padding: '8px 12px',
                  borderRadius: '6px',
                  width: '132px',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{booking.highchairs === 0 ? 'None' : booking.highchairs}</Typography>
                <Spacer>
                  <IconButton
                    disabled={booking.highchairs === 0}
                    variant='outlined'
                    onClick={() => {
                      trackEvent('Booking', 'Updating Highchairs', 'Decrement');
                      updateBooking(bookingKeys.highchairs, Math.max(booking.highchairs - 1, 0));
                    }}
                  >
                    <IconMinus />
                  </IconButton>
                  <IconButton
                    disabled={
                      booking.highchairs >= highchairCap || booking.highchairs >= booking.kids
                    }
                    variant='outlined'
                    onClick={() => {
                      trackEvent('Booking', 'Updating Highchairs', 'Increment');
                      updateBooking(
                        bookingKeys.highchairs,
                        Math.min(booking.highchairs + 1, highchairCap, booking.kids),
                      );
                    }}
                  >
                    <IconPlus />
                  </IconButton>
                </Spacer>
              </Spacer>
            </Spacer>
          )}

          <Box style={{ display: 'flex' }}>
            <Button
              size='large'
              onClick={submit}
              style={{ marginLeft: 'auto', minWidth: '144px' }}
              fullWidth={isMobile}
              color='primary'
            >
              Next
            </Button>
          </Box>
        </Spacer>
      </WidgetLayout>
    </PageLayout>
  );
};

Details.propTypes = {
  booking: bookingPropTypes.isRequired,
  bookingTerms: bookingTermsPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  fetchBookingTerms: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
  fetchPaymentSettings: PropTypes.func.isRequired,
  holdBooking: PropTypes.func.isRequired,
  batchUpdateBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  bookingTerms: state.bookingTerms,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBookingTerms: fetchBookingTermsAction,
      updateBooking: updateBookingAction,
      updateBookingStep: updateBookingStepAction,
      fetchPaymentSettings: fetchPaymentSettingsAction,
      holdBooking: holdBookingAction,
      batchUpdateBooking: batchUpdateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Details);
