import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  items: {
    display: 'flex',
    gap: '8px',
    position: 'absolute',
  },
  iconButton: {
    alignItems: 'center',
    display: 'flex',
    height: '28px',
    justifyContent: 'center',
    width: '28px',
  },
  prevBtnWrapper: {
    left: '-34px',
    marginTop: '-10px',
    position: 'absolute',
  },
  nextBtnWrapper: {
    marginTop: '-10px',
    position: 'absolute',
    right: '-34px',
  },
}));

export default useStyles;
