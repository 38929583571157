import { IconButton as MuiIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './IconButtonStyles';

const IconButton = ({ children, className, onClick, size, style, variant, ...rest }) => {
  const classes = useStyles({ style, variant });

  return (
    <MuiIconButton
      className={`${classes.container} ${className}`}
      size={size}
      disableRipple
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiIconButton>
  );
};

IconButton.defaultProps = {
  children: null,
  className: '',
  onClick: null,
  size: 'small',
  style: {},
  variant: 'contained',
};

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default IconButton;
