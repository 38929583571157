import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconCalPrev } from '../../assets/icons/caret_left.svg';
// import { ReactComponent as IconTick } from '../../assets/icons/check_mark.svg';
import { ReactComponent as IconTick } from '../../assets/icons/check_small.svg';
import { IconButton } from '../../components/IconButton';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { updateBookingStepAction } from '../../data/actions/bookingAction';
import { setRestaurantAction } from '../../data/actions/restaurantAction';
import { bookingPropTypes, bookingSteps, isEditing } from '../../data/models/Booking';
import { restaurantPropTypes } from '../../data/models/Restaurant';
import { formatDateToMoment, isEmpty, minutesToTimeString, shadeHex } from '../../utils/helpers';
import { useIsMobile } from '../../utils/hooks';
import useStyles from './WidgetLayoutStyles';

const WidgetLayout = ({
  booking,
  children,
  restaurant,
  setRestaurant,
  showDetails,
  showBackButton,
  size,
  updateBookingStep,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const classes = useStyles({
    size,
  });

  const { waitlist } = booking;

  const onBack = () => {
    switch (booking.step) {
      case bookingSteps.details:
        if (booking.initialRestId !== restaurant.data.objectId && !isEditing(booking)) {
          // set the parent venue onBack if we aren't in the edit flow for a different venue
          setRestaurant(booking.initialRestId);
        }

        updateBookingStep(bookingSteps.availability);
        break;
      case bookingSteps.review:
        updateBookingStep(bookingSteps.details);
        break;
      case bookingSteps.availability:
      case bookingSteps.confirm:
      default:
    }
  };

  const selectedAreaId = booking?.availabilitySuggestion?.table?.areaId || booking?.areaId;
  const selectedArea =
    booking?.availabilitySuggestion?.table?.areaLabel ??
    restaurant.data.areas.find((area) => area?.id === selectedAreaId)?.name;

  return (
    <Box
      style={{
        background: restaurant.data.availabilityBackgroundBoxBgColour,
        border: `${restaurant.data.availabilityBackgroundBoxBorderWeight}px solid ${restaurant.data.availabilityBackgroundBoxBorderColour}`,
        fontFamily: `'${restaurant.data.availabilityFontFamilies}', 'Open Sans', 'Helvetica', 'sans-serif'`,
        margin: '12px',
      }}
    >
      {showDetails && (
        <Spacer
          gap={12}
          className={classes.bookingsDetails}
          style={{
            alignItems: 'stretch',
            borderBottom: `1px solid ${theme.colors.outlineColour}`,
          }}
        >
          {showBackButton && (
            <>
              <IconButton
                onClick={onBack}
                style={{
                  // color: isBright(restaurant.data.widgetHeaderBgColor)
                  //   ? theme.colors.charcoal
                  //   : theme.colors.white,
                  height: '32px',
                  width: '32px',
                }}
                variant='outlined'
              >
                <IconCalPrev />
              </IconButton>

              {!isMobile && (
                <Box
                  style={{
                    borderRight: `1px solid ${shadeHex(
                      theme.colors.availabilityBackgroundBoxBgColour,
                      -8.4,
                    )}`,
                  }}
                />
              )}
            </>
          )}

          <Spacer
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{
              color: theme.colors.mainTextColour,
              display: isMobile ? 'flex' : 'grid',
              // gridTemplateColumns: '1fr auto',
              gridTemplateColumns: 'minmax(150px, 1fr) minmax(50%, auto)',
              rowGap: '16px',
              padding: '4px',
              columnGap: '16px',
              width: '100%',
            }}
          >
            {[
              formatDateToMoment(booking.date).format('ddd Do of MMMM'),
              `${booking.guests} ${booking.guests > 1 ? 'guests' : 'guest'}`,
              `${waitlist ? 'Requested time' : 'Arrival'} ${minutesToTimeString(
                booking.availabilitySuggestion?.time,
              )}`,
            ].map((item) => (
              <Spacer
                centered={!isMobile}
                key={item}
                style={{
                  justifyContent: 'flex-start',
                  gap: '12px',
                }}
              >
                <IconTick style={{ marginTop: '2px' }} />
                <Typography>{item}</Typography>
              </Spacer>
            ))}

            {!isEmpty(selectedArea) && (
              <Spacer style={{ justifyContent: 'flex-start', gap: '12px' }}>
                <IconTick style={{ marginTop: '2px', minWidth: '15px' }} />
                <Typography>{selectedArea}</Typography>
              </Spacer>
            )}
          </Spacer>
        </Spacer>
      )}

      <Box className={classes.widget}>{children}</Box>
    </Box>
  );
};

WidgetLayout.defaultProps = {
  showDetails: false,
  showBackButton: true,
  size: 'regular',
};

WidgetLayout.propTypes = {
  booking: bookingPropTypes.isRequired,
  children: PropTypes.node.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  setRestaurant: PropTypes.func.isRequired,
  showDetails: PropTypes.bool,
  showBackButton: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
  updateBookingStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRestaurant: setRestaurantAction,
      updateBookingStep: updateBookingStepAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLayout);
