import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconBYO } from '../../../assets/icons/byo.svg';
import { ReactComponent as IconCake } from '../../../assets/icons/cake.svg';
import { ReactComponent as IconChildFriendlyVenue } from '../../../assets/icons/child_friendly_venue.svg';
import { ReactComponent as IconCorkage } from '../../../assets/icons/corkage.svg';
import { ReactComponent as IconDate } from '../../../assets/icons/date.svg';
import { ReactComponent as IconDisabledAccess } from '../../../assets/icons/disabled_access.svg';
import { ReactComponent as IconDressCode } from '../../../assets/icons/dress_code.svg';
import { ReactComponent as IconGuests } from '../../../assets/icons/guests.svg';
import { ReactComponent as IconMarker } from '../../../assets/icons/marker.svg';
import { ReactComponent as IconChildrensPlayArea } from '../../../assets/icons/playground.svg';
import { ReactComponent as IconTime } from '../../../assets/icons/time.svg';
import { Button } from '../../../components/Button';
import { Information } from '../../../components/Information';
import { Spacer } from '../../../components/Spacer';
import { Typography } from '../../../components/Typography';
import {
  batchUpdateBookingAction,
  clearBookingAction,
  editBookingAction,
  editWaitlistAction,
} from '../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../data/models/Booking';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import {
  STATUS_TYPES,
  allowedStatusesForCancel,
  getConfirmationContent,
  getConfirmationIcon,
  getConfirmationTitle,
  isPastBooking,
} from '../../../data/models/obeeBooking';
import { ALL_SESSIONS_LABEL } from '../../../data/models/obeeSessions';
import { PageLayout } from '../../../layouts/PageLayout';
import { WidgetLayout } from '../../../layouts/WidgetLayout';
import { trackEvent } from '../../../utils/analytics';
import {
  byoMessage,
  cakesMessage,
  childrensMenuMessage,
  corkageMessage,
  disabledAccessMessage,
  dressCodeMessage,
  playAreaMessage,
} from '../../../utils/constants';
import { formatDateToMoment, isEmpty, minutesToTimeString } from '../../../utils/helpers';
import { useIsMobile } from '../../../utils/hooks';
import { useCoordinates } from '../../../utils/hooks/useCoordinates';
import { CancelBookingModal } from './CancelBookingModal';
import useStyles from './ConfirmStyles';
// import { GoogleCalendar } from './GoogleCalendar';
import { Map } from './Map';

// import { ReviewExperienceModal } from './ReviewExperienceModal';

const Confirm = ({
  batchUpdateBooking,
  booking,
  clearBooking,
  confirmCancel,
  editBooking,
  editWaitlist,
  obeeBooking,
  restaurant,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const [openReviewExperienceModal, setOpenReviewExperienceModal] = useState(false);
  // const [hasReviewed, setHasReviewed] = useState(false);
  const isMobile = useIsMobile();

  const { longitude, latitude, address } = restaurant.data;
  const coordinates = useCoordinates(longitude, latitude, address);

  const pastBooking = isPastBooking(obeeBooking.data);

  const isWaitlist = obeeBooking.data.status === STATUS_TYPES.WAITLIST && !pastBooking;

  const isBooked = obeeBooking.data.status === STATUS_TYPES.BOOKED && !pastBooking;

  const isConfirmed =
    [STATUS_TYPES.CONFIRMED, STATUS_TYPES.ARRIVED].includes(obeeBooking.data.status) &&
    !pastBooking;

  const isCancelled =
    [STATUS_TYPES.NO_SHOW, STATUS_TYPES.CANCELLED, STATUS_TYPES.UNCONFIRMED].includes(
      obeeBooking.data.status,
    ) && !pastBooking;

  const isCancelledWaitlist = obeeBooking.data.status === STATUS_TYPES.UNCONFIRMED && !pastBooking;

  const isFinished = obeeBooking.data.status === STATUS_TYPES.FINISHED || pastBooking;

  const [openCancelBookingModal, setOpenCancelBookingModal] = useState(
    allowedStatusesForCancel.includes(obeeBooking.data.status) && !pastBooking && confirmCancel,
  );

  const getConfirmationCard = () => {
    if (isFinished) {
      return null;
    }

    return (
      <Spacer direction='vertical' gap='l' style={{ width: '100%' }}>
        <Box className={classes.details}>
          <Spacer gap='s' className={classes.item}>
            <IconDate fill={restaurant.data.iconBgColour} />
            <Typography>
              {formatDateToMoment(obeeBooking.data.date).format('ddd Do MMM')}
            </Typography>
          </Spacer>
          <Spacer gap='s' className={classes.item}>
            <IconGuests fill={restaurant.data.iconBgColour} />
            <Typography>
              {`${obeeBooking.data.size} ${obeeBooking.data.size > 1 ? 'guests' : 'guest'}`}
            </Typography>
          </Spacer>
          <Spacer gap='s' className={classes.item}>
            <IconTime fill={restaurant.data.iconBgColour} />
            <Typography>{`${
              isWaitlist || isCancelledWaitlist ? 'Requested time' : 'Arrival'
            } ${minutesToTimeString(obeeBooking.data.time)}`}</Typography>
          </Spacer>
        </Box>

        {/* todo: Hide feature for now till google approved */}
        {/* {(isBooked || isConfirmed) && <GoogleCalendar />} */}

        <Spacer
          direction='vertical'
          gap='m'
          style={{
            border: `1px solid ${theme.colors.outlineColour}`,
            borderRadius: '6px',
            padding: '12px',
          }}
        >
          <Typography>
            <b>Booking details</b>
          </Typography>

          <Spacer direction='vertical' gap='m'>
            <Box style={{ display: 'grid', gridTemplateColumns: 'min-content auto', gap: '16px' }}>
              {[
                { label: 'Name', value: obeeBooking.data.name },
                { label: 'Email', value: obeeBooking.data.guests[0].email },
                { label: 'Phone', value: obeeBooking.data.guests[0].mobile },
              ].map(({ label, value }) => (
                <Fragment key={label}>
                  <Typography style={{ color: theme.colors.secondaryTextColour }}>
                    {label}
                  </Typography>
                  <Typography style={{ overflowWrap: 'anywhere' }}>{value}</Typography>
                </Fragment>
              ))}

              {obeeBooking.data.kids > 0 && (
                <>
                  <Typography style={{ color: theme.colors.secondaryTextColour }}>
                    Children
                  </Typography>
                  <Typography style={{ overflowWrap: 'anywhere' }}>
                    {obeeBooking.data.kids}
                  </Typography>
                </>
              )}

              {obeeBooking.data.highchairs > 0 && (
                <>
                  <Typography style={{ color: theme.colors.secondaryTextColour }}>
                    High chairs
                  </Typography>
                  <Typography style={{ overflowWrap: 'anywhere' }}>
                    {obeeBooking.data.highchairs}
                  </Typography>
                </>
              )}
            </Box>

            {!isEmpty(obeeBooking.data.customerComments) && (
              <Spacer direction='vertical'>
                <Typography style={{ color: theme.colors.secondaryTextColour }}>
                  Special requests
                </Typography>
                <Typography>{obeeBooking.data.customerComments}</Typography>
              </Spacer>
            )}
          </Spacer>

          {(isBooked || isWaitlist) && (
            <Divider
              color={theme.colors.outlineColour}
              style={{ marginLeft: '-12px', width: 'calc(100% + 24px)' }}
            />
          )}

          <Spacer gap='m' style={{ rowGap: '8px' }} wrap>
            {isWaitlist && (
              <Button
                onClick={() => {
                  trackEvent('Waitlist', 'Editing Waitlist', 'Confirmation');
                  editWaitlist();
                }}
                variant='outlined'
              >
                Edit waitlist request
              </Button>
            )}

            {isBooked && (
              <Button
                size='small'
                onClick={() => {
                  trackEvent('Booking', 'Editing Booking', 'Confirmation');

                  if (booking.initialRestId !== restaurant.data.objectId) {
                    // reset some information that might clash when editing a multi venue booking
                    batchUpdateBooking({
                      [bookingKeys.areaId]: -1,
                      [bookingKeys.sessionId]: -1,
                      [bookingKeys.sessionLabel]: ALL_SESSIONS_LABEL,
                      [bookingKeys.preferredTime]: -1,
                    });
                  }

                  editBooking();
                }}
                variant='outlined'
              >
                Edit booking
              </Button>
            )}
          </Spacer>
        </Spacer>
      </Spacer>
    );
  };

  return (
    <PageLayout>
      <WidgetLayout>
        <Spacer direction='vertical' gap={isMobile ? 'm' : 'l'} centered>
          <Spacer direction='vertical' gap='m' centered>
            {getConfirmationIcon(isWaitlist, restaurant.data.premium, theme)}

            <Typography variant='h2' centered style={{ maxWidth: '280px' }}>
              {getConfirmationTitle(pastBooking, obeeBooking.data.status)}
            </Typography>

            <Typography centered style={{ maxWidth: '400px' }}>
              {getConfirmationContent(pastBooking, obeeBooking.data.status)}
            </Typography>
          </Spacer>

          {getConfirmationCard()}

          {/* todo: Hide feature for now till apis are done */}
          {/* {(isBooked || isConfirmed) &&
            (hasReviewed ? (
              <Typography variant='h4'>Thank you for your feedback!</Typography>
            ) : (
              <Button
                onClick={() => {
                  trackEvent('Booking', 'Viewed review experience modal');
                  setOpenReviewExperienceModal(true);
                }}
                size='large'
                color='primary'
              >
                Review experience
              </Button>
            ))} */}

          {(pastBooking || isCancelled || isCancelledWaitlist || isFinished) && (
            <Spacer direction='vertical' gap='m' centered>
              <Typography variant='h3'>Need to make a new booking?</Typography>
              <Button
                onClick={() => {
                  trackEvent('Booking', 'Adding New Booking');
                  clearBooking();
                }}
                size='large'
                color='primary'
              >
                Start new booking
              </Button>
            </Spacer>
          )}
        </Spacer>
      </WidgetLayout>

      {restaurant.data?.displayHelpfulInfo && (
        <WidgetLayout>
          <Spacer direction='vertical' gap='l'>
            <Typography variant='h3'>Helpful information</Typography>

            <Spacer direction='vertical' gap='m'>
              <Information
                Icon={<IconDisabledAccess />}
                heading='Disabled access'
                description={disabledAccessMessage?.[restaurant.data?.disabledOption]}
                show={restaurant.data?.hasDisabledAccess && restaurant.data?.disabledOption}
              />

              <Information
                Icon={<IconDressCode />}
                heading='Dress code'
                description={dressCodeMessage?.[restaurant.data?.dressCodeOption]}
                show={restaurant.data?.hasDressCode && restaurant.data?.dressCodeOption}
              />

              <Information
                Icon={<IconChildFriendlyVenue />}
                heading='Child friendly venue'
                description={childrensMenuMessage?.[restaurant.data?.kidsMenuOption]}
                show={restaurant.data?.hasKidsMenu && restaurant.data?.kidsMenuOption}
              />

              <Information
                Icon={<IconChildrensPlayArea />}
                heading="Children's play area"
                description={playAreaMessage?.[restaurant.data?.playAreaOption]}
                show={restaurant.data?.hasPlayArea && restaurant.data?.playAreaOption}
              />

              <Information
                Icon={<IconBYO />}
                heading='BYO'
                description={byoMessage(restaurant.data?.byoOption)}
                show={restaurant.data?.hasBYO && restaurant.data?.byoOption}
              />

              <Information
                Icon={<IconCorkage />}
                heading='Corkage'
                description={corkageMessage(
                  restaurant.data?.corkageOption,
                  restaurant.data?.corkageFee,
                  restaurant.data?.corkagePer,
                )}
                show={restaurant.data?.hasCorkage && restaurant.data?.corkageOption}
              />

              <Information
                Icon={<IconCake />}
                heading='Cake'
                description={cakesMessage?.[restaurant.data?.cakesOption]}
                show={restaurant.data?.hasCakes && restaurant.data?.cakesOption}
              />
            </Spacer>
          </Spacer>
        </WidgetLayout>
      )}

      {address && (isWaitlist || isBooked || isConfirmed) && (
        <WidgetLayout>
          <Spacer direction='vertical' gap={isMobile ? 'm' : 'l'}>
            <Spacer style={{ justifyContent: 'space-between' }} wrap>
              <Typography variant='h3'>How to get there</Typography>
              <Button
                href={`http://maps.google.com?q=${address}`}
                target='_blank'
                variant='text'
                style={{ fontWeight: theme.fontWeights.regular }}
              >
                <Spacer>
                  <IconMarker />

                  <Typography>{address}</Typography>
                </Spacer>
              </Button>
            </Spacer>
            {coordinates && <Map coordinates={coordinates} />}
          </Spacer>
        </WidgetLayout>
      )}

      {allowedStatusesForCancel.includes(obeeBooking.data.status) && !pastBooking && (
        <CancelBookingModal
          open={openCancelBookingModal}
          close={() => setOpenCancelBookingModal(false)}
        />
      )}

      {/* TODO: backend required */}
      {/* <ReviewExperienceModal
        open={openReviewExperienceModal}
        close={() => setOpenReviewExperienceModal(false)}
      /> */}
    </PageLayout>
  );
};

Confirm.defaultProps = {
  confirmCancel: false,
};

Confirm.propTypes = {
  batchUpdateBooking: PropTypes.func.isRequired,
  booking: bookingPropTypes.isRequired,
  clearBooking: PropTypes.func.isRequired,
  confirmCancel: PropTypes.bool,
  editBooking: PropTypes.func.isRequired,
  editWaitlist: PropTypes.func.isRequired,
  obeeBooking: bookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
  obeeBooking: state.obeeBooking,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      batchUpdateBooking: batchUpdateBookingAction,
      clearBooking: clearBookingAction,
      editBooking: editBookingAction,
      editWaitlist: editWaitlistAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
