import { isObee } from '../../utils/constants';
import { graphQLQuery } from '../api/ApiClient';
import { obeePaymentSettingsByCriteria } from '../graphql/queries';
import { makeApiAction } from './shared/helpers';

export const fetchPaymentSettingsAction = (data) => (dispatch) => {
  // Eatclub doesn't need to fetch payment settings
  if (!isObee) {
    return;
  }

  const request = (variables) => graphQLQuery(obeePaymentSettingsByCriteria, variables);

  makeApiAction(
    'FETCH_PAYMENT_SETTINGS',
    request,
    data,
    'obeePaymentSettingsByCriteria',
    'An error occurred while retrieving the payment settings.',
    dispatch,
    null,
    null,
    false,
    true,
  );
};
