import * as type from './types';

export const addMessageAction = (id, message, severity, context, model, arg) => (dispatch) => {
  dispatch({
    type: type.ADD_MESSAGE,
    payload: {
      id,
      message,
      severity,
      context,
      model,
      arg,
    },
  });
};

export const removeMessageAction = (id) => (dispatch) => {
  dispatch({
    type: type.REMOVE_MESSAGE,
    payload: id,
  });
};
