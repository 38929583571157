import moment from 'moment';
import PropTypes from 'prop-types';
import { daysOfWeekLongMap } from '../../utils/constants';
import { getMinMaxInArrayOfObjects, range } from '../../utils/helpers';
import { getSessionsForDate, obeeSessionPropTypes } from './obeeSessions';

export const obeeAreaPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  duration: PropTypes.number,
  capacity: PropTypes.number,
  priority: PropTypes.number,
  minGroup: PropTypes.number,
  maxGroup: PropTypes.number,
  cutoffMode: PropTypes.string,
  cutoffValue: PropTypes.string,
  sessions: PropTypes.arrayOf(obeeSessionPropTypes),
});

export const obeeAreasPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(obeeAreaPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

/**
 *
 * @param {{}[]} areas
 * @param {string} areaId
 * @returns {{ minGroup: number; maxGroup: number }}
 */
export const getMinMaxGroupSize = (areas, areaId) => {
  if (areaId === -1) {
    // All areas
    const { min, max } = getMinMaxInArrayOfObjects(areas, 'minGroup', 'maxGroup');

    return { minGroup: min, maxGroup: max };
  }

  const { minGroup, maxGroup } = areas.find((area) => area.id === areaId) ?? {
    minGroup: 1,
    maxGroup: 8,
  }; // fallback to 1 and 8 if for whatever reason the area doesn't exist (it should)

  return { minGroup, maxGroup };
};

const formatDaySession = (date, sessions) => {
  const { min, max } =
    sessions.length > 0
      ? getMinMaxInArrayOfObjects(sessions, 'startTime', 'endTime')
      : {
          min: 0,
          max: 0,
        }; // fallback for closed state

  return {
    day: date.format('ddd'),
    dayLong: daysOfWeekLongMap[date.format('ddd')],
    endTime: max,
    isOpen: min !== 0 || max !== 0,
    startTime: min,
  };
};

export const getDaySessions = (areas) => {
  const allSessions = areas.flatMap((area) => area.sessions);
  const daysOfWeek = range(0, 6).map((num) => moment().add(num, 'day'));

  return daysOfWeek.map((date) => {
    return formatDaySession(date, getSessionsForDate(allSessions, date.format('YYYY-MM-DD')));
  });
};
