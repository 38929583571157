import { Box, Divider, Popover } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconMagnify } from '../../../../assets/icons/magnify_glass.svg';
import { Button } from '../../../../components/Button';
import { IconButton } from '../../../../components/IconButton';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import { updateBookingAction } from '../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import { trackEvent } from '../../../../utils/analytics';
import { isEmpty, isNil } from '../../../../utils/helpers';
import { useIsMobile, useIsSmallDevice } from '../../../../utils/hooks';
import useStyles from './AreasStyles';
import { ImageModal } from './ImageModal';

const Areas = ({ booking, restaurant, updateBooking }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [viewAllAnchorEl, setViewAllAnchorEl] = useState(null);
  const [areaImage, setAreaImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showAreas, setShowAreas] = useState(false);
  const isMobile = useIsMobile();
  const isSmallDevice = useIsSmallDevice();

  // Avoid thinking there are area photos if there are lingering photos for deleted areas
  const hasAreaPhotos = restaurant.data.areas.some(
    (area) => !isEmpty(restaurant.data.areaPhotos?.[area?.id]?.imageLink),
  );

  const getAllAreas = () => {
    if (restaurant.data.premium) {
      return [{ id: -1, name: 'Search all areas' }, ...restaurant.data.areas].map((area) => {
        if (area.id === -1) {
          // is All areas
          return (
            <Spacer direction='vertical' style={{ marginBottom: '8px' }}>
              <Button
                variant='text'
                onClick={() => {
                  trackEvent('Booking', 'Updating Area', 'Dropdown');
                  updateBooking(bookingKeys.preferredAreaId, area.id);
                  setViewAllAnchorEl(null);
                  setShowAreas(false);
                }}
              >
                <Typography style={{ textAlign: 'left', width: '100%' }}>{area.name}</Typography>
              </Button>
              <Divider color={theme.colors.gainsboro} />
            </Spacer>
          );
        }

        const areaPhoto = restaurant.data.areaPhotos[area.id];
        const imageLink = areaPhoto?.imageLink;
        const description = areaPhoto?.description;

        return (
          <Button
            variant='text'
            key={`area-${area.id}`}
            onClick={() => {
              trackEvent('Booking', 'Updating Area', 'Dropdown');
              updateBooking(bookingKeys.preferredAreaId, area.id);
              setViewAllAnchorEl(null);
              setShowAreas(false);
            }}
            style={{ minHeight: hasAreaPhotos ? '165px' : '84px' }}
          >
            <Spacer
              direction={isSmallDevice ? 'vertical' : 'horizontal'}
              gap='l'
              style={{ width: '100%', rowGap: '8px', height: '100%' }}
            >
              {hasAreaPhotos && (
                <Box className={classes.image} style={{ width: isSmallDevice && '100%' }}>
                  {imageLink ? (
                    <img src={imageLink} className={classes.thumbnail} alt={area.name} />
                  ) : (
                    <Typography variant='caption'>No image</Typography>
                  )}

                  {imageLink && (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        trackEvent('Area', 'Viewed Area Image');
                        unstable_batchedUpdates(() => {
                          setAreaImage({ areaName: area.name, imageLink });
                          setShowImage(true);
                        });
                      }}
                      className={classes.icon}
                    >
                      <IconMagnify />
                    </IconButton>
                  )}
                </Box>
              )}
              <Spacer
                direction='vertical'
                style={{
                  alignItems: 'flex-start',
                  maxHeight: hasAreaPhotos ? '136px' : '72px',
                  overflow: 'hidden',
                }}
                gap={0}
              >
                <Typography
                  variant='h3'
                  style={{ fontWeight: theme.fontWeights.medium, textAlign: 'left' }}
                >
                  {area.name}
                </Typography>

                {description && (
                  <Typography
                    style={{
                      color: theme.colors.secondaryTextColour,
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {description}
                  </Typography>
                )}

                <Typography variant='small' style={{ color: theme.colors.secondaryTextColour }}>
                  Select
                </Typography>
              </Spacer>
            </Spacer>
          </Button>
        );
      });
    }

    return [{ id: -1, name: 'All areas' }, ...restaurant.data.areas].map((area) => (
      <Button
        variant='text'
        key={`area-${area.id}`}
        onClick={() => {
          trackEvent('Booking', 'Updating Area', 'Dropdown');
          updateBooking(bookingKeys.preferredAreaId, area.id);
          setViewAllAnchorEl(null);
          setShowAreas(false);
        }}
        style={{ fontWeight: theme.fontWeights.regular }}
      >
        <Typography style={{ marginRight: 'auto' }}>{area.name}</Typography>
      </Button>
    ));
  };

  return (
    <>
      <Button
        variant='text'
        active={showAreas || !isNil(viewAllAnchorEl)}
        style={{ fontWeight: theme.fontWeights.regular, maxWidth: '100%', width: 'fit-content' }}
        onClick={(e) => {
          trackEvent('Area', 'Viewed All Areas');
          setViewAllAnchorEl(e.currentTarget);
        }}
        showArrowDown
      >
        <Typography
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {booking.preferredAreaId !== -1
            ? restaurant.data.areas.find((area) => area.id === booking.preferredAreaId)?.name
            : 'All areas'}
        </Typography>
      </Button>

      <Popover
        anchorEl={viewAllAnchorEl}
        isOpen={!!viewAllAnchorEl}
        onClose={() => setViewAllAnchorEl(null)}
        showArrow
        innerStyle={{
          backgroundColor: theme.colors.availabilityBackgroundBoxBgColour,
          borderRadius: '8px',
          padding: 0,
          position: 'relative',
          marginTop: '16px',
          // todo: fix this mess below
          // eslint-disable-next-line no-nested-ternary
          maxWidth: restaurant.data.premium ? (isMobile ? '' : '540px') : 'fit-content',
          width: '100%',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Spacer
          gap={0}
          direction='vertical'
          style={{
            fontFamily: theme.baseTypography.availabilityFontFamily,
            padding: '8px',
            maxHeight: '500px',
          }}
        >
          {getAllAreas()}
        </Spacer>
      </Popover>

      <ImageModal
        open={showImage}
        onClose={() => setShowImage(false)}
        onAfterClose={() => setAreaImage(null)}
        areaImage={areaImage}
      />
    </>
  );
};

Areas.propTypes = {
  booking: bookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Areas);
