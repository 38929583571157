import debounce from 'lodash.debounce';
import { filterArrayUnique } from '../../utils/helpers';
import { graphQLQuery } from '../api/ApiClient';
import { availabilitySuggestions, availabilitySuggestionsForAllAreas } from '../graphql/queries';
import { makeApiAction } from './shared/helpers';

const debouncedAvailabilitySuggestions = debounce(
  (dispatch, query, variables, model) => {
    const request = (apiVariables) => graphQLQuery(query, apiVariables);

    makeApiAction(
      'FETCH_OBEE_AVAILABILITY_SUGGESTIONS',
      request,
      variables,
      model,
      'an error occurred while retrieving the available times.',
      dispatch,
      null,
      null,
      true,
      true,
    );
  },
  500,
  { leading: true }, // Invoke when called, debouncing subsequent calls.
);

/**
 * Availability suggestion search for a specific area
 * @param {int} areaId
 * @param {string} date
 * @param {int[]} times The list of session start times
 * @param {int} size The amount of guests
 * @returns
 */
export const fetchObeeAvailabilitySuggestionsAction = (areaId, date, times, size) => (dispatch) => {
  debouncedAvailabilitySuggestions(
    dispatch,
    availabilitySuggestions,
    {
      areaId,
      date,
      times,
      size,
      length: 100,
      public: true, // public is for online booking / general availability
    },
    'availabilitySuggestions',
  );
};

/**
 * Availability suggestion search for all areas
 * @param {string} date
 * @param {int[]} times The list of session start times
 * @param {int} size The amount of guests
 * @returns
 */
export const fetchObeeAvailabilitySuggestionsForAllAreasAction =
  (date, times, size) => (dispatch) => {
    debouncedAvailabilitySuggestions(
      dispatch,
      availabilitySuggestionsForAllAreas,
      {
        date,
        times: filterArrayUnique(times),
        size,
        length: 100,
        public: true, // public is for online booking / general availability
      },
      'availabilitySuggestionsForAllAreas',
    );
  };
