import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ErrorSVG } from '../../assets/icons/error.svg';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { getAlertContextStyles } from './AlertHelpers';
import useStyles from './AlertStyles';

const Alert = ({ context, children, heading }) => {
  const theme = useTheme();
  const alertContextStyles = getAlertContextStyles(context, theme);
  const classes = useStyles({ alertContextStyles });

  return (
    <Box className={classes.container}>
      <Spacer direction='vertical' gap='m'>
        <Spacer gap='m'>
          <ErrorSVG color={alertContextStyles.iconColor} style={{ flexShrink: 0 }} />
          <Typography className={classes.heading}>{heading}</Typography>
        </Spacer>

        {children && (
          <>
            <Divider color={alertContextStyles.borderColor} />
            <Box style={{ paddingLeft: '40px', paddingRight: '20px', lineHeight: 1.5 }}>
              {children}
            </Box>
          </>
        )}
      </Spacer>
    </Box>
  );
};

Alert.defaultProps = {
  context: 'error',
  children: null,
};

Alert.propTypes = {
  context: PropTypes.oneOf(['error', 'warning']),
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
};

export default Alert;
