import * as type from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ID_LOADING: {
      return [...state, action.payload];
    }

    case type.REMOVE_ID_LOADING: {
      return [...state.filter((loading) => loading.id !== action.payload)];
    }

    default:
      return state;
  }
};
