import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React, { useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '../../../../components/Typography';
import { paymentSettingPropTypes } from '../../../../data/models/PaymentSettings';
import { devLog } from '../../../../utils/devLog';
import { usePinPayments } from '../../../../utils/pinPayments';
import { PinField } from './PinField';
import useStyles from './PinFieldStyles';

/**
 * Render the credit card fields for pin payments.
 * Stolen from bookings library
 * @param paymentRef
 * @param paymentSettings
 * @returns {JSX.Element}
 * @constructor
 */
const PinPaymentsFields = ({ paymentRef, paymentSettings }) => {
  const classes = useStyles();
  const rollbar = useRollbar();

  const [errorMessages, setErrorMessages] = useState([]);
  const [cardNameError, setCardNameError] = useState(null);
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardCVVError, setCardCVVError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);

  // Inject pin payments script
  const pinPaymentFields = usePinPayments(paymentSettings.data?.pinMode);

  const handlePaymentError = (errorResponse) => {
    if (errorResponse.error === 'unauthenticated') {
      rollbar.error('Cannot process payment. API key is missing or not valid.', errorResponse);
      setErrorMessages(['Cannot process payment. API key is missing or not valid.']);
    } else {
      const messages = [];

      let numberError = null;
      let nameError = null;
      let cvvError = null;
      let expiryError = null;

      errorResponse?.messages?.forEach((message) => {
        if (message.code.includes('number')) {
          numberError = message.message;
        } else if (message.code.includes('name')) {
          nameError = message.message;
        } else if (message.code.includes('cvc')) {
          cvvError = message.message;
        } else if (message.code.includes('expiry')) {
          expiryError = message.message;
        } else {
          messages.push(message.message);
        }
      });

      setCardNumberError(numberError);
      setCardNameError(nameError);
      setCardCVVError(cvvError);
      setCardExpiryError(expiryError);

      setErrorMessages(messages);
    }
  };

  const handlePaymentClicked = async (pinFields) => {
    // console.log('handling card', pinFields);
    if (!pinFields) {
      handlePaymentError({
        messages: [{ code: '', message: 'Credit card details are not valid' }],
      });
      return {
        valid: false,
      };
    }

    setErrorMessages([]);
    setCardCVVError(null);
    setCardNameError(null);
    setCardNumberError(null);
    setCardExpiryError(null);

    // Need to convert pin payments call into a promise so that we can wait for the data
    const pinResponse = await new Promise((resolve) => {
      pinFields?.tokenize(
        { publishable_api_key: paymentSettings?.data?.publishableKey },
        (error, response) => {
          let valid = true;
          devLog('debug', 'pin payments response', error, response);

          if (error) {
            handlePaymentError(error);
            valid = false;

            rollbar.error(error);
          }

          resolve({
            valid,
            response,
            token: response?.token, // This was the result of the intent in stripe
            cardNumber: response?.display_number, // card number
          });
        },
      );
    });

    return pinResponse;
  };

  useImperativeHandle(
    paymentRef,
    () => ({
      submit: async () => handlePaymentClicked(pinPaymentFields),
    }),
    [handlePaymentClicked, pinPaymentFields],
  );

  return (
    <Spacer direction='vertical' gap={8}>
      <PinField
        id='name'
        label='Card name'
        error={cardNameError}
        style={{ label: { width: '80px' } }}
      />
      <PinField
        id='number'
        label='Card no'
        error={cardNumberError}
        style={{ label: { width: '80px' }, valueField: { minWidth: '200px' } }}
      />
      <PinField
        id='cvc'
        label='CVC'
        error={cardCVVError}
        style={{ label: { width: '80px' }, valueField: { maxWidth: '100px' } }}
      />
      <PinField
        id='expiry'
        label='Expiry'
        error={cardExpiryError}
        style={{ label: { width: '80px' }, valueField: { maxWidth: '100px' } }}
      />

      <Typography variant='caption'>
        {errorMessages.map((message) => (
          <Box key={message} className={classes.errorText}>
            {message}
          </Box>
        ))}
      </Typography>
    </Spacer>
  );
};

PinPaymentsFields.defaultProps = {
  paymentRef: null,
};

PinPaymentsFields.propTypes = {
  paymentRef: PropTypes.shape({}),
  paymentSettings: paymentSettingPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  paymentSettings: state.paymentSettings,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PinPaymentsFields);
