export const getAlertContextStyles = (context, theme) => {
  switch (context) {
    case 'warning': {
      return {
        background: theme.colors.chardon,
        borderColor: theme.colors.champagne,
        iconColor: theme.colors.fireBush,
        textColor: theme.colors.christine,
      };
    }
    case 'error':
    default: {
      return {
        background: theme.colors.chardon,
        borderColor: theme.colors.coralCandy,
        iconColor: theme.colors.valencia,
        textColor: theme.colors.valencia,
      };
    }
  }
};
