import { useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { isObee } from './constants';

export const usePinPayments = (pinMode) => {
  const theme = useTheme();
  // const pinPaymentFields = useRef();
  const [pinPaymentFields, setPinPaymentFields] = useState(null);

  const onLoad = () => {
    // console.log('finished loading pin payments');
    setPinPaymentFields(
      window.HostedFields.create({
        // Set this to true when testing. Set it to false in production.
        sandbox: pinMode === 'test',
        // Optional: set autocomplete to true to allow web browsers to suggest saved credit cards.
        autocomplete: true,

        // These are the CSS styles for the input elements inside the iframes. Inside each iframe is a single input with its id set to name, number, cvc or expiry.
        // When the input has a valid value, it will have the 'hosted-fields-valid' class. When the input has an invalid value, it will have the 'hosted-fields-invalid' class.
        styles: {
          'font-family': "'Inter', 'Open Sans', 'Helvetica', 'sans-serif' !important",
          input: {
            'font-size': '15px',
            height: '36px',
            // color: '#3a3a3a',
            color: theme.colors.mainTextColour,
            'background-color': theme.colors.inputFieldsBackground,
            padding: '0px 12px',
          },
          'input:focus': {
            // 'background-color': 'transparent !important',
            outline: `1px solid ${theme.colors.activeOutlineColour}`,
          },
          '.hosted-fields-invalid:not(:focus)': {
            'font-family': "'Inter', 'Open Sans', 'Helvetica', 'sans-serif' !important",
            color: 'red',
          },
          '.pin-form-field': {
            height: '36px',
            border: '1px solid black',
          },
        },

        // The fields object defines the fields to be created. All four fields are required (name, number, cvc, expiry).
        // Each field requires a selector for the element in which to create an iframe. Optionally, you can define placeholder text and a label selector (the CSS selector of the label element for that particular field).
        fields: {
          name: {
            selector: '#name',
            placeholder: 'Empty',
          },
          number: {
            selector: '#number',
            // placeholder: '4200 0000 0000 0000',
            placeholder: 'Empty',
          },
          cvc: {
            selector: '#cvc',
            // placeholder: '123',
            placeholder: '000',
          },
          expiry: {
            selector: '#expiry',
            placeholder: 'MM/YYYY',
            // placeholder: 'Empty',
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (pinPaymentFields || !isObee) {
      return;
    }

    const injectScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', (e) => reject(e.error));
        document.head.appendChild(script);
      });
    };

    injectScript(process.env.REACT_APP_PIN_PAYMENTS_URL).then(() => {
      onLoad();
    });
  }, []);

  return pinPaymentFields;
};
