import * as type from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_MESSAGE: {
      const { id, message, severity, context, model, arg } = action.payload;

      return [...state, { id, message, severity, context, model, arg }];
    }

    case type.REMOVE_MESSAGE: {
      return state.filter((foundMessage) => foundMessage.id !== action.payload);
    }

    default:
      return state;
  }
};
