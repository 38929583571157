import { isJson } from './isJson';
import { safeParseWithDefault } from './stringHelpers';

export const ERROR_MESSAGES = {
  requiredField: 'This field is required.',
  validEmail: 'A valid email is required.',
  validMobile: 'A valid mobile number is required.',
};

/**
 * returns `true` if `REACT_APP_BOOKINGS_BRANDING=obee`
 */
export const isObee = process.env.REACT_APP_BOOKINGS_BRANDING === 'obee';

export const companyName = isObee ? 'Obee' : 'EatClub';

export const daysOfWeekShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const daysOfWeekLongMap = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};
export const momentDaysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const disabledAccessMessage = {
  yes: 'Access Ramps & disabled facilities',
  no: 'No access ramps & disabled facilities',
  contact_us: 'Please contact us for more details',
};

export const dressCodeMessage = {
  no: 'No dress code',
  casual: 'No thongs or singlets permitted',
  smart_casual: 'Smart casual attire required for entry',
  formal: 'Formal attire required for entry',
};

export const childrensMenuMessage = {
  no: 'No kids menu available',
  yes: 'Kids menu available',
  contact_us: 'Please contact us for more details',
};

export const playAreaMessage = {
  no: 'No children’s play area onsite',
  yes: 'There is a children’s play area onsite',
  contact_us: 'Please contact us for more details',
};

export const byoMessage = (byoOption) => {
  const optionAsString = isJson(byoOption)
    ? Object.values(safeParseWithDefault(byoOption, {}))?.sort()?.join('_')
    : byoOption;

  return {
    beer: 'We have a BYO licence for beer only',
    wine: 'We have a BYO licence for wine only',
    beer_wine: 'We have a BYO licence for beer and wine',
    bar_service: 'No BYO, although we have a fully stocked bar!',
    unlicensed: 'Alcohol is not permitted onsite',
  }?.[optionAsString];
};

export const corkageMessage = (option, fee, perBottle) =>
  ({
    no: 'We don’t charge any corkage fees',
    yes: `Yes, we have a $${fee} corkage fee per ${perBottle ? 'bottle' : 'booking'}`,
    contact_us: 'Please contact us for more details',
  }?.[option]);

export const cakesMessage = {
  no: 'Unfortunately we can’t store cakes',
  yes: 'Yes, we can store cakes on arrival',
  contact_us: 'Please contact us for more details',
};
