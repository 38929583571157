import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    WebkitTextFillColor: `${theme.colors.mainTextColour}`,
    WebkitBoxShadow: `0 0 0 30px ${
      theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white'
    } inset`,
    '&:hover': {
      WebkitTextFillColor: `${theme.colors.mainTextColour}`,
      WebkitBoxShadow: `0 0 0 30px ${
        theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white'
      } inset !important`,
    },
    '&:focus': {
      WebkitTextFillColor: `${theme.colors.mainTextColour}`,
      WebkitBoxShadow: `0 0 0 30px ${
        theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white'
      } inset  !important`,
    },
    '&:active': {
      WebkitTextFillColor: `${theme.colors.mainTextColour}`,
      WebkitBoxShadow: `0 0 0 30px ${
        theme.colors.darkMode ? theme.colors.availabilityBackgroundBoxBgColour : 'white'
      } inset`,
    },
  },
}));

export default useStyles;
