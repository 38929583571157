import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  multiVenueImageContainer: {
    borderRadius: '6px',
    height: '132px',
    overflow: 'hidden',
    width: '132px',
  },
  multiVenueImage: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
}));

export default useStyles;
