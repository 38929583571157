import PropTypes from 'prop-types';
import { isNil } from '../../utils/helpers';
import { obeeAvailabilitySuggestionPropTypes } from './obeeAvailabilitySuggestions';

export const obeeMultiVenueAvailabilitySuggestionsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(obeeAvailabilitySuggestionPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const getData = (data) => {
  const availableSuggestions = data.filter(
    (availabilitySuggestion) => !isNil(availabilitySuggestion.table), // ignore non-bookable times for multi venues, don't allow waitlist times
  );

  return availableSuggestions;
};
