import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fullPill: {
    letterSpacing: '1.2px',
    position: 'absolute',
    bottom: '-8px',
    background: theme.colors.peach,
    padding: '0 4px',
    borderRadius: '100px',
    zIndex: 1,
    height: '20px',
    fontWeight: theme.fontWeights.medium,
    color: theme.colors.charcoal,
    pointerEvents: 'none',
  },
}));

export default useStyles;
