import { trackFacebookPageView } from '../../utils/analytics';
import FacebookTracking from '../../utils/facebookAnalytics';
import { graphQLQuery } from '../api/ApiClient';
import { obeeIntegrations } from '../graphql/queries';
import { makeApiAction } from './shared/helpers';
import * as type from './types';

/**
 * Fetch the restaurant's integrations
 * TODO backend should only return the facebook pixel rather than all of the integrations
 */
export const fetchObeeIntegrationsAction = () => async (dispatch) => {
  const request = (variables) => graphQLQuery(obeeIntegrations, variables);
  makeApiAction(
    'FETCH_OBEE_INTEGRATIONS',
    request,
    {},
    'obeeIntegrations',
    null,
    dispatch,
    null,
    null,
    false,
    true,
  );
};

/**
 * Initialize the facebook integration. Track a page view when initialized
 * Pixel is added to redux state so events can use it easily
 * @param pixelId
 * @returns {(function(*): Promise<void>)|*}
 */
export const initializeFacebookAction = (pixelId) => async (dispatch) => {
  FacebookTracking.initialize(pixelId);
  trackFacebookPageView();

  dispatch({
    type: type.INITIALIZE_FACEBOOK_PIXEL,
    payload: pixelId,
  });
};
