import { Popover } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconMinus } from '../../../../assets/icons/minus.svg';
import { ReactComponent as IconPlus } from '../../../../assets/icons/plus.svg';
import { Button } from '../../../../components/Button';
import { IconButton } from '../../../../components/IconButton';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import { updateBookingAction } from '../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import { getMinMaxGroupSize } from '../../../../data/models/obeeAreas';
import { trackEvent } from '../../../../utils/analytics';
import { isNil, pluralise, range } from '../../../../utils/helpers';
import useStyles from './GuestsStyles';

const Guests = ({ booking, restaurant, updateBooking }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [viewAllAnchorEl, setViewAllAnchorEl] = useState(null);

  const { minGroup, maxGroup } = getMinMaxGroupSize(restaurant.data.areas, booking.areaId);

  // we want the user to be able to select outside of the min max range we we can prompt them with a custom message alert
  const minGroupCap = Math.max(1, minGroup - 1);
  const maxGroupCap = maxGroup + 1;

  const guestRange = range(minGroupCap, maxGroupCap);
  const isMaxGuests = guestRange.indexOf(booking.guests) === guestRange.length - 1;

  return (
    <Spacer centered gap={4}>
      <IconButton
        disabled={booking.guests <= minGroupCap}
        variant='outlined'
        onClick={() => {
          trackEvent('Booking', 'Updating Guests', 'Decrement');
          updateBooking(bookingKeys.guests, Math.max(booking.guests - 1, minGroupCap));
        }}
      >
        <IconMinus />
      </IconButton>

      <Button
        variant='text'
        active={!isNil(viewAllAnchorEl)}
        style={{ fontWeight: theme.fontWeights.regular }}
        onClick={(e) => {
          trackEvent('Guests', 'Viewed All guest sizes');
          setViewAllAnchorEl(e.currentTarget);
        }}
      >
        <Typography className={classes.guests} centered>
          {`${booking.guests}${isMaxGuests ? '+' : ''} ${pluralise('guest', booking.guests)}`}
        </Typography>
      </Button>

      <IconButton
        disabled={booking.guests >= maxGroupCap}
        variant='outlined'
        onClick={() => {
          trackEvent('Booking', 'Updating Guests', 'Increment');
          updateBooking(bookingKeys.guests, Math.min(booking.guests + 1, maxGroupCap));
        }}
      >
        <IconPlus />
      </IconButton>

      <Popover
        anchorEl={viewAllAnchorEl}
        isOpen={!!viewAllAnchorEl}
        onClose={() => setViewAllAnchorEl(null)}
        showArrow
        innerStyle={{
          borderRadius: '8px',
          padding: '0',
          position: 'relative',
          marginTop: '16px',
          // maxWidth: restaurant.data.premium ? !breakpointXsDown && '540px' : 'fit-content',
          width: 'fit-content',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Spacer
          gap={0}
          direction='vertical'
          style={{
            fontFamily: theme.baseTypography.availabilityFontFamily,
            padding: '8px',
            maxHeight: '360px',
          }}
        >
          {guestRange.map((size, index) => (
            <Button
              variant='text'
              key={`guest-${size}`}
              onClick={() => {
                trackEvent('Booking', 'Updating Guests', 'Increment');
                updateBooking(bookingKeys.guests, size);
                setViewAllAnchorEl(null);
              }}
              style={{ fontWeight: theme.fontWeights.regular, color: theme.colors.charcoal }}
            >
              <Typography>
                {size}
                {index === guestRange.length - 1 ? '+' : ''}
              </Typography>
            </Button>
          ))}
        </Spacer>
      </Popover>
    </Spacer>
  );
};

Guests.propTypes = {
  booking: bookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Guests);
