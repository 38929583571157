import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { Collapse, useTheme } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconCalPrev } from '../../../../../assets/icons/caret_left.svg';
import { ReactComponent as IconCalNext } from '../../../../../assets/icons/caret_right.svg';
import { Button } from '../../../../../components/Button';
import { ConditionalWrapper } from '../../../../../components/ConditionalWrapper';
import { IconButton } from '../../../../../components/IconButton';
import { Spacer } from '../../../../../components/Spacer';
import { Typography } from '../../../../../components/Typography';
import { updateBookingAction } from '../../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../../data/models/Booking';
import {
  isRestaurantClosedForDate,
  restaurantPropTypes,
} from '../../../../../data/models/Restaurant';
import { getHexColourWithOpacity } from '../../../../../theme';
import { trackEvent } from '../../../../../utils/analytics';
import { daysOfWeekShort } from '../../../../../utils/constants';
import {
  formatDateToMoment,
  formatMomentToDate,
  getContrastTextColor,
  isNil,
} from '../../../../../utils/helpers';
import { useIsMobile, useIsSmallDevice } from '../../../../../utils/hooks';
import useStyles from './CalendarStyles';

const Calendar = ({ booking, defaultView, onChange, restaurant, updateBooking, alwaysOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(
    // todo: make ugly code pretty
    // eslint-disable-next-line no-nested-ternary
    alwaysOpen ? true : defaultView ? isNil(booking.date) : true,
  );
  const startOfToday = moment().startOf('day');
  const [viewedDate, setViewedDate] = useState(
    booking.date ? moment(booking.date).startOf('day') : startOfToday,
  );
  const isMobile = useIsMobile();
  const isSmallDevice = useIsSmallDevice();

  useEffect(() => {
    if (!isExpanded) {
      setViewedDate(booking.date ? moment(booking.date).startOf('day') : startOfToday);
    }
  }, [isExpanded]);

  const onDateChange = (date) => {
    trackEvent('Booking', 'Updating Date', 'Booking Calendar');
    updateBooking(bookingKeys.date, formatMomentToDate(moment(date)));

    setViewedDate(moment(date));

    if (!alwaysOpen && defaultView) {
      setIsExpanded(false);
    }

    onChange();
  };

  const sessions = restaurant.data.areas.flatMap((area) => area.sessions);

  const getTileBgColor = (isDisabled, isSelected) => {
    if (isDisabled) {
      return theme.colors.darkMode ? '#ffffff11' : theme.colors.whiteSmoke;
    }
    if (isSelected) {
      return getHexColourWithOpacity(
        restaurant.data.availabilityFieldsActiveStateColour,
        restaurant.data.availabilityFieldsColourOpacity,
      );
    }
    return '';
  };

  const getHeading = () => {
    if (isExpanded) {
      return viewedDate.format(isSmallDevice ? 'MMM YYYY' : 'MMMM YYYY');
    }

    const selectedDate = formatDateToMoment(booking.date);

    if (selectedDate.isSame(startOfToday, 'd')) {
      return 'Today';
    }

    if (selectedDate.isSame(moment(startOfToday).add(1, 'd'), 'd')) {
      return 'Tomorrow';
    }

    return selectedDate.format(isSmallDevice ? 'ddd Do MMM' : 'dddd Do MMM YYYY');
  };

  const availableDates = Array.from({ length: restaurant.data.daysInAdvance }, (_, i) => {
    return moment(startOfToday).add(i, 'd');
  }).filter(
    (date) =>
      !isRestaurantClosedForDate(date, sessions, restaurant.data.closures, restaurant.data.areas),
  );

  const index = availableDates.findIndex((availableDate) =>
    moment(booking.date).isSame(availableDate, 'd'),
  );

  const prevAvailableDate = index > 0 ? availableDates[index - 1] : null;
  const nextAvailableDate =
    index > -1 && index < availableDates.length - 1 ? availableDates[index + 1] : null;

  const bufferLength = (7 + moment(viewedDate).startOf('M').day() - 1) % 7;

  return (
    <Box>
      <Box>
        <Spacer
          style={{
            border: defaultView ? `1px solid ${theme.colors.outlineColour}` : '',
            borderRadius: '6px',
            height: '32px',
            padding: '8px 12px',
            width: 'calc(100% - 24px)',
          }}
        >
          <IconButton
            variant='outlined'
            style={{ height: '28px', width: '28px' }}
            disabled={!isExpanded && isNil(prevAvailableDate)}
            onClick={() => {
              if (isExpanded) {
                setViewedDate(moment(viewedDate).subtract(1, 'M'));
              } else {
                onDateChange(prevAvailableDate);
              }
            }}
          >
            <IconCalPrev />
          </IconButton>

          <Box style={{ margin: 'auto' }}>
            <ConditionalWrapper
              condition={!isNil(booking.date) && defaultView && !alwaysOpen}
              wrapper={(children) => (
                <Button
                  variant='text'
                  onClick={() => setIsExpanded(!isExpanded)}
                  active={isExpanded}
                  showActiveBorder={false}
                  showArrowDown
                >
                  {children}
                </Button>
              )}
            >
              <Typography variant='h3' style={{ fontWeight: theme.fontWeights.medium }}>
                {getHeading()}
              </Typography>
            </ConditionalWrapper>
          </Box>

          <IconButton
            variant='outlined'
            style={{ height: '28px', width: '28px' }}
            disabled={!isExpanded && isNil(nextAvailableDate)}
            onClick={() => {
              if (isExpanded) {
                setViewedDate(moment(viewedDate).add(1, 'M'));
              } else {
                onDateChange(nextAvailableDate);
              }
            }}
          >
            <IconCalNext />
          </IconButton>
        </Spacer>

        {!defaultView && <Divider color={theme.colors.outlineColour} />}
      </Box>

      <Collapse in={isExpanded}>
        <Spacer
          direction='vertical'
          gap='m'
          style={{ padding: defaultView && isMobile ? '16px 0' : '16px' }}
        >
          <Box
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(7, ${100 / 7}%)`,
            }}
          >
            {daysOfWeekShort.map((day) => (
              <Typography key={day} style={{ fontWeight: theme.fontWeights.medium }} centered>
                {day}
              </Typography>
            ))}
          </Box>
          <Box>
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(7, 1fr)`,
                gridTemplateRows: `repeat(6, 1fr)`,
                gridGap: '2px',
              }}
            >
              {/* Buffer */}
              {Array.from({ length: bufferLength }).map((_, i) => (
                // eslint-disable-next-line
                <Box key={i} />
              ))}

              {Array.from({ length: moment(viewedDate).daysInMonth() }, (_, i) => {
                const startOfMonth = moment(viewedDate).clone().startOf('month');
                return startOfMonth.clone().add(i, 'd');
              }).map((date) => {
                const isDisabled = isNil(
                  availableDates.find((availableDate) => date.isSame(availableDate, 'd')),
                );

                const isSelected = formatMomentToDate(date) === booking.date;
                const isToday = date.isSame(startOfToday, 'd');
                const tileBgColor = getTileBgColor(isDisabled, isSelected);
                const isFull = false; // todo: get this from api

                return (
                  <Button
                    // note: this is to make sure transitions are not triggered on rerenders
                    key={date.format('YYYY-MM-DD')}
                    variant='text'
                    onClick={() => onDateChange(date)}
                    disabled={isDisabled}
                    style={{
                      background: `${tileBgColor} !important`,
                      // eslint-disable-next-line no-nested-ternary
                      border: isSelected
                        ? '1px solid black'
                        : isFull
                        ? `1px solid ${theme.colors.cloud}`
                        : '1px solid transparent',
                      color:
                        !isSelected && theme.colors.darkMode
                          ? theme.colors.mainTextColour
                          : getContrastTextColor(tileBgColor),
                      // color: getContrastTextColor(tileBgColor),
                      height: isMobile ? '36px' : '56px',
                      minWidth: 'unset',
                      padding: 'unset',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        alignItems: 'center',
                        border:
                          isToday &&
                          `1px solid ${isSelected ? 'currentColor' : theme.colors.outlineColour}`,
                        borderRadius: '100%',
                        display: 'flex',
                        height: isMobile ? '28px' : '32px',
                        justifyContent: 'center',
                        width: isMobile ? '28px' : '32px',
                      }}
                    >
                      <Typography
                        variant='small'
                        style={{ fontWeight: !isSelected && !isToday && theme.fontWeights.regular }}
                      >
                        {date.format('D')}
                      </Typography>

                      {isFull && (
                        <Typography className={classes.fullPill} variant='caption'>
                          FULL
                        </Typography>
                      )}
                    </Box>
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Spacer>
        <Divider color={theme.colors.outlineColour} />
      </Collapse>
    </Box>
  );
};

Calendar.defaultProps = {
  defaultView: false,
  onChange: () => {},
};

Calendar.propTypes = {
  booking: bookingPropTypes.isRequired,
  defaultView: PropTypes.bool,
  onChange: PropTypes.func,
  restaurant: restaurantPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
  alwaysOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
