import moment from 'moment';
import PropTypes from 'prop-types';
import { isObee } from '../../utils/constants';
import { formatIntFromTime, isEmpty } from '../../utils/helpers';

export const obeeSessionPropTypes = PropTypes.shape({
  id: PropTypes.number,
  areaId: PropTypes.number,
  label: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  isPublic: PropTypes.bool,
  day: PropTypes.string,
  date: PropTypes.string,
  sittingTimes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, times: PropTypes.arrayOf(PropTypes.string) }),
  ),
});

/**
 * Get sessions for the specified date
 * @param {*} sessions
 * @param {string|null} date YYYY-MM-DD format
 * @returns
 */
export const getSessionsForDate = (sessions, date) => {
  if (isEmpty(date)) {
    return [];
  }

  // Check if there are any events sessions for the date, 'date' sessions overrides 'day' sessions
  // Event sessions only override for that area, so we still check day sessions for other areas
  const events = sessions.filter((session) => session.date === date);

  if (!isObee && !isEmpty(events)) {
    return events;
  }

  const areasWithEvents = events.map((event) => event?.areaId);

  // Check if there are any day sessions for the date
  const nonEventSessions = sessions
    .filter((session) => !areasWithEvents.includes(session?.areaId))
    .filter((session) => session.day === moment(date).format('ddd').toLowerCase());

  return [...nonEventSessions, ...events].filter(
    (session) => !(session?.startTime === 0 && session?.endTime === 0),
  );
};

export const ALL_SESSIONS_LABEL = 'All'; // defining this to be used else where without human error, and a central space to update it

export const getSessionLabel = (sessionLabelId) => {
  const labelMap = {
    morning: 'Morning',
    lunch: 'Lunch',
    dinner: 'Dinner',
    all_day: 'All Day',
  };

  return labelMap?.[sessionLabelId] || sessionLabelId; // fallback to event session label
};

/**
 * Get sessions for the specified booking
 * @param {*} areas
 * @param {*} booking
 * @returns
 */
export const getSessionsForBooking = (areas, booking) => {
  const now = moment();
  const nowAsInt = formatIntFromTime(now);

  // Get all sessions for all areas, even if editing the booking
  const sessions = areas.flatMap((area) => {
    return area.sessions;
  });

  const sessionsForDate = getSessionsForDate(sessions, booking.date);

  if (booking.date === now.format('YYYY-MM-DD')) {
    return sessionsForDate.filter((session) => session.endTime > nowAsInt);
  }

  return sessionsForDate;
};
