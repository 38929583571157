import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    background: theme.colors.cloud,
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '500px',
    overflow: 'hidden',
  },
}));

export default useStyles;
