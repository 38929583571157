import { Box } from '@eatclub-apps/ec-component-library';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconCalPrev } from '../../../../assets/icons/caret_left.svg';
import { ReactComponent as IconCalNext } from '../../../../assets/icons/caret_right.svg';
import { ConditionalWrapper } from '../../../../components/ConditionalWrapper';
import { IconButton } from '../../../../components/IconButton';
import { Spacer } from '../../../../components/Spacer';
import { Swipe } from '../../../../components/Swipe';
import { bookingPropTypes } from '../../../../data/models/Booking';
import { obeeAvailabilitySuggestionPropTypes } from '../../../../data/models/obeeAvailabilitySuggestions';
import { trackEvent } from '../../../../utils/analytics';
import { makeChunks } from '../../../../utils/helpers';
import { useIsMobile } from '../../../../utils/hooks';
import useStyles from './CarouselStyles';
import { Time } from './Time';

/**
 * A carousel for showing a list of items in a swipe-able grid format.
 *
 * TODO move this to the component library
 */
const Carousel = ({
  booking,
  columns,
  gap,
  items,
  loading,
  mobileButtonsOnly,
  rows,
  style,
  skipAreaSelection,
}) => {
  const classes = useStyles({ columns, gap, rows, style });

  const swipeRef = useRef(null);
  const [index, setIndex] = useState(0);

  const gridSize = rows * columns;
  const chunks = makeChunks(items, gridSize);
  const maxIndex = chunks.length - 1;

  const isMobile = useIsMobile();

  const next = () => {
    setIndex(Math.min(index + 1, maxIndex));
  };

  const prev = () => {
    setIndex(Math.max(index - 1, 0));
  };

  useEffect(() => {
    const newIndex = chunks.findIndex((chunk) =>
      chunk.some(
        (availabilitySuggestion) =>
          availabilitySuggestion.time === booking.availabilitySuggestion?.time &&
          availabilitySuggestion.restId === booking.availabilitySuggestion?.restId,
      ),
    );

    if (newIndex !== -1) {
      // we have a match let's gooo!!!
      setIndex(newIndex);

      // note: this conflicts with the scroll date into view
      // if (swipeRef) {
      //   setTimeout(() => {
      //     swipeRef.current.scrollIntoView({
      //       behavior: 'smooth',
      //       block: 'center',
      //       inline: 'center',
      //     });
      //   }, 100);
      // }
    } else if (index >= maxIndex) {
      // reset the index if the index has fallen outside of the bounds
      setIndex(0);
    }
  }, [items.length, gridSize, maxIndex]);

  const scrollRight = () => {
    trackEvent('Scroll', 'Scrolled Right', 'Button');
    next();
  };

  const scrollLeft = () => {
    trackEvent('Scroll', 'Scrolled Left', 'Button');
    prev();
  };

  return (
    <Spacer direction='vertical'>
      <Spacer style={{ position: 'relative' }}>
        {!isMobile && !mobileButtonsOnly && (
          <Box style={{ position: 'absolute', left: '-34px' }}>
            {index > 0 && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollLeft}>
                <IconCalPrev />
              </IconButton>
            )}
          </Box>
        )}

        {/* Grid */}
        <Box style={{ overflow: 'hidden', width: '100%' }}>
          <Swipe ref={swipeRef} onNext={next} onPrev={prev}>
            <Box
              className={classes.items}
              style={{
                transform: `translateX(-${index * swipeRef.current?.clientWidth + index * gap}px)`,
                gridTemplateColumns: `repeat(${chunks.length}, 100%)`,
              }}
            >
              {(loading ? [Array.from({ length: gridSize }, () => ({}))] : chunks).map(
                (itemGroup) => (
                  <Box className={classes.item}>
                    {itemGroup.map((item) => (
                      <ConditionalWrapper
                        key={item.time}
                        condition={loading}
                        wrapper={(children) => (
                          <Skeleton
                            variant='rect'
                            style={{ borderRadius: '6px', maxWidth: '100%' }}
                          >
                            {children}
                          </Skeleton>
                        )}
                      >
                        <Time availabilitySuggestion={item} skipAreaSelection={skipAreaSelection} />
                      </ConditionalWrapper>
                    ))}
                  </Box>
                ),
              )}
            </Box>
          </Swipe>
        </Box>

        {!isMobile && !mobileButtonsOnly && (
          <Box style={{ position: 'absolute', right: '-34px' }}>
            {index < maxIndex && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollRight}>
                <IconCalNext />
              </IconButton>
            )}
          </Box>
        )}
      </Spacer>

      {(isMobile || mobileButtonsOnly) && (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Left */}
          <Box>
            {index > 0 && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollLeft}>
                <IconCalPrev />
              </IconButton>
            )}
          </Box>

          {/* Right */}
          <Box>
            {index < maxIndex && (
              <IconButton variant='outlined' className={classes.iconButton} onClick={scrollRight}>
                <IconCalNext />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Spacer>
  );
};

Carousel.defaultProps = {
  columns: 4,
  gap: 8,
  items: [],
  loading: false,
  mobileButtonsOnly: false,
  rows: 3,
  style: {},
  skipAreaSelection: false,
};

Carousel.propTypes = {
  booking: bookingPropTypes.isRequired,
  columns: PropTypes.number,
  gap: PropTypes.number,
  items: PropTypes.arrayOf(obeeAvailabilitySuggestionPropTypes),
  loading: PropTypes.bool,
  mobileButtonsOnly: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.shape({}),
  skipAreaSelection: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
