import ReactGA from 'react-ga4';
import store from '../data/store';
import { devLog } from './devLog';
import FacebookTracking from './facebookAnalytics';
import { snakeCase } from './helpers';

/**
 * Tracking in-page event interactions.
 * @param {string} category A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {string} action A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param {string|null} label More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 */
export const trackEvent = (category, action, label = null) => {
  const event = {
    category: snakeCase(category),
    action: snakeCase(action),
    label: snakeCase(label),
  };
  devLog('tracking', 'tracked google event', event);
  ReactGA.event(event);

  // Track with facebook, if this restaurant has a pixel
  const facebookPixel = store?.getState()?.integrations?.facebookPixel;
  if (facebookPixel) {
    devLog('tracking', 'tracking fb event', event);
    FacebookTracking.trackEvent(action, event);
  }
};

/**
 * Allow to measure periods of time.
 * @param {string} action `${action}_timing_ms`.
 * @param {number} value Number of milliseconds elapsed time to respond.
 * @param {string|'API'} category A top level category for these timing events. E.g. 'API'.
 */
export const trackTiming = (action, value, category = 'api') => {
  const timing = {
    category: snakeCase(category),
    action: `${snakeCase(action)}_timing_ms`,
    value, // in milliseconds
    nonInteraction: true,
  };
  devLog('tracking', 'tracked timing', timing);
  ReactGA.event(timing);
};

export const trackGooglePageview = () => {
  const pageview = window.location.pathname + window.location.search;
  devLog('tracking', 'tracked google pageview', pageview);
  ReactGA.send('pageview');
};

/**
 * Track a pageview with facebook
 * This happens separately to google, since we need to first
 * fetch the restaurant's pixel
 */
export const trackFacebookPageView = () => {
  devLog('tracking', 'tracked facebook pageview');
  FacebookTracking.trackPageview();
};

export const trackCompletion = () => {
  trackEvent('Booking', 'Confirming Booking');
  FacebookTracking.trackEvent('Schedule');
};
