import { useEffect, useState } from 'react';
import { devLog } from '../devLog';
import { getCoordinates } from '../getCoordinates';

/**
 * A custom React hook that provides coordinates based on longitude, latitude, or address.
 *
 * If both `longitude` and `latitude` are provided, it uses them directly as coordinates.
 * Otherwise, it uses the Mapbox Geocoding API to fetch coordinates based on the provided `address`.
 *
 * @param {number} longitude - The longitude coordinate.
 * @param {number} latitude - The latitude coordinate.
 * @param {string} address - The address to fetch coordinates if longitude and latitude are not provided.
 * @returns {Array<number>|undefined} - An array containing the latitude and longitude coordinates, or `undefined` if not available.
 */
export const useCoordinates = (longitude, latitude, address) => {
  const [coordinates, setCoordinates] = useState(undefined);

  useEffect(() => {
    if (longitude && latitude) {
      setCoordinates([longitude, latitude]);
    } else {
      // fallback: use the mapbox geocoder
      (async () => {
        try {
          if (!address) {
            throw new Error('No address provided for the venue');
          }

          const response = await getCoordinates(address);
          setCoordinates(response);
        } catch (error) {
          setCoordinates(undefined);

          devLog('error', error.message);
        }
      })();
    }
  }, [longitude, latitude, address]);

  return coordinates;
};
