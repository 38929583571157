import { isObee } from '../../utils/constants';
import { isNil, noop } from '../../utils/helpers';
import { graphQLQuery } from '../api/ApiClient';
import {
  restaurantForId,
  restaurantForIdEatClub,
  restaurantForSlug,
  restaurantForSlugEatClub,
  restaurantsByIds,
  restaurantsByIdsEatClub,
} from '../graphql/queries';
import store from '../store';
import { makeApiAction } from './shared/helpers';
import * as type from './types';

/**
 * Used for fetching a multi venue via restId
 * @param {string} restId
 * @param {() => void} callback called on makeApiAction success
 * @returns
 */
export const restaurantForIdAction =
  (restId, callback = noop) =>
  (dispatch) => {
    const request = isObee
      ? (variables) => graphQLQuery(restaurantForId, variables)
      : (variables) => graphQLQuery(restaurantForIdEatClub, variables);

    makeApiAction(
      'RESTAURANT_FOR_ID',
      request,
      { restId },
      'restaurantForId',
      'an error occurred while retrieving the restaurant.',
      dispatch,
      null,
      null,
      false,
      false,
      true,
      callback,
    );
  };

/**
 * Set's the active restaurant
 * @param {string} restId
 * @param {() => void} callback
 * @returns
 */
export const setRestaurantAction =
  (restId, callback = noop) =>
  (dispatch) => {
    const state = store?.getState();
    const { restaurants } = state;

    const payload = restaurants.data.find((restaurant) => restaurant.objectId === restId);

    if (isNil(payload)) {
      // venue isn't cached
      dispatch(restaurantForIdAction(restId, callback));
    } else {
      // venue is cached
      dispatch({
        type: type.SET_RESTAURANT,
        payload,
      });

      callback();
    }
  };

/**
 * Used for fetching the initial venue via slug
 * @param {string} slug
 * @returns
 */
export const restaurantForSlugAction = (slug) => (dispatch) => {
  const request = isObee
    ? (variables) => graphQLQuery(restaurantForSlug, variables)
    : (variables) => graphQLQuery(restaurantForSlugEatClub, variables);

  makeApiAction(
    'RESTAURANT_FOR_SLUG',
    request,
    { slug },
    'restaurantForSlug',
    'an error occurred while retrieving the restaurant.',
    dispatch,
    null,
    null,
    false,
    false,
    true,
  );
};

/**
 * Used for fetching multi venues via restIds
 * @param {string[]} restIds
 * @returns
 */
export const restaurantsByIdsAction = (restIds) => (dispatch) => {
  // Currently eatclub returns properties that obee doesn't
  const request = isObee
    ? (variables) => graphQLQuery(restaurantsByIds, variables)
    : (variables) => graphQLQuery(restaurantsByIdsEatClub, variables);

  makeApiAction(
    'RESTAURANTS_BY_IDS',
    request,
    { restIds },
    'restaurantsByIds',
    'an error occurred while retrieving the restaurants.',
    dispatch,
    null,
    null,
    true,
    false,
    true,
  );
};
