import PropTypes from 'prop-types';

export const obeeGuestPropTypes = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
  landline: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  notes: PropTypes.string,
  allergies: PropTypes.string,
  birthday: PropTypes.string,
  vip: PropTypes.bool,
  jobCompany: PropTypes.string,
  interests: PropTypes.string,
  secretary: PropTypes.string,
  address: PropTypes.string,
  postcode: PropTypes.string,
  website: PropTypes.string,
  blog: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  pinterest: PropTypes.string,
  linkedIn: PropTypes.string,
  blacklisted: PropTypes.bool,
  favouriteMeals: PropTypes.string,
  favouriteDrinks: PropTypes.string,
  preferredTableArea: PropTypes.string,
  smsPromo: PropTypes.bool,
  emailPromo: PropTypes.bool,
});
