const getColor = (context) => {
  switch (context) {
    case 'success':
      return '#13CE66';
    case 'warning':
      return '#FFCC3D';
    case 'error':
      return '#FF4949';
    case 'tracking':
      return '#9D66DC';
    case 'info':
      return '#2D8EFF';
    default:
      return '#ffffff';
  }
};

/**
 * Log a `msg` to console only in dev/demo mode.
 *
 * @param context Success, Tracking or Danger
 * @param name Name of console
 * @param msg Message to log
 */
export const devLog = (context = 'success', name, ...msg) => {
  if (!process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'development') {
    // eslint-disable-next-line no-console
    console.log(`%c${context.toUpperCase()} ${name}: `, `color: ${getColor(context)}`, ...msg);
  }
};
