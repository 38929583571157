import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '../../../../../components/Modal';
import useStyles from './ImageModalStyles';

const ImageModal = ({ areaImage, onAfterClose, onClose, open }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setTimeout(() => onAfterClose(), 225); // Triggers once the modal has faded out
      }}
      headerTitle={areaImage?.areaName}
    >
      <Box className={classes.container}>
        <img alt={areaImage?.areaName} src={areaImage?.imageLink} style={{ width: '100%' }} />
      </Box>
    </Modal>
  );
};

ImageModal.defaultProps = {
  areaImage: null,
  onAfterClose: () => {},
};

ImageModal.propTypes = {
  areaImage: PropTypes.shape({ areaName: PropTypes.string, imageLink: PropTypes.string }),
  onAfterClose: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ImageModal;
