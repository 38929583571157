import { safeParse } from '../../utils/stringHelpers';
import * as type from '../actions/types';

const initialState = {
  data: {},
  error: '',
  pending: false,
  success: false,
  // for caching purposes, remember the last used variables so we don't re-fetch the same terms
  lastUsedVariables: {
    restId: undefined,
    areaId: undefined,
    date: undefined,
    time: undefined,
    size: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_BOOKING_TERMS_PENDING: {
      return {
        ...initialState,
        pending: true,
      };
    }

    case type.FETCH_BOOKING_TERMS_SUCCESS: {
      return {
        ...state,
        data: safeParse(action.payload.data),
        pending: false,
        success: true,
        lastUsedVariables: action.payload.meta.variables,
      };
    }

    case type.FETCH_BOOKING_TERMS_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
