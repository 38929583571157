import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../../../components/Button';
import { Spacer } from '../../../../../components/Spacer';
import { Typography } from '../../../../../components/Typography';
import { batchUpdateBookingAction } from '../../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../../data/models/Restaurant';
import {
  ALL_SESSIONS_LABEL,
  getSessionLabel,
  getSessionsForBooking,
} from '../../../../../data/models/obeeSessions';
import { trackEvent } from '../../../../../utils/analytics';
import { isEmpty, sortByProperty, unique } from '../../../../../utils/helpers';
import useStyles from './SessionsStyles';

const Sessions = ({ batchUpdateBooking, booking, restaurant, suggestionsBySession }) => {
  const theme = useTheme();
  const classes = useStyles();
  const sessions = unique(getSessionsForBooking(restaurant.data.areas, booking), 'label');

  // Get the sessions that can be used
  // Filters out sessions with no availability
  const getSessionOptions = () => {
    const sessionsWithAvailableTables = suggestionsBySession
      .filter((sessionSuggestion) => !isEmpty(sessionSuggestion.availabilitySuggestions))
      .map((sessionSuggestion) => sessionSuggestion.sessionId);

    const sessionsWithTables = sessions.filter((session) =>
      sessionsWithAvailableTables.includes(session.id),
    );

    // If no area is chosen, show all sessions
    if (
      (isEmpty(booking?.preferredAreaId) || booking?.preferredAreaId <= 0) &&
      sessions.length > 1
    ) {
      return [
        { label: ALL_SESSIONS_LABEL, value: -1 },
        ...sessions
          // .filter(
          //   (session) => booking.preferredAreaId <= 1 || booking.preferredAreaId === session.areaId,
          // )
          .sort((a, b) => sortByProperty(a, b, 'startTime'))
          .map((session) => ({
            label: getSessionLabel(session.label),
            value: session.id,
          })),
      ];
    }

    // If a specific area is chosen, show only sessions with availabilities for that area
    if (booking.sessionLabel !== ALL_SESSIONS_LABEL || sessionsWithTables.length > 1) {
      return [
        { label: ALL_SESSIONS_LABEL, value: -1 },
        ...sessionsWithTables
          // .filter(
          //   (session) => booking.preferredAreaId <= 1 || booking.preferredAreaId === session.areaId,
          // )
          .sort((a, b) => sortByProperty(a, b, 'startTime'))
          .map((session) => ({
            label: getSessionLabel(session.label),
            value: session.id,
          })),
      ];
    }

    return [];
  };

  const sessionOptions = getSessionOptions();

  if (isEmpty(sessionOptions)) {
    return <></>;
  }

  const handleOnClick = (session) => {
    trackEvent('Booking', 'Updating Session');
    batchUpdateBooking({
      [bookingKeys.preferredTime]: -1, // note: all times list is dependant on date, area and session
      [bookingKeys.sessionId]: session.value,
      [bookingKeys.sessionLabel]: session.label,
    });
  };

  return (
    <Spacer wrap>
      {sessionOptions.map((sessionOption) => {
        const isSelected = booking.sessionLabel === sessionOption.label;

        // TODO use tabbar component
        return (
          <Button
            key={sessionOption.value}
            variant='outlined'
            size='small'
            className={`${classes.sessionButton} ${isSelected && classes.selected}`}
            onClick={() => handleOnClick(sessionOption)}
          >
            <Typography
              variant='small'
              style={{
                fontWeight: isSelected ? theme.fontWeights.medium : theme.fontWeights.regular,
              }}
            >
              {sessionOption.label}
            </Typography>
          </Button>
        );
      })}
    </Spacer>
  );
};

Sessions.propTypes = {
  batchUpdateBooking: PropTypes.func.isRequired,
  booking: bookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  suggestionsBySession: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      batchUpdateBooking: batchUpdateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
