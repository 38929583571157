/**
 * Mapping of `variant` strings to HTML tags
 */
export const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  large: 'p',
  small: 'p',
  span: 'span',
  caption: 'span',
};
