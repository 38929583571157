import { Box } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './SpacerStyles';

const Spacer = ({ centered, children, className, direction, gap, style, wrap }) => {
  const classes = useStyles({ centered, direction, gap, style, wrap });

  return (
    <Box className={`${classes.container} ${className}`} style={style}>
      {children}
    </Box>
  );
};

Spacer.defaultProps = {
  centered: false,
  children: null,
  className: '',
  direction: 'horizontal',
  gap: 's',
  style: {},
  wrap: false,
};

Spacer.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({ container: PropTypes.shape({}) }),
  wrap: PropTypes.bool,
};

export default Spacer;
