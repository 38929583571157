import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isObee } from '../../../../utils/constants';
import PinPaymentsFields from './PinPaymentsFields';
import StripeFields from './StripeFields';

const CardFields = ({ paymentRef, phone, email, paymentMethodName, confirmingBooking }) => {
  return (
    <>
      {isObee ? (
        <>
          <PinPaymentsFields paymentRef={paymentRef} confirmingBooking={confirmingBooking} />
        </>
      ) : (
        <StripeFields
          paymentRef={paymentRef}
          phone={phone}
          email={email}
          confirmingBooking={confirmingBooking}
          paymentMethodName={paymentMethodName}
        />
      )}
    </>
  );
};

CardFields.defaultProps = {
  paymentRef: null,
  phone: null,
  email: null,
  confirmingBooking: null,
  paymentMethodName: null,
};

CardFields.propTypes = {
  paymentRef: PropTypes.shape({}),
  phone: PropTypes.string,
  email: PropTypes.string,
  confirmingBooking: PropTypes.bool,
  paymentMethodName: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardFields);
