import { useRollbarPerson } from '@rollbar/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import {
  batchUpdateBookingAction,
  clearBookingAction,
  updateBookingAction,
  updateBookingStepAction,
} from '../../data/actions/bookingAction';
import {
  confirmObeeBookingAction,
  fetchObeeBookingAction,
  fetchObeeBookingByCodeAction,
  freeBookingAction,
} from '../../data/actions/obeeBookingAction';
import { restaurantForSlugAction } from '../../data/actions/restaurantAction';
import {
  bookingActions,
  bookingKeys,
  bookingPropTypes,
  bookingSteps,
  isEditing,
} from '../../data/models/Booking';
import { isRestaurantClosedForDate, restaurantPropTypes } from '../../data/models/Restaurant';
import { getMinMaxGroupSize } from '../../data/models/obeeAreas';
import {
  STATUS_TYPES,
  allowedStatusesForConfirm,
  allowedStatusesForEdit,
  isPastBooking,
  obeeBookingPropTypes,
} from '../../data/models/obeeBooking';
import { ALL_SESSIONS_LABEL } from '../../data/models/obeeSessions';
import { isObee } from '../../utils/constants';
import {
  decode,
  extract,
  formatMomentToDate,
  isEmpty,
  isNil,
  range,
  setFavicon,
  setPageTitle,
  setPathname,
} from '../../utils/helpers';
import { useTimeout } from '../../utils/hooks/useTimeout';
import { safeParse } from '../../utils/stringHelpers';
import { ErrorDisplay } from '../ErrorDisplay';
import { FetchingDetails } from '../FetchingDetails';
import { TimedOut } from '../TimedOut';
import { UserErrorDisplay } from '../UserErrorDisplay';
import { Availability } from './Availability';
import { Confirm } from './Confirm';
import { Details } from './Details';
import { Review } from './Review';

const stripe = isObee ? null : loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Booking = ({
  batchUpdateBooking,
  booking,
  clearBooking,
  confirmObeeBooking,
  fetchObeeBooking,
  fetchObeeBookingByCode,
  obeeBooking,
  restaurant,
  restaurantForSlug,
  updateBooking,
  updateBookingStep,
  freeBooking,
}) => {
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const pathname = location.pathname.slice(1);

  const [action, setAction] = useState(null);
  const [code, setCode] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [slug, setSlug] = useState(null);

  const [rollbarPerson, setRollbarPerson] = useState(null);
  useRollbarPerson(rollbarPerson);

  const [error, setError] = useState(null);
  if (error) {
    throw error;
  }

  const tenMinutesInMs = 10 * 1000 * 60;
  // const tenSecondsInMs = 10 * 1000;
  const hasBeenBooked = !!obeeBooking?.data?.status;
  const [timedOut, setTimedOut] = useState(false);
  const timer = useTimeout(
    () => {
      // Table ID is unique to both the area ID and table ID in the chosen availability suggestion
      // const suggestion = booking?.availabilitySuggestion;

      // Show warning
      if (!obeeBooking?.data?.status && !isEditing(booking) && !booking?.waitlist) {
        setTimedOut(true);
      }
    },
    tenMinutesInMs,
    booking?.heldBookingId,
  );

  const freeHeldBooking = () => {
    if (booking.heldBookingId) {
      freeBooking(booking.heldBookingId);
    }
  };

  // On unmount, free up booking
  useEffect(() => {
    return freeHeldBooking;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', freeHeldBooking);
    return () => {
      window.removeEventListener('beforeunload', freeHeldBooking);
    };
  }, [freeHeldBooking, booking]);

  useEffect(async () => {
    // TODO is there a better way to test?
    // This route MIGHT be deprecated (for ecb widget v2 release)
    if (pathname?.length > 40) {
      // note: the max length for a slug is 40 but give a buffer for html encoded characters
      // magical link!
      try {
        updateBooking(bookingKeys.fetchingExisting, true);

        const decodedPath = await decode(pathname);
        const parsed = decodedPath?.split(';').map((item) => item.split('='));

        const slugToUse = extract(parsed, 'venue');
        const actionToUse = extract(parsed, 'action');
        const bookingIdToUse = extract(parsed, 'bookingId');

        if (!isEmpty(slugToUse) && !isEmpty(actionToUse)) {
          unstable_batchedUpdates(() => {
            setSlug(slugToUse);
            setAction(actionToUse);
            setBookingId(parseInt(bookingIdToUse, 10));
          });
        } else {
          // broken link
          setError(`${pathname} is an invalid email link`);
        }
      } catch (err) {
        updateBooking(bookingKeys.fetchingExisting, false);
        setError(`an error occurred decoding ${pathname}`);
      }
    } else if (pathname) {
      // the path is the slug
      setSlug(pathname);
      const bAction = searchParams.get('action');
      const bCode = searchParams.get('code');

      if (!isNil(bAction) && !isNil(bCode)) {
        updateBooking(bookingKeys.fetchingExisting, true);

        setAction(bAction);
        setCode(bCode);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!isNil(slug)) {
      restaurantForSlug(slug);
    }
  }, [slug]);

  const setNextAvailableDate = (sessions) => {
    const nextAvailableDate = range(1, 29)
      .map((num) => moment().add(num, 'day'))
      .find((date) => {
        return !isRestaurantClosedForDate(
          date,
          sessions,
          restaurant.data.closures,
          restaurant.data.areas,
        );
      });

    if (nextAvailableDate) {
      updateBooking(bookingKeys.date, formatMomentToDate(nextAvailableDate));
    } else {
      // todo: shouldn't fallback to today if the next month was closed
      updateBooking(bookingKeys.date, formatMomentToDate(moment()));
    }
  };

  const setDate = (areas) => {
    const today = moment();
    const sessions = areas.flatMap((area) => area.sessions);

    if (
      !isRestaurantClosedForDate(today, sessions, restaurant.data.closures, restaurant.data.areas)
    ) {
      updateBooking(bookingKeys.date, formatMomentToDate(today));
    } else {
      // set the date to the next available date
      setNextAvailableDate(sessions);
    }
  };

  useEffect(() => {
    if (restaurant.success && isNil(booking.date)) {
      setDate(restaurant.data.areas);
    }
  }, [restaurant.success, booking.date]);

  useEffect(() => {
    if (restaurant.success) {
      const { objectId, name, address, premium, waitlist, closures } = restaurant.data;

      // some nice information for error triaging
      setRollbarPerson({
        restaurant: { objectId, address, name, premium, waitlist, closures },
      });

      switch (action) {
        // Fetch the booking for the following email link actions
        case bookingActions.cc:
        case bookingActions.edit:
        case bookingActions.cancel:
        case bookingActions.confirm:
        case bookingActions.review: {
          if (!isNil(bookingId)) {
            fetchObeeBooking(bookingId);
          } else if (!isNil(code)) {
            fetchObeeBookingByCode(code);
          } else {
            updateBooking(bookingKeys.fetchingExisting, false);
          }
          break;
        }
        case bookingActions.create:
        default: {
          // Fallback to the normal booking flow
          updateBooking(bookingKeys.fetchingExisting, false);
          break;
        }
      }
    }
  }, [restaurant.success]);

  useEffect(() => {
    if (obeeBooking.success) {
      const pastBooking = isPastBooking(obeeBooking.data);

      switch (action) {
        case bookingActions.cc:
        case bookingActions.edit: {
          if (allowedStatusesForEdit.includes(obeeBooking?.data?.status) && !pastBooking) {
            const {
              areaId,
              date,
              time,
              size,
              customerComments,
              status,
              tableNumber,
              highchairs,
              kids,
              guests,
              paymentCustomerId,
              paymentMethodId,
              paymentMethodName,
            } = obeeBooking.data;

            const guest = guests?.[0];

            const isWaitlist = status === STATUS_TYPES.WAITLIST;

            // Try to get the name from the booking. The name from the guest may not be the name it was booked under
            const getNameFromBooking = () => {
              // if ([guest?.firstName, guest?.lastName].join(' ') === booking.name) {
              //   return { firstName: guest?.firstName, lastName: guest?.lastName };
              // }

              // Otherwise try extracting it from the booking. Not the best if the person has spaces in their name
              const nameParts = (obeeBooking.data.name || '').split(' ');

              // Assume last name to be the last name provided, so long as two or more names were provided
              const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

              return {
                firstName: nameParts.slice(0, -1).join(' '),
                lastName,
              };
            };

            const { firstName, lastName } = getNameFromBooking();

            const newBooking = {
              [isWaitlist ? 'editWaitlist' : 'editBooking']: true,
              // Booking
              [bookingKeys.date]: date,
              [bookingKeys.guests]: size,
              [bookingKeys.highchairs]: Math.max(0, highchairs),
              [bookingKeys.kids]: Math.max(0, kids),
              [bookingKeys.areaId]: areaId,
              [bookingKeys.sessionId]: -1, // TODO: we have limited information about the session with the booking object, should we still fetch which session the date and time belongs to?
              [bookingKeys.sessionLabel]: ALL_SESSIONS_LABEL,
              [bookingKeys.availabilitySuggestion]: {
                time,
                table: { tableNumber },
                waitlist: isWaitlist,
              },
              // Guest
              [bookingKeys.firstName]: firstName,
              [bookingKeys.lastName]: lastName,
              [bookingKeys.mobile]: guest.mobile,
              [bookingKeys.email]: guest.email,
              [bookingKeys.specialRequirements]: customerComments,
              // Payment
              [bookingKeys.paymentMethodId]: paymentMethodId,
              [bookingKeys.paymentMethodName]: paymentMethodName,
              [bookingKeys.setupIntentId]: paymentCustomerId,
            };

            // Jump straight to the credit card screen if that's the action
            if (action === bookingActions.cc) {
              newBooking.step = bookingSteps.review;
            }

            batchUpdateBooking(newBooking);
          } else {
            updateBookingStep(bookingSteps.confirm);
          }
          break;
        }
        case bookingActions.confirm: {
          if (allowedStatusesForConfirm.includes(obeeBooking.data.status) && !pastBooking) {
            confirmObeeBooking(obeeBooking.data.id); // note: should this follow the same flow as cancel? which is go to the final screen with a confirmation modal to confirm booking
          }
          updateBookingStep(bookingSteps.confirm);
          break;
        }
        case bookingActions.cancel:
        case bookingActions.review: {
          updateBookingStep(bookingSteps.confirm);
          break;
        }
        case bookingActions.create:
        default: {
          break;
        }
      }

      updateBooking(bookingKeys.fetchingExisting, false);
    }
  }, [obeeBooking.success]);

  useEffect(() => {
    if (obeeBooking.error) {
      updateBooking(bookingKeys.fetchingExisting, false);
    }
  }, [obeeBooking.error]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [booking.step]);

  // Set title
  useEffect(() => {
    setPageTitle(restaurant.data?.name);
  }, [restaurant.data?.name]);

  // Set favicon
  useEffect(() => {
    setFavicon(restaurant.data?.favicon);
  }, [restaurant.data?.favicon]);

  // Set pathname
  useEffect(() => {
    setPathname(restaurant.data?.slug);
  }, [restaurant.data?.slug]);

  // Automatically update the booking's min/max guests if it's outside of what's allowed, or set the default
  useEffect(() => {
    if (isEmpty(restaurant?.data?.areas)) {
      return;
    }

    const defaultBookingGuests = 2;
    let preferredGuestAmount = booking.guests ?? defaultBookingGuests;
    const { minGroup, maxGroup } = getMinMaxGroupSize(restaurant.data?.areas, booking.areaId);

    // If too low
    if (preferredGuestAmount < minGroup) {
      preferredGuestAmount = minGroup;
    }

    // If too high
    if (preferredGuestAmount > maxGroup) {
      preferredGuestAmount = maxGroup;
    }

    if (preferredGuestAmount !== booking.guests) {
      updateBooking(bookingKeys.guests, preferredGuestAmount);
    }
  }, [restaurant.data.areas]);

  const bookingCanBeEdited = () => {
    if (
      !isPastBooking(obeeBooking.data) &&
      (isEditing(booking) || action === bookingActions.cancel) &&
      obeeBooking?.data?.bookingCutOffs
    ) {
      const cutoffs = safeParse(obeeBooking?.data?.bookingCutOffs);

      // If restaurant disallows editing/cancelling
      if (!cutoffs.canEditOnline) {
        return false;
      }

      // If restaurant disallows editing when a credit card has been provided
      if (cutoffs.canEditWithPayment === false) {
        return false;
      }

      // If the current time is beyond the cutoff for the booking
      if (Date.now() / 1000 > cutoffs.lastOnlineCancellableSeconds) {
        return false;
      }
    }

    return true;
  };

  const getBookingStep = () => {
    switch (booking.step) {
      case 0:
        return <Availability />;
      case 1:
        return <Details />;
      case 2:
        return (
          <ConditionalWrapper
            condition={!isObee}
            wrapper={(children) => <Elements stripe={stripe}>{children}</Elements>}
          >
            <Review />
          </ConditionalWrapper>
        );
      case 3:
        return <Confirm confirmCancel={action === bookingActions.cancel} />;
      default:
        clearBooking();
        return <Availability />;
    }
  };

  if (restaurant.error) {
    return <ErrorDisplay error={restaurant.error} />;
  }

  if (booking.fetchingExisting) {
    return <FetchingDetails message='Please wait a moment while we retrieve the booking.' />;
  }

  if (restaurant.pending || isNil(booking.date)) {
    return <FetchingDetails />;
  }

  if (!bookingCanBeEdited()) {
    if (restaurant.data.cutoffMessage) {
      return (
        <UserErrorDisplay
          title='Unable to change booking'
          description={restaurant.data.cutoffMessage}
        />
      );
    }

    if (action === bookingActions.cancel) {
      return (
        <UserErrorDisplay
          title='Unable to cancel booking'
          description={`Please contact us directly ${
            restaurant?.data?.phone ? `at ${restaurant.data.phone}` : ''
          } to cancel your booking.`}
        />
      );
    }

    return (
      <UserErrorDisplay
        title='Unable to edit booking'
        description={`This booking can no longer be edited or cancelled online. Please contact us ${
          restaurant?.data?.phone ? `at ${restaurant.data.phone}` : ''
        } to change your booking.`}
      />
    );
  }

  const getPageTitle = () => {
    switch (booking.step) {
      case 3:
        return 'Booking confirmation';
      case 0:
      case 1:
      default:
        return booking.editBooking || bookingSteps.editWaitlist
          ? 'Edit a booking'
          : 'Make a booking';
    }
  };

  return (
    <>
      {restaurant.data.name && (
        <Helmet>
          <title>
            {restaurant.data.name} - {getPageTitle()}
          </title>
          <meta name='description' content={`Make a booking at ${restaurant.data.name}`} />
        </Helmet>
      )}

      {getBookingStep()}
      <TimedOut
        open={timedOut && !hasBeenBooked}
        onReset={() => {
          setTimedOut(false);
          timer.reset();
        }}
      />
    </>
  );
};

Booking.propTypes = {
  batchUpdateBooking: PropTypes.func.isRequired,
  booking: bookingPropTypes.isRequired,
  clearBooking: PropTypes.func.isRequired,
  confirmObeeBooking: PropTypes.func.isRequired,
  fetchObeeBooking: PropTypes.func.isRequired,
  fetchObeeBookingByCode: PropTypes.func.isRequired,
  obeeBooking: obeeBookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  restaurantForSlug: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
  freeBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  paymentSettings: state.paymentSettings,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      batchUpdateBooking: batchUpdateBookingAction,
      clearBooking: clearBookingAction,
      confirmObeeBooking: confirmObeeBookingAction,
      fetchObeeBooking: fetchObeeBookingAction,
      fetchObeeBookingByCode: fetchObeeBookingByCodeAction,
      restaurantForSlug: restaurantForSlugAction,
      updateBooking: updateBookingAction,
      updateBookingStep: updateBookingStepAction,
      freeBooking: freeBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
