import { createTheme as MuiCreateTheme } from '@material-ui/core';
import WebFont from 'webfontloader';
import { isObee } from '../utils/constants';
import { baseColors, baseFontWeights, baseTypography } from './base';
import { fontFamily as eatClubFontFamily, eatclubColors, eatclubTypography } from './eatclub';
import { obeeColors, fontFamily as obeeFontFamily, obeeTypography } from './obee';

export const colors = { ...baseColors, ...(isObee ? obeeColors : eatclubColors) };
export const fontWeights = baseFontWeights;
export const typography = { ...baseTypography, ...(isObee ? obeeTypography : eatclubTypography) };

const convertPercentageToHex = (percentage) => {
  const percentageAsInt = parseInt(`${percentage}`, 10);

  if (Number.isNaN(percentageAsInt)) {
    return '';
  }
  if (percentageAsInt === 100) {
    return 'ff';
  }
  return Math.floor((256 * percentageAsInt) / 100)
    .toString(16)
    .padStart(2, '0');
};

export const getHexColourWithOpacity = (hexColour, opacity) => {
  return `${hexColour}${convertPercentageToHex(opacity)}`;
};

export const createTheme = (
  generalFontFamilies = null,
  availabilityFontFamilies = null,
  mainButtonColour = colors.liteAvailabilityMainButtonBgColour,
  mainButtonTextColour = null,
  mainButtonCornerRadius = 6,
  mainTextColour = '#313131',
  outlineColour = '#ffffff',
  activeOutlineColour, // when hovered or focused
  availabilityBackgroundBoxBgColour = null,
  darkMode = false,
  widgetHeaderBgColor,
  secondaryTextColour,
  secondaryTextOpacity,
  outlineOpacity,
  availabilityFieldsActiveStateColour,
  availabilityFieldsColourOpacity,
) => {
  const families = [
    'Open Sans',
    ...(generalFontFamilies ? generalFontFamilies.split(',') : []),
    ...(availabilityFontFamilies ? availabilityFontFamilies.split(',') : []),
  ].filter((value, index, self) => self.indexOf(value) === index);

  if (families.length > 0) {
    WebFont.load({ google: { families: families.map((family) => `${family}:400,500,700,900`) } });
  }

  return MuiCreateTheme({
    colors: {
      ...colors,
      mainTextColour,
      availabilityBackgroundBoxBgColour,
      darkMode,
      mainButtonTextColour,
      outlineColour: getHexColourWithOpacity(outlineColour, outlineOpacity),
      activeOutlineColour,
      formHeaderBackgroundColor: darkMode ? 'transparent' : widgetHeaderBgColor,
      inputFieldsBackground: darkMode ? availabilityBackgroundBoxBgColour : 'white',
      secondaryTextColour,
      activeFieldsBackground: getHexColourWithOpacity(
        availabilityFieldsActiveStateColour,
        availabilityFieldsColourOpacity,
      ),
    },
    fontWeights,
    baseTypography: {
      ...baseTypography,
      fontFamily: 'inherit',
      availabilityFontFamily:
        availabilityFontFamilies ??
        (isObee ? obeeFontFamily : eatClubFontFamily) ??
        generalFontFamilies,
      generalFontFamily: generalFontFamilies,
    },
    typography: {
      ...typography,
      fontFamily: 'inherit',
    },
    breakpoints: {
      values: {
        xs: 480,
        sm: 644,
        md: 944,
        lg: 1200,
        xl: 1536,
      },
    },
    overrides: {
      MuiInputBase: {
        root: {
          fontSize: '16px', // '1em',
          color: `${mainTextColour} !important`,
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: `${mainTextColour}`,
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '16px', // '1em',
          color: `${secondaryTextColour} !important`,
          '&$focused': {
            color: `${secondaryTextColour} !important`,
          },
        },
      },
      MuiFilledInput: {
        root: {
          // TODO only use coloured background on dark mode
          backgroundColor: availabilityBackgroundBoxBgColour || 'white',
          '&:hover': {
            backgroundColor: availabilityBackgroundBoxBgColour || 'white',
            outline: `1px solid ${activeOutlineColour} !important`,
          },
          '&.Mui-focused': {
            backgroundColor: availabilityBackgroundBoxBgColour || 'white',
            outline: `1px solid ${activeOutlineColour} !important`,
          },
        },
        multiline: {
          padding: '35px 12px 10px',
        },
      },
      MuiFormHelperText: {
        root: {
          margin: '4px 0 0 0',
        },
        contained: {
          color: mainTextColour,
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiIconButton: {
        root: {
          color: mainTextColour,
          '&.Mui-disabled': {
            color: '#00000022',
            border: '1px solid transparent',
          },
        },
      },
      MuiButton: {
        root: {
          color: mainTextColour,
        },
        outlined: {
          border: `1px solid ${getHexColourWithOpacity(outlineColour, outlineOpacity)}`,
          '&:hover': {
            border: `1px solid ${activeOutlineColour}`,
          },
          '&:focus': {
            border: `1px solid ${activeOutlineColour}`,
          },
          borderRadius: `${mainButtonCornerRadius}px`,
        },
        containedPrimary: {
          color: mainButtonTextColour,
          borderRadius: `${mainButtonCornerRadius}px !important`,
        },
      },
    },
    palette: {
      primary: {
        main: mainButtonColour,
      },
    },
  });
};
