import { Provider as RollbarProvider, historyContext } from '@rollbar/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './data/store';
import configureRollbar from './rollbar';

const { rollbar } = configureRollbar();

const history = createBrowserHistory();
history.listen(historyContext(rollbar));

ReactDOM.render(
  <RollbarProvider instance={rollbar}>
    <Provider store={store}>
      <App />
    </Provider>
  </RollbarProvider>,
  document.getElementById('root'),
);
