import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    rowGap: '12px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
