import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCircleCheck } from '../../assets/icons/check_circle.svg';
import { ReactComponent as IconWaitlist } from '../../assets/icons/waitlist.svg';
import { minutesToTimeString } from '../../utils/helpers';
import { obeeGuestPropTypes } from './obeeGuests';

export const obeeBookingPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    areaId: PropTypes.number,
    date: PropTypes.string,
    time: PropTypes.number,
    size: PropTypes.number,
    name: PropTypes.string,
    customerComments: PropTypes.string,
    status: PropTypes.string,
    tableNumber: PropTypes.string,
    duration: PropTypes.number,
    highchairs: PropTypes.number,
    kids: PropTypes.number,
    paymentCustomerId: PropTypes.string,
    paymentMethodId: PropTypes.string,
    paymentMethodName: PropTypes.string,
    guests: PropTypes.arrayOf(obeeGuestPropTypes),
  }),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const STATUS_TYPES = {
  BOOKED: 'booked',
  CONFIRMED: 'confirmed',
  ARRIVED: 'arrived',
  FINISHED: 'finished',
  NO_SHOW: 'noshow',
  CANCELLED: 'cancelled',
  WAITLIST: 'waitlist',
  UNCONFIRMED: 'unconfirmed', // A waitlist booking that has been cancelled
};

export const allowedStatusesForEdit = [
  STATUS_TYPES.BOOKED,
  STATUS_TYPES.CONFIRMED,
  STATUS_TYPES.WAITLIST,
];

export const allowedStatusesForCancel = [
  STATUS_TYPES.BOOKED,
  STATUS_TYPES.CONFIRMED,
  STATUS_TYPES.WAITLIST,
];

export const allowedStatusesForConfirm = [STATUS_TYPES.BOOKED];

/**
 * Checks if the booking is in the past
 * @param {*} booking
 * @returns {boolean}
 */
export const isPastBooking = (booking) => {
  const now = moment();

  const timeAsInt = parseInt(booking?.time, 10) || 0;
  const bookingMoment = moment(
    `${booking?.date} ${minutesToTimeString(timeAsInt)}`,
    'YYYY-MM-DD h:mma',
  );

  return bookingMoment <= now;
};

export const getConfirmationIcon = (isWaitlist, premium, theme) => {
  if (isWaitlist) {
    return <IconWaitlist height='60' width='60' style={{ color: '#FDFDFC' }} />;
  }

  return (
    <IconCircleCheck
      height='60'
      width='60'
      color={(premium && theme.colors.mainTextColour) || theme.colors.deepTeal}
    />
  );
};

export const getConfirmationTitle = (pastBooking, status) => {
  // Any waitlist booking should trump others
  if (status === STATUS_TYPES.WAITLIST) {
    return "You're on the waitlist";
  }

  if (pastBooking) {
    // override the message if it's a past booking
    return 'Thank you for dining with us';
  }

  switch (status) {
    case STATUS_TYPES.FINISHED: {
      return 'Thank you for dining with us';
    }
    case STATUS_TYPES.NO_SHOW:
    case STATUS_TYPES.CANCELLED: {
      return 'Your booking has been cancelled';
    }
    case STATUS_TYPES.UNCONFIRMED: {
      return 'Your waitlist request has been cancelled';
    }
    case STATUS_TYPES.CONFIRMED:
    case STATUS_TYPES.ARRIVED: {
      return 'Your booking is confirmed';
    }
    case STATUS_TYPES.WAITLIST: {
      return "You're on the waitlist";
    }
    default: {
      return 'Thank you, your table has been reserved';
    }
  }
};

export const getConfirmationContent = (pastBooking, status) => {
  if (pastBooking) {
    return null;
  }

  switch (status) {
    case STATUS_TYPES.FINISHED: {
      return null;
    }
    case STATUS_TYPES.NO_SHOW:
      return null;
    case STATUS_TYPES.CANCELLED: {
      return "Thanks for cancelling your booking so that it's available to other guests! We hope to see you again soon.";
    }
    case STATUS_TYPES.UNCONFIRMED: {
      return null;
    }
    case STATUS_TYPES.ARRIVED: {
      return null;
    }
    case STATUS_TYPES.WAITLIST: {
      return "Thank you for putting your name down on the waitlist. As soon as a table becomes available, we'll get in touch.";
    }
    case STATUS_TYPES.CONFIRMED:
    default: {
      return 'If you would like to make any special arrangements or have questions about your reservation, please do not hesitate to reach out.';
    }
  }
};
