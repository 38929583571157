import PropTypes from 'prop-types';

export const messagesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    severity: PropTypes.string,
    context: PropTypes.string,
    type: PropTypes.string,
    arg: PropTypes.shape({}),
  }),
);
