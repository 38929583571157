import * as type from '../actions/types';

const initialState = {
  data: {},
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_OBEE_BOOKING_PENDING:
    case type.CREATE_OBEE_BOOKING_PENDING:
    case type.UPDATE_OBEE_BOOKING_PENDING:
    case type.CONFIRM_OBEE_BOOKING_PENDING:
    case type.CANCEL_OBEE_BOOKING_PENDING: {
      return {
        ...state,
        error: '',
        pending: true,
        success: false,
      };
    }

    case type.FETCH_OBEE_BOOKING_SUCCESS:
    case type.CREATE_OBEE_BOOKING_SUCCESS:
    case type.UPDATE_OBEE_BOOKING_SUCCESS:
    case type.CONFIRM_OBEE_BOOKING_SUCCESS:
    case type.CANCEL_OBEE_BOOKING_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        success: true,
      };
    }

    case type.FETCH_OBEE_BOOKING_FAILURE:
    case type.CREATE_OBEE_BOOKING_FAILURE:
    case type.UPDATE_OBEE_BOOKING_FAILURE:
    case type.CONFIRM_OBEE_BOOKING_FAILURE:
    case type.CANCEL_OBEE_BOOKING_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    // remove the obee booking when clearing the local booking (triggers when adding a new booking)
    case type.CLEAR_BOOKING: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
