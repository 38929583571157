import { CircularProgress, Button as MuiButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconChevronDown } from '../../assets/icons/chevron_down.svg';
import { Spacer } from '../Spacer';
import useStyles from './ButtonStyles';

// note: using mui button for now for the ease of theming benefits (hover colors and contrast text color)
// todo: look into removing when transitioning to jss theme

const Button = ({
  active,
  children,
  className,
  disabled,
  loading,
  onClick,
  showActiveBorder,
  showArrowDown,
  style,
  variant,
  ...rest
}) => {
  const classes = useStyles({ style, active, showActiveBorder });

  return (
    <MuiButton
      className={`${classes.container} ${className}`}
      disabled={disabled || loading}
      disableElevation
      disableRipple
      onClick={onClick}
      variant={variant}
      {...rest}
    >
      <Spacer centered style={{ width: '100%' }}>
        {loading ? <CircularProgress size={15} color='inherit' /> : <></>}
        {children}
        {showArrowDown && (
          <IconChevronDown
            style={{
              flexShrink: 0,
              transform: active ? 'rotate(180deg)' : '',
              marginTop: '2px',
            }}
          />
        )}
      </Spacer>
    </MuiButton>
  );
};

Button.defaultProps = {
  active: false,
  children: null,
  className: '',
  disabled: false,
  loading: false,
  onClick: undefined,
  showActiveBorder: true,
  showArrowDown: false,
  style: {},
  variant: 'contained',
};

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  showActiveBorder: PropTypes.bool,
  showArrowDown: PropTypes.bool,
  style: PropTypes.shape({}),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

export default Button;
