import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  items: ({ gap }) => ({
    display: 'grid',
    gap: `${gap}px`,
    transition: 'transform 0.25s',
  }),
  item: ({ rows, columns, gap }) => ({
    display: 'grid',
    gap: `${gap}px`,
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  }),
  iconButton: {
    alignItems: 'center',
    display: 'flex',
    height: '28px',
    justifyContent: 'center',
    width: '28px',
  },
}));

export default useStyles;
