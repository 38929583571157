import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../../../../components/Button';
import { Spacer } from '../../../../../../components/Spacer';
import { Typography } from '../../../../../../components/Typography';
import { updateBookingAction } from '../../../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes } from '../../../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../../../data/models/Restaurant';
import { getHexColourWithOpacity } from '../../../../../../theme';
import { trackEvent } from '../../../../../../utils/analytics';
import { formatMomentToDate, getContrastTextColor } from '../../../../../../utils/helpers';
import useStyles from './DateStyles';

const Date = ({ booking, disabled, isToday, restaurant, today, updateBooking }) => {
  const theme = useTheme();
  const ref = useRef();
  const isSelected = booking.date && booking.date === formatMomentToDate(today);
  const classes = useStyles({ disabled, isSelected });

  const isFull = false; // todo: get this from api

  useEffect(() => {
    if (ref && isSelected) {
      setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }, 100);
    }
  }, [isSelected]);

  const getSelectedColor = () => {
    if (disabled) {
      return theme.colors.americanSilver;
    }

    if (isSelected) {
      return getHexColourWithOpacity(
        restaurant.data.availabilityFieldsActiveStateColour,
        restaurant.data.availabilityFieldsColourOpacity,
      );
    }

    return '';
  };

  const selectedColor = getSelectedColor();

  const handleOnClick = () => {
    trackEvent('Booking', 'Updating Date', 'Scroll');
    updateBooking(bookingKeys.date, formatMomentToDate(today));
  };

  return (
    <Box ref={ref}>
      <Button
        disabled={disabled}
        onClick={handleOnClick}
        className={classes.button}
        style={{
          background: `${selectedColor}${isSelected ? ' !important' : ''}`,
          color: theme.colors.darkMode ? 'white' : getContrastTextColor(selectedColor),
        }}
        variant='outlined'
      >
        <Box className={classes.container}>
          <Box>
            {isToday ? (
              <Typography variant='caption' className={`${classes.heading} ${classes.todayPill}`}>
                Today
              </Typography>
            ) : (
              <Typography variant='caption' className={classes.heading}>
                {formatMomentToDate(today, 'ddd').toUpperCase()}
              </Typography>
            )}
          </Box>
          <Spacer direction='vertical' style={{ margin: 'auto' }}>
            <Typography>{formatMomentToDate(today, 'Do')}</Typography>
            <Typography variant='caption' className={classes.month}>
              {formatMomentToDate(today, 'MMM').toUpperCase()}
            </Typography>
          </Spacer>
        </Box>

        {isFull && (
          <Typography className={classes.fullPill} variant='caption'>
            FULL
          </Typography>
        )}
      </Button>
    </Box>
  );
};

Date.defaultProps = {
  disabled: false,
  isToday: false,
};

Date.propTypes = {
  booking: bookingPropTypes.isRequired,
  disabled: PropTypes.bool,
  isToday: PropTypes.bool,
  restaurant: restaurantPropTypes.isRequired,
  today: PropTypes.shape({ format: PropTypes.func.isRequired }).isRequired,
  updateBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Date);
