import { makeStyles } from '@material-ui/core';
import { getDirection, getGap } from './SpacerHelpers';

const useStyles = makeStyles(() => ({
  container: ({ centered, direction, gap, style, wrap }) => {
    const flexDirection = getDirection(direction);
    return {
      alignItems: centered || flexDirection === 'row' ? 'center' : '',
      display: 'flex',
      flexDirection,
      flexWrap: wrap ? 'wrap' : '',
      gap: getGap(gap),
      justifyContent: centered ? 'center' : '',
      ...style,
    };
  },
}));

export default useStyles;
