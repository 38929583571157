import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const PaymentPolicyModal = ({ onClose, open, text }) => {
  return (
    <Modal open={open} onClose={onClose} title='Payment Policy'>
      <Typography style={{ whiteSpace: 'pre-wrap' }}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </Modal>
  );
};

PaymentPolicyModal.defaultProps = {
  open: false,
  text: '',
};

PaymentPolicyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  text: PropTypes.string,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPolicyModal);
