import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: ({ style, variant }) => ({
    border: variant === 'outlined' && `1px solid ${theme.colors.outlineColour}`,
    // color: theme.colors.textColour,
    padding: '8px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    ...style,

    '&:hover': {
      border: variant === 'outlined' && `1px solid ${theme.colors.activeOutlineColour} !important`,
      ...style?.hover,
    },
  }),
}));

export default useStyles;
