// eslint-disable-next-line import/no-webpack-loader-syntax, import/order, import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ coordinates }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: coordinates,
      zoom: 16,
    });

    new mapboxgl.Marker({ color: 'black' }).setLngLat(coordinates).addTo(map.current);
  });

  return (
    <div style={{ width: '100%', borderRadius: '24px', overflow: 'hidden' }}>
      <div ref={mapContainer} style={{ height: '400px' }} />
    </div>
  );
};

Map.propTypes = {
  coordinates: PropTypes.shape([PropTypes.number, PropTypes.number]).isRequired,
};

export default Map;
