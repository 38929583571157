import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';

// todo: needs a better name
const Information = ({ Icon, description, heading, show }) => {
  const theme = useTheme();

  if (!show) {
    return <></>;
  }

  return (
    <Spacer gap='m'>
      {React.cloneElement(Icon, { style: { flexShrink: 0 } })}

      <Spacer direction='vertical'>
        <Typography variant='h3' style={{ fontWeight: theme.fontWeights.bold }}>
          {heading}
        </Typography>
        <Typography style={{ color: theme.colors.secondaryTextColour }}>{description}</Typography>
      </Spacer>
    </Spacer>
  );
};

Information.propTypes = {
  Icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Information;
