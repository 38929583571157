import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bookingsDetails: () => ({
    background: theme.colors.formHeaderBackgroundColor,
    padding: '12px',
  }),
  widget: ({ size }) => ({
    padding: size === 'small' ? '20px' : '28px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  }),
}));

export default useStyles;
