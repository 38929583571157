import * as type from '../actions/types';
import { bookingKeys, bookingSteps } from '../models/Booking';
import { ALL_SESSIONS_LABEL } from '../models/obeeSessions';

const initialState = {
  [bookingKeys.fetchingExisting]: false, // fetching existing details from an email link

  step: bookingSteps.availability, // 0 = availability, 1 = details, 2 = review, 3 = confirm
  editBooking: false,
  editWaitlist: false,

  [bookingKeys.date]: null,
  [bookingKeys.guests]: 2,
  [bookingKeys.highchairs]: 0,
  [bookingKeys.kids]: 0,
  [bookingKeys.areaId]: -1,
  [bookingKeys.sessionId]: -1,
  [bookingKeys.sessionLabel]: ALL_SESSIONS_LABEL,
  [bookingKeys.availabilitySuggestion]: null,
  [bookingKeys.preferredTime]: -1,
  [bookingKeys.preferredAreaId]: -1,

  [bookingKeys.firstName]: null,
  [bookingKeys.lastName]: null,
  [bookingKeys.mobile]: null,
  [bookingKeys.email]: null,
  [bookingKeys.specialRequirements]: null,

  [bookingKeys.cardName]: null,
  [bookingKeys.paymentMethodId]: null,
  [bookingKeys.paymentMethodName]: null,
  [bookingKeys.setupIntentId]: null,

  [bookingKeys.initialRestId]: null,
  [bookingKeys.heldBookingId]: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CLEAR_BOOKING: {
      return initialState;
    }

    case type.EDIT_BOOKING: {
      return { ...state, step: bookingSteps.availability, editBooking: true };
    }

    case type.EDIT_WAITLIST: {
      return { ...state, step: bookingSteps.availability, editWaitlist: true };
    }

    case type.UPDATE_BOOKING: {
      const { key, value } = action.payload;

      let newState = { ...state, [key]: value };

      // reset sessionId to -1 on an area or date change as areas and dates can have various different sessions
      if ([bookingKeys.areaId, bookingKeys.date].includes(key)) {
        newState = {
          ...newState,
          [bookingKeys.sessionId]: -1,
          [bookingKeys.sessionLabel]: ALL_SESSIONS_LABEL,
        };
      }

      // changes to the area, guest size or date should remove the current available suggestion selected
      if ([bookingKeys.areaId, bookingKeys.guests, bookingKeys.date].includes(key)) {
        newState = { ...newState, [bookingKeys.availabilitySuggestion]: null };
      }

      return newState;
    }

    case type.BATCH_UPDATE_BOOKING: {
      const newState = { ...state, ...action.payload };
      return newState;
    }

    case type.UPDATE_BOOKING_STEP: {
      return { ...state, step: action.payload };
    }

    // note: restaurantForSlug is called for just the initial venue, let's store it for later
    case type.RESTAURANT_FOR_SLUG_SUCCESS: {
      return { ...state, [bookingKeys.initialRestId]: action.payload.data.objectId };
    }

    case type.HOLD_OBEE_BOOKING_SUCCESS: {
      return {
        ...state,
        [bookingKeys.heldBookingId]: action.payload.data.id,
        [bookingKeys.areaId]: action.payload.data.areaId,
        // Holding can return a different table, so be ready to override the previous availability suggestion
        [bookingKeys.availabilitySuggestion]: {
          ...state[bookingKeys.availabilitySuggestion],
          table: {
            ...state[bookingKeys.availabilitySuggestion].table,
            areaId: action.payload.data.areaId,
            id: null, // We don't get this back so unset it
            tableName: null,
            tableNumber: action.payload.data.tableNumber,
          },
        },
      };
    }

    case type.HOLD_OBEE_BOOKING_FAILURE: {
      // On failure, there was no valid table, so back to first page and re-fetch the suggestions
      return {
        ...state,
        [bookingKeys.heldBookingId]: null,
        // [bookingKeys.areaId]: null,
        step: 0,
        // Holding can return a different table, so be ready to override the previous availability suggestion
        [bookingKeys.availabilitySuggestion]: null,
      };
    }

    default: {
      return state;
    }
  }
};
