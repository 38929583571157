import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { PageLayout } from '../../layouts/PageLayout';
import { WidgetLayout } from '../../layouts/WidgetLayout';
import { devLog } from '../../utils/devLog';

const ErrorDisplay = ({ error }) => {
  const rollbar = useRollbar();
  rollbar.error(error);

  devLog('error', 'Oh No!', error);

  return (
    <PageLayout>
      <WidgetLayout>
        <Spacer direction='vertical' gap='m' centered>
          {/* icon */}
          <Typography centered variant='h2'>
            Whoops, something went wrong.
          </Typography>
          <Typography centered>
            Please either refresh the page or contact the restaurant if there is an issue.
          </Typography>
        </Spacer>
      </WidgetLayout>
    </PageLayout>
  );
};

ErrorDisplay.propTypes = {
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
};

export default ErrorDisplay;
