import { sortByProperty } from '../../utils/helpers';
import * as type from '../actions/types';

const initialState = {
  data: [],
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_OBEE_AVAILABILITY_SUGGESTIONS_PENDING: {
      return {
        ...initialState,
        pending: true,
      };
    }

    case type.FETCH_OBEE_AVAILABILITY_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.map((item) => ({
          ...item,
          tables: (item?.tables || [])
            .filter((table) => !!table)
            .sort((a, b) => sortByProperty(a, b, 'priority')),
        })),
        pending: false,
        success: true,
      };
    }

    case type.FETCH_OBEE_AVAILABILITY_SUGGESTIONS_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    case type.CLEAR_BOOKING: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
