export const OBGuest = `
	id
	email
	mobile
`;

export const OBBooking = `
	createdAt
	id
	areaId
	date
	time
	size
	name
	customerComments
	status
	tableNumber
	duration
	highchairs
	kids
	paymentCustomerId
	paymentMethodId
	paymentMethodName
	guests {
		${OBGuest}
	}
	bookingCutOffs
	requiresCC
`;

export const OBSession = `
    id
	areaId
	label
	startTime
	endTime
	isPublic
	day
	date
	sittingTimes
`;

export const OBArea = `
    id
	name
	duration
	capacity
	priority
	minGroup
	maxGroup
	cutoffMode
	cutoffValue
	sessions {
		${OBSession}
	}
`;

export const OBTable = `
    id
	areaId
	tableNumber
	tableName
	min
	max
	priority
`;

export const OBAvailabilitySuggestion = `
	restId
  time
	table {
        ${OBTable}
    }
	tables {
    	${OBTable}
  	}
	reason
`;

export const Closure = `
	restId
	startDate
	endDate
	startTime
	endTime
`;

export const Restaurant = `
	objectId
	name
	address
	phone
	email
	latitude
	longitude
	slug
	linkedVenues {
		objectId
	}
	premium
	bookingWidgetSettings
	daysInAdvance
  	timezone
  	googleTagManager
	waitlist
	terms
	areas {
		${OBArea}
	}
`;

// TODO sync with the restaurant from the obee call
// TODO use the favicon somewhere
export const EatClubRestaurant = `
  	objectId
	name
	address
	phone
	email
	latitude
	longitude
	slug
	linkedVenues {
		objectId
	}
	premium
	bookingWidgetSettings
	daysInAdvance
	timezone
	googleTagManager
	waitlist
	ccCapture
	ccNoShowFee
	ccPaymentPolicy
	ccWaitlistShow
	ccWaitlistPolicy
	terms
	areas {
		${OBArea}
	}
	areaPhotos
	showHighchairs
	favicon
	groupSizeMessage
	stepperColor
	closures {
		${Closure}
	}
`;

export const Integration = `
	id
	data
	active
`;

export const PaymentSettings = `
  id
  areaId
  date
  dateMax
  day
  timeOption
  time
  timeMax
  sizeOption
  size
  sizeMax
  source
  mode
  noShowAmount
  noShowOption
  depositAmount
  depositOption
  fullAmount
  fullOption
  currency
  includeGST
  title
  shortDescription
  policyUrl
  policyText
  staffPayRequired
  usedBy
  priority
  deleted
  publishableKey
  pinMode
`;
