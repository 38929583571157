import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeMessageAction } from '../../data/actions/messagesAction';
import { messagesPropTypes } from '../../data/models/Messages';
import useStyles from './ToastStyles';

/**
 * Show a list of messages from a queue in a snackbar at the bottom
 */
const Toast = ({ messages, removeMessage }) => {
  const rollbar = useRollbar();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);

  useEffect(() => {
    if (messages.length > 0 && !messageInfo) {
      // log errors to rollbar
      if (messages[0].severity === 'error') {
        const { context, model, arg } = messages[0];
        rollbar.error(context, model, arg);
      }

      setMessageInfo({ ...messages[0] });
      removeMessage(messages[0].id);
      setOpen(true);
    } else if (messages.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [messages, messageInfo, open]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Snackbar
      TransitionProps={{ onExited: handleExited }}
      autoHideDuration={messageInfo && messageInfo.severity !== 'error' ? 6000 : undefined}
      className={classes.snackbar}
      key={messageInfo ? messageInfo.id : undefined}
      onClose={handleClose}
      open={open}
      style={{ width: 'unset', padding: 0 }}
    >
      <Alert onClose={handleClose} severity={messageInfo ? messageInfo.severity : undefined}>
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  messages: messagesPropTypes.isRequired,
  removeMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeMessage: removeMessageAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
