import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '86px',
    width: '72px',
    fontSize: '16px',
    transition: 'background 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  heading: {
    fontWeight: theme.fontWeights.medium,
    letterSpacing: '1.2px',
  },
  month: {
    fontWeight: theme.fontWeights.medium,
    letterSpacing: '1.2px',
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  fullPill: {
    background: theme.colors.peach,
    borderRadius: '100px',
    bottom: '-8px',
    color: theme.colors.mainTextColour,
    fontWeight: theme.fontWeights.medium,
    height: '20px',
    letterSpacing: '1.2px',
    padding: '0 4px',
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 1,
  },
  button: ({ disabled, isSelected }) => ({
    border: isSelected && `1px solid ${theme.colors.activeOutlineColour} !important`,
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    opacity: disabled && 0.38,
    overflow: 'visible',
    padding: 0,
    pointerEvents: isSelected ? 'none' : 'auto',
  }),
  todayPill: ({ disabled, isSelected }) => ({
    border: `1px solid ${isSelected || disabled ? 'currentColor' : theme.colors.outlineColour}`,
    borderRadius: '100px',
    letterSpacing: 'unset',
    padding: '0 8px',
  }),
}));

export default useStyles;
