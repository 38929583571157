import * as type from '../actions/types';
import { getData } from '../models/obeeMultiVenueAvailabilitySuggestions';

const initialState = {
  data: [],
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // clear multi venue suggestions on parent venue suggestions fetch
    case type.CLEAR_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS: {
      return initialState;
    }

    case type.FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_PENDING: {
      return {
        ...initialState,
        pending: true,
      };
    }

    case type.FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        data: getData(action.payload.data),
        pending: false,
        success: true,
      };
    }

    case type.FETCH_OBEE_MULTI_VENUE_AVAILABILITY_SUGGESTIONS_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    case type.CLEAR_BOOKING: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
