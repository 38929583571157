import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: ({ style, active, showActiveBorder }) => ({
    borderRadius: '6px',
    outline: active && showActiveBorder ? `1px solid ${theme.colors.black}` : '',
    textTransform: 'unset',
    ...theme.typography.p,
    ...style,
  }),
}));

export default useStyles;
