import { graphQLQuery } from '../api/ApiClient';
import { bookingTerms } from '../graphql/queries';
import { makeApiAction } from './shared/helpers';

export const fetchBookingTermsAction = (areaId, date, time, size) => async (dispatch) => {
  const request = (variables) => graphQLQuery(bookingTerms, variables);
  makeApiAction(
    'FETCH_BOOKING_TERMS',
    request,
    { areaId, date, time, size },
    'bookingTerms',
    null,
    dispatch,
    null,
    null,
    false,
    true,
  );
};
