import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from '../../../../components/Alert';
import { Button } from '../../../../components/Button';
import { Typography } from '../../../../components/Typography';
import { bookingPropTypes } from '../../../../data/models/Booking';
import { isRestaurantClosedForDate, restaurantPropTypes } from '../../../../data/models/Restaurant';
import { getMinMaxGroupSize } from '../../../../data/models/obeeAreas';
import { obeeAvailabilitySuggestionsPropTypes } from '../../../../data/models/obeeAvailabilitySuggestions';
import { formatDateToMoment, minutesToTimeString, pluralise } from '../../../../utils/helpers';

const UnavailabilityCard = ({
  booking,
  obeeAvailabilitySuggestions,
  restaurant,
  fetchAvailabilitySuggestions,
}) => {
  const theme = useTheme();

  const { minGroup, maxGroup } = getMinMaxGroupSize(
    restaurant.data.areas,
    booking?.preferredAreaId,
  );

  // Error state
  if (obeeAvailabilitySuggestions.error) {
    return (
      <Alert heading='An error occurred retrieving the available times'>
        <Button
          onClick={fetchAvailabilitySuggestions}
          style={{ width: 'fit-content' }}
          variant='outlined'
        >
          <Typography style={{ fontWeight: theme.fontWeights.regular }}>Try again</Typography>
        </Button>
      </Alert>
    );
  }

  if (
    isRestaurantClosedForDate(
      formatDateToMoment(booking.date),
      restaurant.data.areas.flatMap((area) => area.sessions),
      restaurant.data.closures,
      restaurant.data.areas,
    )
  ) {
    return <Alert context='warning' heading='Venue closed' />;
  }

  // todo: check that minGroup will ever be greater than 1
  if (booking.guests < minGroup) {
    return (
      <Alert context='warning' heading='Group booking availability'>
        <Typography>
          {/* Todo: this message will be a setting */}
          {/* Please call us for bookings under {minGroup} guests on {restaurant.data.phone} */}
          Please contact us for bookings under {minGroup} guests
        </Typography>
      </Alert>
    );
  }

  if (booking.guests > maxGroup) {
    return (
      <Alert context='warning' heading='Group booking availability'>
        <Typography>
          {(restaurant.data?.showCustomMessages &&
            restaurant.data?.showMaxGroupMessage &&
            restaurant.data?.maxGroupMessage) ||
            `Please contact us for bookings over ${maxGroup} guests`}
        </Typography>
      </Alert>
    );
  }

  // No availability for a specific reason (cutoff is the only reason returned now)
  if (obeeAvailabilitySuggestions?.data?.[0]?.reason) {
    const hasPhoneCutoffDetails =
      restaurant.data?.cutoffContactType === 'phone' ||
      restaurant.data?.cutoffContactType === 'email_and_phone';
    const hasEmailCutoffDetails =
      restaurant.data?.cutoffContactType === 'email' ||
      restaurant.data?.cutoffContactType === 'email_and_phone';

    return (
      <Alert context='warning' heading='No available times found'>
        <Typography>{obeeAvailabilitySuggestions?.data?.[0]?.reason}</Typography>

        {/* Show contact info */}
        {/* Ridiculous logic to handle cases where a venue didn't set their phone or email */}
        {restaurant.data?.showVenueInfoOnCutoff &&
          (restaurant.data?.phone || restaurant.data?.email) && (
            <Typography>
              Please{' '}
              {hasPhoneCutoffDetails &&
                restaurant.data?.phone &&
                `contact us on ${restaurant.data?.phone}`}
              {restaurant.data?.cutoffContactType === 'email_and_phone' &&
                hasEmailCutoffDetails &&
                hasPhoneCutoffDetails &&
                restaurant.data?.phone &&
                restaurant.data?.email &&
                ' or '}
              {hasEmailCutoffDetails &&
                restaurant.data?.email &&
                `email us at ${restaurant.data?.email}`}
            </Typography>
          )}
      </Alert>
    );
  }

  // Success with no suggestions
  return (
    <>
      <Alert
        context='warning'
        heading={`No availability for ${booking.guests} ${pluralise('guest', booking.guests)} ${
          booking.preferredTime !== -1 ? ` at ${minutesToTimeString(booking.preferredTime)}` : ''
        }
            ${
              booking?.preferredAreaId !== -1
                ? ` for ${
                    restaurant.data.areas.find((area) => area?.id === booking?.preferredAreaId)
                      ?.name
                  }`
                : ''
            }`}
        // {
        //   availableSuggestionsForSession.length === 0 && (
        //     <Spacer gap='s' direction='vertical'>
        //       {/* todo: is this a widget setting? */}
        //       {/* <Typography>
        //         Call us on <a href={`tel:${restaurant.data.phone}`}>{restaurant.data.phone}</a>{' '}
        //         to check availability at the bar.
        //       </Typography> */}
        //     </Spacer>
        //   )
        // }
      >
        {booking?.preferredAreaId !== -1 && booking.preferredTime !== -1
          ? (restaurant.data?.showCustomMessages && restaurant.data?.showNoAvailabilityTimeMessage
              ? restaurant.data?.noAvailabilityTimeMessage
              : null) || 'Select an alternate time.'
          : (restaurant.data?.showCustomMessages && restaurant.data?.showNoAvailabilityDayMessage
              ? restaurant.data?.noAvailabilityDayMessage
              : null) || 'Select another day to try again.'}
      </Alert>
    </>
  );
};

UnavailabilityCard.propTypes = {
  booking: bookingPropTypes.isRequired,
  obeeAvailabilitySuggestions: obeeAvailabilitySuggestionsPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  fetchAvailabilitySuggestions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeAvailabilitySuggestions: state.obeeAvailabilitySuggestions,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnavailabilityCard);
