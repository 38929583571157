import { useRollbar } from '@rollbar/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isObee } from '../../utils/constants';
import { safeParseWithDefault } from '../../utils/stringHelpers';

/**
 * This is a temporary solution to migrate accounts away from the legacy widget
 */
export const LegacyRedirect = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const preventRedirect = params.get('skipRedirect') === 'true';

  const [hasVerified, setHasVerified] = useState(preventRedirect);
  const rollbar = useRollbar();

  useEffect(() => {
    if (!isObee && window.location.origin !== process.env.REACT_APP_LEGACY_URL) {
      // this might be a legacy venue
      fetch(process.env.REACT_APP_LEGACY_VENUES_URL)
        .then((response) => response.text())
        .then((data) => {
          const slug = window.location.pathname.slice(1);

          const legacyVenues = safeParseWithDefault(data, []);
          if (!preventRedirect && legacyVenues.includes(slug)) {
            window.location.href = `${process.env.REACT_APP_LEGACY_URL}/${slug}`;
            // note: we don't need to set hasVerified to true here, we're rerouting
          } else {
            setHasVerified(true);
          }
        })
        .catch((error) => {
          rollbar.error(error);
          setHasVerified(true);
        });
    }
  }, []);

  if (isObee || hasVerified) {
    return <>{children}</>;
  }

  return <></>;
};

LegacyRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};
