import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { getLogo, restaurantPropTypes } from '../../data/models/Restaurant';
import { isObee } from '../../utils/constants';
import { useIsTablet } from '../../utils/hooks';
import useStyles from './PageLayoutStyles';
import { RestaurantDetails } from './RestaurantDetails';

const PageLayout = ({ children, restaurant }) => {
  const params = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const isTablet = useIsTablet();
  const theme = useTheme();

  const hideHeader = params.get('header') === 'hidden';

  const isLoading = restaurant.pending;

  const PoweredBy = () => {
    return (
      <Spacer
        direction='vertical'
        gap='m'
        style={{
          alignItems: restaurant.data.providerLogoAlignment === 'right' && 'flex-end',
          margin: '12px',
        }}
      >
        <Typography
          variant='small'
          style={{ color: theme.colors.secondaryTextColour ?? theme.colors.nimbus }}
        >
          Powered by
        </Typography>
        {getLogo(isObee, restaurant.data.providerLogoStyle)}
      </Spacer>
    );
  };

  return (
    <Box className={classes.container}>
      {!hideHeader && (
        <>
          <RestaurantDetails />
          {restaurant.data.navigationShowUnderline && (
            <Divider
              color={restaurant.data.navigationUnderlineColour}
              style={{
                borderWidth: `${restaurant.data.navigationUnderlineHeight}px`,
                height: 'unset',
                margin: 'auto',
                width: 'calc(100% - 24px)',
              }}
            />
          )}
        </>
      )}

      <Spacer direction='vertical' className={classes.content} gap={0}>
        <>
          {children}
          {!isLoading && restaurant.data.showProviderLogo && isTablet && <PoweredBy />}
        </>
      </Spacer>

      {!isLoading && restaurant.data.showProviderLogo && !isTablet && (
        <Box
          style={{
            position: 'fixed',
            bottom: '20px',
            left: restaurant.data.providerLogoAlignment === 'left' && '20px',
            right: restaurant.data.providerLogoAlignment === 'right' && '20px',
          }}
        >
          <PoweredBy />
        </Box>
      )}
    </Box>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
