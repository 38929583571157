import { API } from 'aws-amplify';
import { getAppVersion } from '../../utils/helpers';
import store from '../store';

/**
 * Create a graphQL query
 * @param query
 * @param variables
 * @param addRestIdToVariables
 * @returns {Promise<T>}
 */
export const graphQLQuery = (query, variables, addRestIdToVariables = false) => {
  const state = store.getState();
  const restId = state.restaurant.data?.objectId;

  let data = variables;
  if (addRestIdToVariables) {
    data = { ...variables, restId };
  }

  return API.graphql(
    {
      query,
      variables: data,
    },
    {
      'rest-id': restId,
      'app-version': getAppVersion(),
    },
  );
};
