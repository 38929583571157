import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    rowGap: '12px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
