import { Box, Popover } from '@eatclub-apps/ec-component-library';
import { Divider, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as CloseMarkSVG } from '../../../../assets/icons/close_mark.svg';
import { ReactComponent as IconEmail } from '../../../../assets/icons/email.svg';
import { ReactComponent as IconPhone } from '../../../../assets/icons/phone.svg';
import { Button } from '../../../../components/Button';
import { IconButton } from '../../../../components/IconButton';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import { isNil } from '../../../../utils/helpers';
import HelpfulInfo from './HelpfulInfo';

const FAQsPopover = ({ anchorEl, close, restaurant }) => {
  const theme = useTheme();
  // const [hoursAnchorEl, setHoursAnchorEl] = useState(null);

  const getVenuePhoneBtn = () =>
    restaurant.data.phone && (
      <Button href={`tel:${restaurant.data.phone}`} fullWidth color='primary'>
        <Spacer style={{ marginRight: 'auto' }}>
          <IconPhone />
          <Typography>Call</Typography>
        </Spacer>
      </Button>
    );

  const getVenueEmailBtn = () =>
    restaurant.data.email && (
      <Button href={`mailto:${restaurant.data.email}`} fullWidth color='primary'>
        <Spacer style={{ marginRight: 'auto' }}>
          <IconEmail />
          <Typography>Email</Typography>
        </Spacer>
      </Button>
    );

  const getDetail = (label, value) => {
    if (value) {
      return (
        <>
          <Typography style={{ fontWeight: theme.fontWeights.medium }}>{label}</Typography>
          <Typography style={{ whiteSpace: 'pre-line' }}>{value}</Typography>
        </>
      );
    }

    return null;
  };

  // const getHourDetail = (label, session) => {
  //   let value = 'Closed';
  //
  //   if (session.isOpen) {
  //     value = `${minutesToTimeString(session.startTime)} - ${minutesToTimeString(session.endTime)}`;
  //   }
  //
  //   return (
  //     <Fragment key={label}>
  //       <Typography>{label}</Typography>
  //       <Typography style={{ whiteSpace: 'pre-line' }}>{value}</Typography>
  //     </Fragment>
  //   );
  // };
  //
  // const getHourLabel = (isOpen, todaySession, nextAvailableSession) => {
  //   if (isOpen) {
  //     return `Closes ${minutesToTimeString(todaySession.endTime)}`;
  //   }
  //
  //   if (nextAvailableSession) {
  //     return `Opens ${minutesToTimeString(nextAvailableSession.startTime)} ${
  //       nextAvailableSession.day
  //     }`;
  //   }
  //
  //   return 'Opening hours'; // fallback if there are no sessions
  // };

  // const getHours = () => {
  //   const daySessions = getDaySessions(restaurant.data.areas);
  //
  //   const now = moment();
  //   const todaySession = daySessions.find((daySession) => daySession.day === now.format('ddd'));
  //
  //   const nowAsInt = formatIntFromTime(now);
  //
  //   // check if the venue is currently open
  //   const isOpen = isBetween(nowAsInt, todaySession?.startTime, todaySession?.endTime, true);
  //
  //   let nextAvailableSession;
  //   if (!isOpen) {
  //     // check if the venue will be open today
  //     if (todaySession.startTime > nowAsInt) {
  //       nextAvailableSession = todaySession;
  //     } else {
  //       // let's find the next available session
  //       const nextSessions = range(1, 6).map((num) => {
  //         const day = moment().add(num, 'day').format('ddd');
  //         return daySessions.find((daySession) => daySession.day === day);
  //       });
  //
  //       nextAvailableSession = nextSessions.find((session) => session.isOpen);
  //     }
  //   }
  //
  //   return (
  //     <>
  //       <Typography style={{ fontWeight: theme.fontWeights.medium }}>Hours</Typography>
  //
  //       <ConditionalWrapper
  //         condition={restaurant.pending}
  //         wrapper={(wrapper) => <Skeleton>{wrapper}</Skeleton>}
  //       >
  //         <Spacer>
  //           <Typography
  //             style={{
  //               color: isOpen ? theme.colors.roquette : theme.colors.redOrange,
  //               fontWeight: theme.fontWeights.medium,
  //             }}
  //           >
  //             {isOpen ? 'Open' : 'Closed'}
  //           </Typography>
  //           <Typography variant='small'>·</Typography>
  //
  //           <Button
  //             active={!isNil(hoursAnchorEl)}
  //             onClick={(e) => setHoursAnchorEl(e.currentTarget)}
  //             showArrowDown
  //             style={{
  //               fontWeight: theme.fontWeights.regular,
  //               margin: '-12px 0',
  //               color: theme.colors.charcoal,
  //             }}
  //             variant='text'
  //           >
  //             {getHourLabel(isOpen, todaySession, nextAvailableSession)}
  //           </Button>
  //         </Spacer>
  //       </ConditionalWrapper>
  //
  //       <Popover
  //         anchorEl={hoursAnchorEl}
  //         isOpen={!isNil(hoursAnchorEl)}
  //         onClose={() => setHoursAnchorEl(null)}
  //         innerStyle={{
  //           borderRadius: '8px',
  //           marginTop: '10px',
  //           padding: '0',
  //           position: 'relative',
  //           width: 'fit-content',
  //         }}
  //         anchorOrigin={{
  //           vertical: 'bottom',
  //           horizontal: 'right',
  //         }}
  //         transformOrigin={{
  //           vertical: 'top',
  //           horizontal: 'right',
  //         }}
  //       >
  //         <Spacer direction='vertical' gap='m' style={{ padding: '20px' }}>
  //           <Box
  //             style={{
  //               alignItems: 'baseline',
  //               display: 'grid',
  //               gap: '16px',
  //               gridTemplateColumns: 'auto auto',
  //             }}
  //           >
  //             {daySessions.map((daySession) => getHourDetail(daySession.dayLong, daySession))}
  //           </Box>
  //         </Spacer>
  //       </Popover>
  //     </>
  //   );
  // };

  return (
    <Popover
      isOpen={!isNil(anchorEl)}
      onClose={close}
      anchorEl={anchorEl}
      innerStyle={{
        borderRadius: '8px',
        marginTop: '10px',
        padding: 0,
        position: 'relative',
        width: 'fit-content',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Spacer
        direction='vertical'
        gap='m'
        style={{ maxWidth: '400px', padding: '12px', paddingBottom: '50px' }}
      >
        <Spacer style={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={close} style={{ color: theme.colors.charcoal }}>
            <CloseMarkSVG />
          </IconButton>
        </Spacer>

        {restaurant.data.venueMessage && (
          <>
            <Typography style={{ color: theme.colors.secondaryTextColour }}>
              <div dangerouslySetInnerHTML={{ __html: restaurant.data.venueMessage }} />
            </Typography>

            <Divider />
          </>
        )}

        {restaurant.data?.displayHelpfulInfo && (
          <>
            <HelpfulInfo />
            <Divider />
          </>
        )}

        {(restaurant.data.phone || restaurant.data.email) && (
          <>
            <Box style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr' }}>
              {getVenuePhoneBtn()}
              {getVenueEmailBtn()}
            </Box>
            <Divider />
          </>
        )}

        <Box
          style={{
            alignItems: 'baseline',
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: 'auto auto',
          }}
        >
          {/* {getHours()} */}
          {getDetail('Address', restaurant.data.address)}
          {getDetail('Phone', restaurant.data.phone)}
          {getDetail('Email', restaurant.data.email)}
        </Box>
      </Spacer>
    </Popover>
  );
};

FAQsPopover.defaultProps = {
  anchorEl: null,
};

FAQsPopover.propTypes = {
  anchorEl: PropTypes.shape({}),
  close: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FAQsPopover);
