import PropTypes from 'prop-types';
import { obeeTablePropTypes } from './obeeAvailabilitySuggestions';

export const bookingPropTypes = PropTypes.shape({
  areaId: PropTypes.number,
  availabilitySuggestion: PropTypes.shape({
    restId: PropTypes.string,
    time: PropTypes.number,
    table: obeeTablePropTypes,
    reason: PropTypes.string, // If no availability, this will be why
  }),
  cardName: PropTypes.string,
  date: PropTypes.string,
  editBooking: PropTypes.bool,
  editWaitlist: PropTypes.bool,
  email: PropTypes.string,
  fetchingExisting: PropTypes.bool,
  firstName: PropTypes.string,
  guests: PropTypes.number,
  highchairs: PropTypes.number,
  kids: PropTypes.number, // How many goats are on the booking
  lastName: PropTypes.string,
  mobile: PropTypes.string,
  initialRestId: PropTypes.string, // the initial venue we're making a booking for
  paymentMethodName: PropTypes.string,
  preferredTime: PropTypes.number,
  preferredAreaId: PropTypes.number,
  sessionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  specialRequirements: PropTypes.string,
  step: PropTypes.number,
  waitlist: PropTypes.bool,
  heldBookingId: PropTypes.number,
});

export const bookingKeys = {
  areaId: 'areaId',
  availabilitySuggestion: 'availabilitySuggestion',
  cardName: 'cardName',
  date: 'date',
  email: 'email',
  fetchingExisting: 'fetchingExisting',
  firstName: 'firstName',
  guests: 'guests',
  highchairs: 'highchairs',
  kids: 'kids',
  lastName: 'lastName',
  mobile: 'mobile',
  initialRestId: 'initialRestId',
  paymentMethodId: 'paymentMethodId',
  paymentMethodName: 'paymentMethodName',
  preferredTime: 'preferredTime',
  preferredAreaId: 'preferredAreaId',
  sessionId: 'sessionId',
  sessionLabel: 'sessionLabel',
  setupIntentId: 'setupIntentId',
  specialRequirements: 'specialRequirements',
  cardToken: 'cardToken',
  paymentSettingId: 'paymentSettingId',
  ccMode: 'ccMode',
  duration: 'duration',
  heldBookingId: 'heldBookingId',
};

export const bookingActions = {
  cancel: 'cancel',
  confirm: 'confirm',
  create: 'create',
  edit: 'edit',
  review: 'review',
  cc: 'cc', // Edit credit card specifically
};

/**
 * Return true if booking / waitlist is in an edit state
 * @param {bookingPropTypes} booking
 * @returns {boolean}
 */
export const isEditing = (booking) => booking.editBooking || booking.editWaitlist;

export const bookingSteps = {
  availability: 0,
  details: 1,
  review: 2,
  confirm: 3,
};
