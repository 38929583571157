import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  guests: {
    whiteSpace: 'nowrap',
    minWidth: '72px',
  },
}));

export default useStyles;
