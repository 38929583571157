import { useMediaQuery, useTheme } from '@material-ui/core';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { noop } from './helpers';

/**
 * Skips triggering `fn` on the initial render (unless forced) but will call on subsequent updates to `inputs`
 * @param {() => void} fn
 * @param {[*]} inputs
 * @param {boolean} force
 */
export const useDidUpdateEffect = (fn, inputs, force = false) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current || (didMountRef.current === false && force)) {
      if (force) {
        didMountRef.current = true;
      }
      return fn();
    }
    didMountRef.current = true;
    return null;
  }, inputs);
};

/**
 * Modified from https://dev.to/rakumairu/how-to-handle-swipe-event-on-react-carousel-24ab
 *
 * Handles horizontal swipe events on touch and mouse devices
 * @param {() => void} onNext callback for next swipe
 * @param {() => void} onPrev callback for previous swipe
 * @param {number} swipeDistance the swipe distance in pixels till the callbacks are triggered
 * @param {number} swipeStart the swipe distance in pixels till we consider the swiping to begin
 * @param {number} verticalThreshold the vertical swipe distance in pixels to cancel the swipe before it begins
 * @param {boolean} lockWhenSwiping whether to disable vertical scrolling once the swiping has initiated
 * @returns {[handleSwipeStart, handleSwipeMove, handleSwipeEnd, diff]}
 */
export const useSwipe = (
  onNext = noop,
  onPrev = noop,
  swipeDistance = 25,
  swipeStart = 15,
  verticalThreshold = 25,
  lockWhenSwiping = true,
) => {
  const [initialPosition, setInitialPosition] = useState({ initialX: null, initialY: null }); // the position where the touch started

  const [diff, setDiff] = useState(0); // how far we've swiped since isSwiping was set to `true`
  const [internalDiff, setInternalDiff] = useState(0); // keep track of an internal diff to know when to set isSwiping

  const [isSwiping, setIsSwiping] = useState(false);

  const onEnd = () => {
    setInitialPosition({ initialX: null, initialY: null });
    setDiff(0);
    setInternalDiff(0);
  };

  const handleSwipeStart = (e) => {
    const x = e.touches?.[0]?.clientX || e.clientX;
    const y = e.touches?.[0]?.clientY || e.clientY;

    setInitialPosition({ initialX: x, initialY: y });
  };

  const handleSwipeEnd = () => {
    setIsSwiping(false);
    onEnd();
  };

  const handleSwipeMove = (e) => {
    const { initialX, initialY } = initialPosition;

    if (initialX === null) {
      return;
    }

    const currentX = e.touches?.[0]?.clientX || e.clientX;
    const currentY = e.touches?.[0]?.clientY || e.clientY;

    setInternalDiff(initialX - currentX);

    if (!isSwiping && Math.abs(initialY - currentY) > verticalThreshold) {
      // we've begun a touch move event but have scroll too far up or down before initiating the swipe
      handleSwipeEnd();
      return;
    }

    if (Math.abs(internalDiff) > swipeStart) {
      setIsSwiping(true);
    }

    if (!isSwiping) {
      return;
    }

    setDiff(initialX - currentX);

    if (diff > swipeDistance) {
      onNext();
      onEnd();
    }

    if (diff < -swipeDistance) {
      onPrev();
      onEnd();
    }
  };

  useEffect(() => {
    if (lockWhenSwiping) {
      if (isSwiping) {
        // disable vertical scroll during swipe
        disableBodyScroll(document);
      } else {
        // enable vertical scroll after swipe
        enableBodyScroll(document);
      }
    }

    return () => {
      // cleanup
      clearAllBodyScrollLocks();
    };
  }, [clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll, isSwiping, lockWhenSwiping]);

  return [handleSwipeStart, handleSwipeMove, handleSwipeEnd, diff];
};

export const useScrolled = (offset = 88) => {
  const [scrolled, setScrolled] = useState(false);
  const [offsetPosition, setOffsetPosition] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setOffsetPosition(Math.min(1, Math.max((window.scrollY / offset).toPrecision(2), 0)));

      if (window.scrollY > offset) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [scrolled, offsetPosition];
};

export const useIsFirstRender = () => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
};

export const useBreakpointDown = (breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint), { noSsr: true });
};

/**
 *
 * @returns `true` if the screen size is mobile
 */
export const useIsMobile = () => {
  return useBreakpointDown('xs');
};

/**
 *
 * @returns `true` if the screen size is tablet
 */
export const useIsTablet = () => {
  return useBreakpointDown('sm');
};

/**
 *
 * @returns `true` if the screen size is small device
 */
export const useIsSmallDevice = () => {
  return useMediaQuery('(max-width:375px)', { noSsr: true });
};
