import { API } from 'aws-amplify';
import { trackTiming } from '../../../utils/analytics';
import { devLog } from '../../../utils/devLog';
import { isEmpty, noop } from '../../../utils/helpers';
import store from '../../store';
import * as type from '../types';

export const handleSuccess = (action, model, message, data, variables, dispatch) => {
  devLog('success', model, data);

  if (message) {
    dispatch({
      type: type.ADD_MESSAGE,
      payload: {
        id: `${action}_${new Date().getTime()}`,
        message: `Success, ${message}`,
        severity: 'success',
      },
    });
  }

  dispatch({
    type: `${action}_SUCCESS`,
    payload: { data, meta: { variables } },
  });
};

export const handleError = (action, model, message, error, dispatch, arg = {}) => {
  devLog('error', model, error);

  dispatch({
    type: type.ADD_MESSAGE,
    payload: {
      id: `${action}_${new Date().getTime()}`,
      message: `Oh no, ${message}`,
      severity: 'error',
      context: `${error}`,
      model,
      arg,
    },
  });

  dispatch({
    type: `${action}_FAILURE`,
    payload: message,
  });
};

export const beginAction = (action, loadingId, cancel, dispatch) => {
  dispatch({
    type: type.SET_ID_LOADING,
    payload: { id: loadingId, cancel },
  });

  dispatch({
    type: `${action}_PENDING`,
  });
};

export const endAction = (loadingId, dispatch) => {
  dispatch({
    type: type.REMOVE_ID_LOADING,
    payload: loadingId,
  });
};

export const cancelPendingApiAction = (action, now) => {
  const state = store?.getState();
  const { loading } = state;

  loading.forEach((request) => {
    if (request.id.startsWith(action) && now > parseInt(request.id.replace(`${action}_`, ''), 10)) {
      devLog('info', 'cancelling pending request', request);

      request.cancel();
    }
  });
};

export const makeApiAction = (
  action,
  request,
  apiVariables = {},
  model,
  errorMessage,
  dispatch,
  successMessage = null,
  actionResponse = null,
  cancelPending = false,
  includeRestId = false,
  throwErrorOnNull = false,
  onSuccess = noop, // success callback
) => {
  const now = new Date().getTime();
  const loadingId = `${action}_${now}`;

  if (cancelPending) {
    cancelPendingApiAction(action, now);
  }

  const state = store.getState();
  const restId = state.restaurant.data?.objectId;

  let variables = apiVariables;
  if (includeRestId) {
    if (isEmpty(restId)) {
      devLog('error', 'makeApiAction', 'restId is undefined');
      return;
    }

    variables = { ...variables, restId };
  }

  devLog('info', `preparing makeApiAction`, { variables });

  const promise = request(variables);

  beginAction(action, loadingId, () => API.cancel(promise), dispatch);

  (async () => {
    try {
      const response = await promise;
      trackTiming(model, new Date().getTime() - now);

      if (throwErrorOnNull && isEmpty(response.data[model])) {
        devLog('error', 'makeApiAction', `${model} returned null`);
        throw new Error(`${model} returned null`);
      }

      handleSuccess(
        action,
        model,
        successMessage,
        actionResponse || response.data[model],
        variables,
        dispatch,
      );

      onSuccess();
    } catch (error) {
      if (API.isCancel(error)) {
        devLog('info', 'cancelled pending request', `${action}_${now}`);
      } else {
        devLog('info', 'error with request', `${action}_${now}`, error);
        const errorMessageToShow = errorMessage || error?.errors?.[0]?.message || error;
        handleError(action, model, errorMessageToShow, error, dispatch, variables);
      }
    } finally {
      endAction(loadingId, dispatch);
    }
  })();
};
