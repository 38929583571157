import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sessionButton: {
    whiteSpace: 'nowrap',
    borderRadius: '100px',
    border: '1px solid transparent',
  },
  selected: {
    border: `1px solid ${theme.colors.outlineColour}`,
  },
}));

export default useStyles;
