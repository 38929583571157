import PropTypes from 'prop-types';
import React from 'react';
import { variantsMapping } from './TypographyHelpers';
import { useStyles } from './TypographyStyles';

/**
 * A Typography component
 * @example <Typography>Hello!</Typography>
 */
const Typography = ({ centered, children, className, style, variant }) => {
  const classes = useStyles({ centered, variant });

  const Component = variant ? variantsMapping[variant] : 'p';

  return (
    <Component className={`${classes.container} ${className}`} style={style}>
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  centered: false,
  children: null,
  className: '',
  style: {},
  variant: 'p',
};

Typography.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default Typography;
