import PropTypes from 'prop-types';
import React from 'react';

/**
 * @author https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : <>{children}</>;

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConditionalWrapper;
