import PropTypes from 'prop-types';
import { safeParse } from '../../utils/stringHelpers';

export const integrationPropTypes = PropTypes.shape({
  id: PropTypes.string,
  active: PropTypes.bool,
  data: PropTypes.shape({
    created_date: PropTypes.string,
    fb_page_id: PropTypes.string,
    fbe_company_id: PropTypes.string,
    modified_date: PropTypes.string,
    pixel_id: PropTypes.string,
  }),
});

export const integrationsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(integrationPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
  facebookPixel: PropTypes.string,
});

/**
 * Convert the API data to usable frontend data
 * Basically this is the inverse of "convertDataForApi"
 */
export const getData = (integration) => {
  return {
    id: integration?.id,
    active: integration?.active,
    data: safeParse(integration?.data),
  };
};
