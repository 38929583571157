import { devLog } from './devLog';

/**
 * @param {string} string
 * @returns {object|undefined}
 */
export const safeParse = (string) => {
  let parsed;
  try {
    parsed = JSON.parse(string);
  } catch {
    devLog('error', 'safeParse', `unable to parse ${string}`);
    return string;
  }
  return parsed; // Could be undefined
};

/**
 * @param {string} string
 * @returns {object|undefined}
 */
export const safeParseWithDefault = (string, defaultIfInvalid) => {
  let parsed;
  try {
    parsed = JSON.parse(string);
  } catch {
    devLog('error', 'safeParse', `unable to parse ${string}`);
    return defaultIfInvalid;
  }
  return parsed; // Could be undefined
};
