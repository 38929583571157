import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: ({ alertContextStyles }) => ({
    background: `${alertContextStyles.background}${theme.colors.darkMode ? '1A' : ''}`, // Add 10% opacity in dark mode
    border: `1px solid ${alertContextStyles.borderColor}${theme.colors.darkMode ? '80' : ''}`, // 50% opacity on border
    borderRadius: '12px',
    height: 'fit-content',
    padding: '12px',
    width: 'calc(100% - 24px)',
  }),
  heading: ({ alertContextStyles }) => ({
    color: alertContextStyles.textColor,
    fontWeight: theme.fontWeights.bold,
  }),
}));

export default useStyles;
