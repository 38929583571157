import { makeStyles } from '@material-ui/core';
import { shadeHex } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  icon: {
    background: theme.colors.white,
    borderRadius: '4px',
    bottom: '8px',
    boxShadow: '0px 4px 4px rgba(206, 206, 206, 0.25)',
    padding: '8px',
    position: 'absolute',
    right: '8px',

    '&:hover': {
      background: shadeHex(theme.colors.white, -10),
    },
  },
  image: {
    alignItems: 'center',
    background: theme.colors.cloud,
    borderRadius: '6px',
    color: theme.colors.nimbus,
    display: 'flex',
    flexShrink: 0,
    height: '136px',
    justifyContent: 'center',
    objectFit: 'cover',
    overflow: 'hidden',
    position: 'relative',
    width: '164px',
  },
  thumbnail: {
    width: '100%',
  },
}));

export default useStyles;
