import { baseColors } from './base';

export const obeeColors = {
  liteNavigationUnderlineColour: baseColors.shark,
  liteIconBgColour: baseColors.obeeGreen,
  liteAvailabilityMainButtonBgColour: baseColors.obeeGreen,
  liteBackgroundValue: baseColors.paleMint,
  liteAvailabilityFieldsActiveStateColour: baseColors.white,
  premiumAvailabilityMainButtonBgColour: baseColors.charcoal,
  liteWidgetHeaderBgColor: baseColors.cloud,
};

export const fontFamily = "'Inter', 'Open Sans', 'Helvetica', 'sans-serif'";

export const obeeTypography = {
  availabilityFontFamily: fontFamily,
  generalFontFamily: fontFamily,
};
