import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconBYO } from '../../../../assets/icons/byo.svg';
import { ReactComponent as IconCake } from '../../../../assets/icons/cake.svg';
import { ReactComponent as IconChildFriendlyVenue } from '../../../../assets/icons/child_friendly_venue.svg';
import { ReactComponent as IconCorkage } from '../../../../assets/icons/corkage.svg';
import { ReactComponent as IconDisabledAccess } from '../../../../assets/icons/disabled_access.svg';
import { ReactComponent as IconDressCode } from '../../../../assets/icons/dress_code.svg';
import { ReactComponent as IconChildrensPlayArea } from '../../../../assets/icons/playground.svg';
import { Spacer } from '../../../../components/Spacer';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import {
  byoMessage,
  cakesMessage,
  childrensMenuMessage,
  corkageMessage,
  disabledAccessMessage,
  dressCodeMessage,
  playAreaMessage,
} from '../../../../utils/constants';
import HelpfulInfoItem from './HelpfulInfoItem';

const HelpfulInfo = ({ restaurant }) => {
  return (
    <Spacer direction='vertical' gap='m' style={{ fontSize: '15px' }}>
      <HelpfulInfoItem
        Icon={<IconDisabledAccess />}
        heading='Disabled access'
        description={disabledAccessMessage?.[restaurant.data?.disabledOption]}
        show={restaurant.data?.hasDisabledAccess && restaurant.data?.disabledOption}
      />

      <HelpfulInfoItem
        Icon={<IconDressCode />}
        heading='Dress code'
        description={dressCodeMessage?.[restaurant.data?.dressCodeOption]}
        show={restaurant.data?.hasDressCode && restaurant.data?.dressCodeOption}
      />

      <HelpfulInfoItem
        Icon={<IconChildFriendlyVenue />}
        heading='Child friendly venue'
        description={childrensMenuMessage?.[restaurant.data?.kidsMenuOption]}
        show={restaurant.data?.hasKidsMenu && restaurant.data?.kidsMenuOption}
      />

      <HelpfulInfoItem
        Icon={<IconChildrensPlayArea />}
        heading="Children's play area"
        description={playAreaMessage?.[restaurant.data?.playAreaOption]}
        show={restaurant.data?.hasPlayArea && restaurant.data?.playAreaOption}
      />

      <HelpfulInfoItem
        Icon={<IconBYO />}
        heading='BYO'
        description={byoMessage(restaurant.data?.byoOption)}
        show={restaurant.data?.hasBYO && restaurant.data?.byoOption}
      />

      <HelpfulInfoItem
        Icon={<IconCorkage />}
        heading='Corkage'
        description={corkageMessage(
          restaurant.data?.corkageOption,
          restaurant.data?.corkageFee,
          restaurant.data?.corkagePer,
        )}
        show={restaurant.data?.hasCorkage && restaurant.data?.corkageOption}
      />

      <HelpfulInfoItem
        Icon={<IconCake />}
        heading='Cake'
        description={cakesMessage?.[restaurant.data?.cakesOption]}
        show={restaurant.data?.hasCakes && restaurant.data?.cakesOption}
      />
    </Spacer>
  );
};

HelpfulInfo.defaultProps = {};

HelpfulInfo.propTypes = {
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HelpfulInfo);
