import { unique } from '../../utils/helpers';
import * as type from '../actions/types';
import { getWidgetSettings } from '../models/Restaurant';

const initialState = {
  data: [], // cached venues
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.RESTAURANTS_BY_IDS_PENDING: {
      return {
        ...state,
        error: '',
        pending: true,
        success: false,
      };
    }

    case type.RESTAURANTS_BY_IDS_SUCCESS: {
      return {
        ...state,
        data: unique([...state.data, ...action.payload.data], 'objectId').map(getWidgetSettings), // cache venues
        pending: false,
        success: true,
      };
    }

    case type.RESTAURANTS_BY_IDS_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    // cache the initial venue fetched
    case type.RESTAURANT_FOR_SLUG_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload.data].map(getWidgetSettings),
      };
    }

    default: {
      return state;
    }
  }
};
