import * as type from './types';

export const createObeeBooking = /* GraphQL */ `
  mutation CreateObeeBooking($restId: String!, $booking: OBBookingInput!) {
    createObeeBooking(restId: $restId, booking: $booking) {
      ${type.OBBooking}
    }
  }
`;

export const updateObeeBooking = /* GraphQL */ `
  mutation UpdateObeeBooking($restId: String!, $bookingId: String!, $booking: OBBookingInput!) {
    updateObeeBooking(restId: $restId, bookingId: $bookingId, booking: $booking) {
      ${type.OBBooking}
    }
  }
`;

export const cancelObeeBooking = /* GraphQL */ `
  mutation CancelObeeBooking($restId: String!, $bookingId: String!) {
    cancelObeeBooking(restId: $restId, bookingId: $bookingId) {
      ${type.OBBooking}
    }
  }
`;

export const confirmObeeBooking = /* GraphQL */ `
  mutation ConfirmObeeBooking($restId: String!, $bookingId: String!) {
    confirmObeeBooking(restId: $restId, bookingId: $bookingId) {
      ${type.OBBooking}
    }
  }
`;

export const createPaymentSetupIntent = /* GraphQL */ `
  mutation CreatePaymentSetupIntent(
    $name: String!
    $phone: String!
    $email: String
    $userId: String
    $stripeId: String
  ) {
    createPaymentSetupIntent(
      name: $name
      phone: $phone
      email: $email
      userId: $userId
      stripeId: $stripeId
    )
  }
`;

export const holdBooking = /* GraphQL */ `
  mutation HoldBooking($restId: String!, $booking: OBBookingInput!) {
    holdBooking(restId: $restId, booking: $booking) {
      ${type.OBBooking}
    }
  }
`;

export const freeHeldBooking = /* GraphQL */ `
  mutation FreeHeldBooking($restId: String!, $bookingId: String!) {
    freeHeldBooking(restId: $restId, bookingId: $bookingId)
  }
`;
