import { TextField as MuiTextField, styled } from '@material-ui/core';
import React from 'react';
import useStyles from './TextFieldStyles';

const TextField = styled(({ InputProps, ...props }) => {
  const classes = useStyles();
  return (
    <MuiTextField
      fullWidth
      variant='filled'
      InputProps={{ classes: { input: classes.input }, ...InputProps }}
      {...props}
    />
  );
})(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: theme.colors.darkMode
      ? theme.colors.availabilityBackgroundBoxBgColour
      : theme.colors.white,
    borderRadius: 6,
    outline: `1px solid ${theme.colors.outlineColour}`,
    overflow: 'hidden',
    transition: theme.transitions.create(['outline'], { duration: 250 }),
  },
}));

export default TextField;
