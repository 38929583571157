import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as EatClubIcon } from '../../assets/icons/eatclub_logo.svg';
import { ReactComponent as EatClubIconColored } from '../../assets/icons/eatclub_logo_colored.svg';
import { ReactComponent as ObeeIcon } from '../../assets/icons/obee_logo.svg';
import { ReactComponent as ObeeBlackIcon } from '../../assets/icons/obee_logo_black.svg';
import { ReactComponent as ObeeWhiteIcon } from '../../assets/icons/obee_logo_white.svg';
import { colors, typography } from '../../theme';
import { momentDaysOfWeek } from '../../utils/constants';
import { devLog } from '../../utils/devLog';
import {
  getContrastTextColor,
  isEmpty,
  isNil,
  nullIfNegative,
  onlyUnique,
  trim,
} from '../../utils/helpers';
import { safeParse } from '../../utils/stringHelpers';
import { obeeAreaPropTypes } from './obeeAreas';

const restaurantPropType = PropTypes.shape({
  objectId: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  slug: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  premium: PropTypes.bool,
  theme: PropTypes.string,
  daysInAdvance: PropTypes.number,
  areaPhotos: PropTypes.objectOf(
    PropTypes.shape({
      imageLink: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  waitlist: PropTypes.bool,
  ccCapture: PropTypes.bool,
  ccNoShowFee: PropTypes.number,
  ccPaymentPolicy: PropTypes.string,
  ccWaitlistShow: PropTypes.string,
  ccWaitlistPolicy: PropTypes.string,
  terms: PropTypes.string,
  closures: PropTypes.arrayOf(
    PropTypes.shape({
      restId: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.number,
      endTime: PropTypes.number,
    }),
  ),
  timezone: PropTypes.string,
  googleTagManager: PropTypes.string,

  // Appearance settings
  generalFontFamilies: PropTypes.string,

  navigationLogoUrl: PropTypes.string,
  navigationLogoAlignment: PropTypes.oneOf(['left', 'middle']),

  navigationShowVenueName: PropTypes.bool,
  navigationVenueNameTextColour: PropTypes.string,

  navigationBgColour: PropTypes.string,
  navigationBgColourTransparency: PropTypes.number,

  navigationShowUnderline: PropTypes.bool,
  navigationUnderlineColour: PropTypes.string,
  navigationUnderlineHeight: PropTypes.number,

  navigationShowHeaderText: PropTypes.bool,
  navigationHeaderText: PropTypes.string,
  navigationHeaderTextColour: PropTypes.string,

  navigationShowNeedHelp: PropTypes.bool,
  navigationNeedHelpButtonTextColour: PropTypes.string,
  navigationNeedHelpButtonBorderColour: PropTypes.string,

  backgroundType: PropTypes.oneOf(['solid', 'image']),
  backgroundValue: PropTypes.string,
  backgroundImageUrl: PropTypes.string,

  availabilityFontFamilies: PropTypes.string,

  availabilityMainButtonBgColour: PropTypes.string,
  availabilityMainButtonTextColour: PropTypes.string,
  availabilityMainButtonCornerRadius: PropTypes.number,

  availabilityTimeButtonBgColour: PropTypes.string,
  availabilityTimeButtonTextColour: PropTypes.string,
  availabilityTimeButtonCornerRadius: PropTypes.number, // not used

  availabilityFieldsActiveStateColour: PropTypes.string,
  availabilityFieldsTextColour: PropTypes.string,
  availabilityFieldsColourOpacity: PropTypes.number,

  availabilityBackgroundBoxBgColour: PropTypes.string,
  availabilityBackgroundBoxBorderColour: PropTypes.string,
  availabilityBackgroundBoxBorderWeight: PropTypes.number,

  showProviderLogo: PropTypes.bool,
  providerLogoStyle: PropTypes.oneOf(['coloured', 'white', 'black']),
  providerLogoAlignment: PropTypes.oneOf(['left', 'right']),

  bodyTextColour: PropTypes.string,
  bodyButtonTextColour: PropTypes.string,
  bodyButtonBorderColour: PropTypes.string,

  dateSelectorType: PropTypes.oneOf(['7_days', 'calendar', 'calendar_always']),

  // Areas settings
  areas: PropTypes.arrayOf(obeeAreaPropTypes),

  // Data collection settings
  showHighchairs: PropTypes.bool,
  showChildren: PropTypes.bool,
  showMarketingOptIn: PropTypes.bool,
  marketingOptInMessage: PropTypes.string,

  // Venue info settings
  venueMessage: PropTypes.string,
  groupSizeMessage: PropTypes.string,

  // Multi-venue settings
  suggestAvailability: PropTypes.string,
  multiVenueImageUrl: PropTypes.string,
  venueDescription: PropTypes.string,

  // Settings not fetched from the api
  iconBgColour: PropTypes.string,
  modalHeaderColour: PropTypes.string,
  widgetHeaderBgColor: PropTypes.string,
});

export const restaurantPropTypes = PropTypes.shape({
  data: restaurantPropType,
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const restaurantsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(restaurantPropType),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

/**
 * Some settings have a premium wall
 * @param {boolean} isPremium
 * @param {*} premiumSetting
 * @param {*} liteSetting the fallback if `isPremium` is false or `premiumSetting` is null/undefined
 * @returns
 */
const getPremiumSetting = (isPremium, premiumSetting, liteSetting = null) => {
  if (isPremium) {
    return premiumSetting ?? liteSetting;
  }

  return liteSetting;
};

/**
 * Parse an int from the server and allow for defaults.
 * Will always default to 0 if could not parse as int.
 *
 * @param value
 * @param defaultValue
 * @returns {number|number|number}
 */
const extractInt = (value, defaultValue = 0) => {
  // backend converts 1s and 0s to true and false...
  if (value === false) {
    return 0;
  }

  if (value === true) {
    return 1;
  }

  if (isNil(value)) {
    return defaultValue;
  }

  return parseInt(value, 10) || 0;
};

/**
 * Ensure the value being read is treated as a number and suffixes such as "px" are removed
 * @param value
 * @param suffixToRemove
 * @param defaultValue
 */
const getValueAsNumber = (value, suffixToRemove = '%', defaultValue = 0) => {
  return parseInt(`${extractInt(value, defaultValue)}`.replace(suffixToRemove, ''), 10);
};

const getAppearanceSettings = (isPremium, theme) => {
  const availabilityMainButtonBgColour = getPremiumSetting(
    isPremium,
    theme.availabilityMainButtonBgColour ?? colors.premiumAvailabilityMainButtonBgColour,
    colors.liteAvailabilityMainButtonBgColour,
  );

  return {
    // General
    generalFontFamilies: getPremiumSetting(
      isPremium,
      theme.generalFontFamilies,
      typography.generalFontFamily,
    ),

    // Navigation
    navigationLogoUrl: getPremiumSetting(isPremium, theme.navigationLogoUrl),
    navigationLogoAlignment: getPremiumSetting(isPremium, theme.navigationLogoAlignment, 'left'), // left and center

    navigationShowVenueName: getPremiumSetting(isPremium, theme.navigationShowVenueName, true),
    navigationVenueNameTextColour: getPremiumSetting(
      isPremium,
      theme.navigationVenueNameTextColour,
      colors.black,
    ),

    navigationBgColour: getPremiumSetting(isPremium, theme.navigationBgColour, 'transparent'),
    navigationBgColourTransparency: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.navigationBgColourTransparency, '%', 100),
      100,
    ),

    navigationShowUnderline: getPremiumSetting(isPremium, theme.navigationShowUnderline, true),
    navigationUnderlineColour: getPremiumSetting(
      isPremium,
      theme.navigationUnderlineColour,
      colors.liteNavigationUnderlineColour,
    ),
    navigationUnderlineHeight: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.navigationUnderlineHeight, 'px', 1),
      1,
    ),

    navigationShowHeaderText: getPremiumSetting(isPremium, theme.navigationShowHeaderText, true),
    navigationHeaderText: getPremiumSetting(isPremium, theme.navigationHeaderText, 'Reservations'),
    navigationHeaderTextColour: getPremiumSetting(isPremium, theme.navigationHeaderTextColour),

    navigationShowNeedHelp: getPremiumSetting(isPremium, theme.navigationShowNeedHelp, true),
    navigationNeedHelpButtonTextColour: getPremiumSetting(
      isPremium,
      theme.navigationNeedHelpButtonTextColour,
    ),
    navigationNeedHelpButtonBorderColour: getPremiumSetting(
      isPremium,
      theme.navigationNeedHelpButtonBorderColour,
    ),

    // Background
    backgroundType: getPremiumSetting(isPremium, theme.backgroundType, 'solid'),
    backgroundValue: getPremiumSetting(
      isPremium,
      theme.backgroundValue,
      colors.liteBackgroundValue,
    ),
    backgroundImageUrl: getPremiumSetting(isPremium, theme.backgroundImageUrl, null),

    // Availability
    availabilityFontFamilies: getPremiumSetting(
      isPremium,
      theme.availabilityFontFamilies,
      typography.availabilityFontFamily,
    ),

    availabilityMainButtonBgColour,
    availabilityMainButtonTextColour: getPremiumSetting(
      isPremium,
      theme.availabilityMainButtonTextColour ??
        getContrastTextColor(availabilityMainButtonBgColour),
    ),
    availabilityMainButtonCornerRadius: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.availabilityMainButtonCornerRadius, 'px'),
      '6px',
    ),

    availabilityFieldsActiveStateColour: getPremiumSetting(
      isPremium,
      theme.availabilityFieldsActiveStateColour,
      colors.liteAvailabilityFieldsActiveStateColour,
    ),
    availabilityFieldsColourOpacity: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.availabilityFieldsColourOpacity, '%', 100),
      100,
    ),

    availabilityBackgroundBoxBgColour: getPremiumSetting(
      isPremium,
      theme.availabilityBackgroundBoxBgColour,
      colors.white,
    ),
    availabilityBackgroundBoxBorderColour: getPremiumSetting(
      isPremium,
      theme.availabilityBackgroundBoxBorderColour,
      'transparent',
    ),
    availabilityBackgroundBoxBorderWeight: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.availabilityBackgroundBoxBorderWeight, 'px'),
      '0',
    ),

    showProviderLogo: getPremiumSetting(isPremium, theme.showProviderLogo, true),
    providerLogoStyle: getPremiumSetting(isPremium, theme.providerLogoStyle, 'coloured'),
    providerLogoAlignment: getPremiumSetting(isPremium, theme.providerLogoAlignment, 'left'),

    // Body
    bodyTextColour: getPremiumSetting(isPremium, theme.bodyTextColour),
    bodyButtonTextColour: getPremiumSetting(isPremium, theme.bodyButtonTextColour),
    bodyButtonBorderColour: getPremiumSetting(isPremium, theme.bodyButtonBorderColour),

    dateSelectorType: getPremiumSetting(isPremium, theme.dateSelectorType, 'calendar'),

    // Main text overrides
    darkTheme: getPremiumSetting(isPremium, theme.darkTheme, false),
    mainTextColour: getPremiumSetting(isPremium, theme.mainTextColour, '#313131'),
    // iconColour: getPremiumSetting(isPremium, theme.iconColour, '#313131'),
    outlineColour: getPremiumSetting(isPremium, theme.outlineColour, '#E6E6E6'),
    outlineOpacity: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.outlineOpacity, '%', 100),
      100,
    ),
    activeOutlineColour: getPremiumSetting(isPremium, theme.activeOutlineColour, '#313131'),
    secondaryTextColour: getPremiumSetting(isPremium, theme.secondaryTextColour, '#6E6E6D'),
    secondaryTextOpacity: getPremiumSetting(
      isPremium,
      getValueAsNumber(theme.secondaryTextOpacity, '%', 100),
      100,
    ),
    // availabilityFieldsActiveStateTextColour: getPremiumSetting(
    //   isPremium,
    //   theme.availabilityFieldsActiveStateTextColour ?? 'lime',
    //   '#313131',
    // ),

    showCustomMessages: getPremiumSetting(isPremium, theme.showCustomMessages, false),
    venueClosedMessage: getPremiumSetting(isPremium, theme.venueClosedMessage, null),
    showNoAvailabilityDayMessage: getPremiumSetting(
      isPremium,
      theme.showNoAvailabilityDayMessage,
      false,
    ),
    noAvailabilityDayMessage: getPremiumSetting(isPremium, theme.noAvailabilityDayMessage, null),
    showNoAvailabilityTimeMessage: getPremiumSetting(
      isPremium,
      theme.showNoAvailabilityTimeMessage,
      false,
    ),
    noAvailabilityTimeMessage: getPremiumSetting(isPremium, theme.noAvailabilityTimeMessage, null),
    showCutoffMessage: getPremiumSetting(isPremium, theme.showCutoffMessage, false),
    cutoffMessage: getPremiumSetting(isPremium, theme?.cutoffMessage, null),
    showMaxGroupMessage: getPremiumSetting(isPremium, theme?.showMaxGroupMessage, false),
    maxGroupMessage: getPremiumSetting(isPremium, theme?.maxGroupMessage, null),
    showVenueInfoOnCutoff: getPremiumSetting(isPremium, theme?.showVenueInfoOnCutoff, false),
    cutoffContactType: getPremiumSetting(isPremium, theme?.cutoffContactType, 'email'),
  };
};

const getAreasSettings = (isPremium, theme) => {
  // TODO widget settings in foh to limit this on standard
  if (!isPremium) {
    return { areaPhotos: {} };
  }

  const areas = safeParse(theme?.areas);

  if (!areas) {
    return { areaPhotos: {} };
  }

  // Fall back to the restaurant's area photos for EC Bookings
  const areaPhotosToUse = areas;

  // If not showing area images, still show the descriptions
  if (!theme?.showAreaImages) {
    Object.keys(areaPhotosToUse).forEach((areaId) => {
      areaPhotosToUse[areaId].imageLink = '';
    });
  }

  return {
    areaPhotos: getPremiumSetting(isPremium, areaPhotosToUse, {}),
  };
};

// TODO get high chairs from a single location
const getDataCollectionSettings = (isPremium, theme, restaurant) => {
  return {
    showHighchairs: restaurant?.showHighchairs || theme?.showHighChairs,
    showChildren: restaurant?.showChildren || theme?.showChildren,
    showMarketingOptIn: theme?.showMarketingOptIn,
    marketingOptInMessage: theme?.marketingOptInMessage,
  };
};

const getVenueInfoSettings = (isPremium, theme) => {
  return {
    venueMessage: getPremiumSetting(isPremium, theme?.venueMessage, null),
  };
};

const getHelpfulInfoSettings = (isPremium, theme) => {
  return {
    displayHelpfulInfo: theme?.displayHelpfulInfo,
    hasDisabledAccess: theme?.hasDisabledAccess,
    hasDressCode: theme?.hasDressCode,
    hasKidsMenu: theme?.hasKidsMenu,
    hasPlayArea: theme?.hasPlayArea,
    hasBYO: theme?.hasBYO,
    hasCorkage: theme?.hasCorkage,
    hasCakes: theme?.hasCakes,
    disabledOption: theme?.disabledOption,
    dressCodeOption: theme?.dressCodeOption,
    kidsMenuOption: theme?.kidsMenuOption,
    playAreaOption: theme?.playAreaOption,
    byoOption: theme?.byoOption,
    corkageOption: theme?.corkageOption,
    corkageFee: theme?.corkageFee,
    corkagePer: theme?.corkagePer,
    cakesOption: theme?.cakesOption,
  };
};

const getMultiVenueSettings = (isPremium, theme) => {
  // This makes it backwards compatible since it used to be a boolean
  const availabilitySetting =
    theme.suggestAvailability === true ? 'no_availability' : theme.suggestAvailability;

  return {
    multiVenueImageUrl: getPremiumSetting(isPremium, theme.multiVenueImageUrl, null),
    suggestAvailability: getPremiumSetting(isPremium, availabilitySetting, 'no'),
  };
};

/**
 * Gets widget premium and default settings
 * @param {*} restaurant
 * @returns {*} The widget premium settings if premium, and the default settings if the settings have not been set
 */
export const getWidgetSettings = (restaurant = {}) => {
  const isPremium = restaurant.premium;
  // Theme and settings now come back from the API as two different properties, so let's combine them back into 1
  const theme = {
    ...(safeParse(restaurant.bookingWidgetSettings)?.themeSettings?.themeDetails ?? {}),
    ...(safeParse(restaurant.bookingWidgetSettings)?.widgetSettings?.settingsDetails ?? {}),
  };

  devLog('info', `theme from api`, theme);

  const widgetSettings = {
    ...getAppearanceSettings(isPremium, theme),
    ...getAreasSettings(isPremium, theme),
    ...getDataCollectionSettings(isPremium, theme, restaurant),
    ...getVenueInfoSettings(isPremium, theme),
    ...getHelpfulInfoSettings(isPremium, theme),
    ...getMultiVenueSettings(isPremium, theme),
  };

  /**
   * Find whether an address is valid.
   * It's common for addresses to just be blank text with a comma if not properly set.
   * In those cases, we'd rather just have a null value for the address
   * @param address
   * @returns {boolean}
   */
  const isValidAddress = (address) => {
    const trimmedAddress = trim(address);
    return !(isEmpty(trimmedAddress) || trimmedAddress === ',');
  };

  // Sitting times are encoded in json. So we have to go deep through the areas and sessions to get to them
  const areaData = (restaurant?.areas || []).map((area) => ({
    ...area,
    sessions: (area?.sessions || []).map((session) => {
      return {
        ...session,
        sittingTimes: (session?.sittingTimes || []).map((sittingTime) => {
          const parsedSittingTime = safeParse(sittingTime);
          return {
            label: parsedSittingTime?.label,
            times: parsedSittingTime?.times,
          };
        }),
      };
    }),
  }));

  return {
    ...restaurant,
    address: isValidAddress(restaurant?.address)
      ? trim(`${restaurant?.address}`.replace('\n', ', '))
      : null,
    areas: areaData,
    venueDescription: theme.venueDescription,
    // ----------General----------
    daysInAdvance: nullIfNegative(restaurant.daysInAdvance) || 60,

    // ----------Widget settings----------
    ...widgetSettings,

    // ----------Other settings where lite and premium will differ----------
    iconBgColour: getPremiumSetting(isPremium, 'transparent', colors.liteIconBgColour),
    modalHeaderColour: getPremiumSetting(
      isPremium,
      theme.navigationBgColour,
      colors.liteBackgroundValue,
    ),
    widgetHeaderBgColor: getPremiumSetting(isPremium, colors.white, colors.liteWidgetHeaderBgColor),
  };
};

export const isRestaurantClosedForDate = (date, sessions, closures, areas) => {
  let isClosed = false;

  const openDays = sessions.map((session) => session.day).filter(onlyUnique);
  const openDates = sessions.map((session) => session.date).filter(onlyUnique);

  if (
    !openDays.includes(date.format('ddd').toLowerCase()) &&
    !openDates.includes(date.format('YYYY-MM-DD'))
  ) {
    isClosed = true;
  }

  // Whether the given date is the same date (e.g. 01/01/2024) or the same day (e.g. wednesday)
  const isSameDayOrDate = (date1, dateCompare, dayCompare) =>
    (dateCompare !== null && date1.isSame(moment(dateCompare), 'day')) ||
    (dayCompare !== null && momentDaysOfWeek[date1.day()] === dayCompare);

  // Whether the area has any normal sessions
  const areaHasSessionsForDay = (area) => {
    const sessionsForAreaForDay = area.sessions.filter(
      (session) =>
        (session.startTime !== 0 || session.endTime !== 0) &&
        isSameDayOrDate(date, session.date, session.day),
    );

    return !isEmpty(sessionsForAreaForDay);
  };

  // If an area has a single "closed event" (date with start and end times 0), then the area is closed for the day
  const areaHasCloseEvent = (area) => {
    return area.sessions.some(
      (session) =>
        date.isSame(moment(session.date), 'day') &&
        session.startTime === 0 &&
        session.endTime === 0,
    );
  };

  // Does the venue have any "default" (non-area-specific) close events?
  const hasDefaultCloseEvent = sessions.some(
    (session) =>
      session.startTime === 0 &&
      session.endTime === 0 &&
      (session.areaId === null || session.areaId <= 0) &&
      isSameDayOrDate(date, session.date, session.day),
  );

  // If every area has an event that counts as a closure, then the venue is considered closed for the day
  // Note: double negative. If any area is NOT closed, then the restaurant is open
  // Also if the area just has no sessions in general, then it's closed
  const allAreasClosed = !areas.some(
    (area) => areaHasSessionsForDay(area) && !areaHasCloseEvent(area),
  );

  // If either default is closed, or all areas are, then they're closed
  if (hasDefaultCloseEvent || allAreasClosed) {
    isClosed = true;
  }

  if (isNil(closures)) {
    return isClosed;
  }

  const hasClosure = closures.some((closure) =>
    date.isBetween(moment(closure.startDate), moment(closure.endDate), 'day', '[]'),
  );

  if (hasClosure) {
    isClosed = true;
  }

  return isClosed;
};

export const getLogo = (isObee, variant = 'coloured') => {
  switch (variant) {
    case 'black': {
      return isObee ? <ObeeBlackIcon /> : <EatClubIcon style={{ color: '#313131' }} />; // todo: need black EatClub logo
    }
    case 'white': {
      return isObee ? <ObeeWhiteIcon /> : <EatClubIcon style={{ color: 'white' }} />; // todo: need white EatClub logo
    }
    case 'coloured':
    default: {
      return isObee ? <ObeeIcon /> : <EatClubIconColored />;
    }
  }
};
