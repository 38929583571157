import { Popover } from '@eatclub-apps/ec-component-library';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../../../components/Button';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';
import { restaurantPropTypes } from '../../../../data/models/Restaurant';
import { trackEvent } from '../../../../utils/analytics';
import { useIsMobile } from '../../../../utils/hooks';
import { Calendar } from './Calendar';
import { DateScroll } from './DateScroll';

const SelectADate = ({ restaurant }) => {
  const [viewAllAnchorEl, setViewAllAnchorEl] = useState(null);
  const isMobile = useIsMobile();

  const onClose = () => setViewAllAnchorEl(null);

  if (restaurant.data.dateSelectorType === '7_days') {
    return (
      <Spacer direction='vertical' gap='m'>
        <Spacer centered style={{ justifyContent: 'space-between', minHeight: '32.5px' }} wrap>
          <Typography variant='h3'>Search availability</Typography>

          <Button
            variant='outlined'
            size='small'
            onClick={(e) => {
              trackEvent('Date', 'Viewed All Dates');
              setViewAllAnchorEl(e.currentTarget);
            }}
          >
            <Typography variant='small'>Calendar</Typography>
          </Button>
        </Spacer>

        <DateScroll />

        <Popover
          isOpen={!!viewAllAnchorEl}
          onClose={onClose}
          anchorEl={viewAllAnchorEl}
          innerStyle={{
            borderRadius: '6px',
            padding: 0,
            position: 'relative',
            marginTop: '10px',
            maxWidth: !isMobile && '540px',
            width: '100%',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Calendar onChange={onClose} />
        </Popover>
      </Spacer>
    );
  }

  return (
    <Calendar defaultView alwaysOpen={restaurant.data.dateSelectorType === 'calendar_always'} />
  );
};

SelectADate.propTypes = {
  restaurant: restaurantPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectADate);
