import React from 'react';
import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import { PageLayout } from '../../layouts/PageLayout';
import { WidgetLayout } from '../../layouts/WidgetLayout';

const CancelledBooking = () => {
  return (
    <PageLayout>
      <WidgetLayout>
        <Spacer direction='vertical' gap='m' centered>
          {/* icon */}
          <Typography centered variant='h2'>
            This booking is no longer editable.
          </Typography>
          <Typography centered>Please contact the restaurant if there is an issue.</Typography>
        </Spacer>
      </WidgetLayout>
    </PageLayout>
  );
};

export default CancelledBooking;
