import { Box, Divider } from '@eatclub-apps/ec-component-library';
import { CircularProgress, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconDate } from '../../../assets/icons/date.svg';
import { ReactComponent as IconGuests } from '../../../assets/icons/guests.svg';
import { ReactComponent as IconTime } from '../../../assets/icons/time.svg';
import { Button } from '../../../components/Button';
import { Spacer } from '../../../components/Spacer';
import { Typography } from '../../../components/Typography';
import { restaurantsByIdsAction } from '../../../data/actions/restaurantAction';
import { bookingPropTypes, isEditing } from '../../../data/models/Booking';
import { restaurantPropTypes, restaurantsPropTypes } from '../../../data/models/Restaurant';
import { STATUS_TYPES, obeeBookingPropTypes } from '../../../data/models/obeeBooking';
import { obeeMultiVenueAvailabilitySuggestionsPropTypes } from '../../../data/models/obeeMultiVenueAvailabilitySuggestions';
import { PageLayout } from '../../../layouts/PageLayout';
import { WidgetLayout } from '../../../layouts/WidgetLayout';
import {
  formatDateToMoment,
  getFixedGridColumns,
  isEmpty,
  minutesToTimeString,
  onlyUnique,
} from '../../../utils/helpers';
import { useIsMobile } from '../../../utils/hooks';
import { CancelledBooking } from '../../CancelledBooking';
import { Areas } from './Areas';
import useStyles from './AvailabilityStyles';
import { Guests } from './Guests';
import { MultiVenueNextAvailableTimes } from './MultiVenueNextAvailableTimes';
import { NextAvailableTimes } from './NextAvailableTimes';
import { SelectADate } from './SelectADate';

const Availability = ({
  booking,
  obeeMultiVenueAvailabilitySuggestions,
  obeeBooking,
  restaurant,
  restaurants,
  restaurantsByIds,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [multiVenueRestIds, setMultiVenueRestIds] = useState([]);

  const isCancelled = [
    STATUS_TYPES.NO_SHOW,
    STATUS_TYPES.CANCELLED,
    STATUS_TYPES.UNCONFIRMED,
  ].includes(obeeBooking.data.status);

  const isMobile = useIsMobile();

  const showAreas = restaurant.data.areas.length > 1;

  const ref = useRef(null);

  useEffect(() => {
    const newMultiVenueRestIds = obeeMultiVenueAvailabilitySuggestions.data
      .map((availabilitySuggestion) => availabilitySuggestion.restId)
      .filter(onlyUnique);

    setMultiVenueRestIds(newMultiVenueRestIds);
  }, [obeeMultiVenueAvailabilitySuggestions.data]);

  useEffect(() => {
    // check which venues have been cached
    const restIds = multiVenueRestIds.filter((restId) =>
      restaurants.data.every((rest) => rest.objectId !== restId),
    );

    if (restIds.length > 0) {
      restaurantsByIds(restIds);
    }
  }, [multiVenueRestIds]);

  const showMultiVenue = !isEditing(booking) && multiVenueRestIds.length > 0;

  if (isCancelled) {
    return <CancelledBooking />;
  }

  return (
    <PageLayout>
      {(booking.editBooking || booking.editWaitlist) && (
        <WidgetLayout size='small'>
          <Spacer
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{ alignItems: isMobile ? 'flex-start' : 'center' }}
            className={classes.editBooking}
          >
            <Typography variant='small' style={{ fontWeight: theme.fontWeights.bold }}>
              Edit {obeeBooking.data.status === STATUS_TYPES.WAITLIST ? 'waitlist' : 'booking'}
            </Typography>

            <Spacer centered>
              <IconDate fill={restaurant.data.iconBgColour} />
              <Typography variant='small'>
                {formatDateToMoment(obeeBooking.data.date).format('ddd Do of MMM')}
              </Typography>
            </Spacer>
            <Spacer centered>
              <IconGuests fill={restaurant.data.iconBgColour} />
              <Typography variant='small'>{`${obeeBooking.data.size} ${
                obeeBooking.data.size > 1 ? 'guests' : 'guest'
              }`}</Typography>
            </Spacer>
            <Spacer centered>
              <IconTime fill={restaurant.data.iconBgColour} />
              <Typography variant='small'>{`${
                obeeBooking.data.status === STATUS_TYPES.WAITLIST ? 'Requested time' : 'Arrival'
              } ${minutesToTimeString(obeeBooking.data.time)}`}</Typography>
            </Spacer>
          </Spacer>
        </WidgetLayout>
      )}

      <WidgetLayout>
        <Spacer direction='vertical' gap='m' style={{ width: '100%' }}>
          {!isEmpty(restaurant.data.venueMessage) && (
            <>
              <Spacer direction='vertical' gap='m'>
                <Typography centered>
                  <div dangerouslySetInnerHTML={{ __html: restaurant.data.venueMessage }} />
                </Typography>
              </Spacer>

              <Divider color={theme.colors.outlineColour} />
            </>
          )}

          <SelectADate />

          {isMobile ? (
            <Spacer direction='vertical' gap='m'>
              <Guests />

              <Divider color={theme.colors.outlineColour} />

              <Box style={{ ...getFixedGridColumns(showAreas ? 1 : 0, 12) }}>
                {/* note: show areas if there are multiple different areas to choose from */}
                {showAreas && (
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Areas />
                  </Box>
                )}

                {/* <Box */}
                {/*  style={{ */}
                {/*    display: 'flex', */}
                {/*    justifyContent: showAreas ? 'flex-start' : 'center', */}
                {/*  }} */}
                {/* > */}
                {/*  <PreferredTimes /> */}
                {/* </Box> */}
              </Box>
            </Spacer>
          ) : (
            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Box
                style={{
                  ...getFixedGridColumns(showAreas ? 2 : 1, 12),
                  maxWidth: '480px',
                  width: '100%',
                }}
              >
                <Box>
                  <Guests />
                </Box>

                {/* note: show areas if there are multiple different areas to choose from */}
                {showAreas && (
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Areas />
                  </Box>
                )}

                {/* <Box style={{ marginRight: 'auto' }}> */}
                {/*  <PreferredTimes /> */}
                {/* </Box> */}
              </Box>
            </Box>
          )}

          <Divider color={theme.colors.outlineColour} />

          <NextAvailableTimes />
        </Spacer>
      </WidgetLayout>

      {(obeeMultiVenueAvailabilitySuggestions.pending || showMultiVenue) && (
        <Spacer gap='l' style={{ color: restaurant.data.bodyTextColour, margin: '12px' }}>
          {obeeMultiVenueAvailabilitySuggestions.pending && (
            <CircularProgress color='inherit' size={18} />
          )}

          <Typography variant='h3'>
            {obeeMultiVenueAvailabilitySuggestions.pending ? 'Fetching' : 'Discover'} availability
            at our other venues
          </Typography>

          {obeeMultiVenueAvailabilitySuggestions.success && (
            <Button
              variant='outlined'
              style={{
                color: restaurant.data.bodyButtonTextColour,
                borderColor: restaurant.data.bodyButtonBorderColour,
              }}
              onClick={() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })}
            >
              Explore
            </Button>
          )}
        </Spacer>
      )}

      {showMultiVenue && (
        <Box ref={ref}>
          {multiVenueRestIds.map((multiVenueRestId) => (
            <WidgetLayout>
              <Spacer direction='vertical' gap='l' style={{ width: '100%' }}>
                <MultiVenueNextAvailableTimes restId={multiVenueRestId} />
              </Spacer>
            </WidgetLayout>
          ))}
        </Box>
      )}
    </PageLayout>
  );
};

Availability.propTypes = {
  booking: bookingPropTypes.isRequired,
  obeeMultiVenueAvailabilitySuggestions: obeeMultiVenueAvailabilitySuggestionsPropTypes.isRequired,
  obeeBooking: obeeBookingPropTypes.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  restaurants: restaurantsPropTypes.isRequired,
  restaurantsByIds: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeMultiVenueAvailabilitySuggestions: state.obeeMultiVenueAvailabilitySuggestions,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      restaurantsByIds: restaurantsByIdsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
