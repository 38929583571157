import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {},
  innerContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  label: (props) => ({
    // position: 'absolute',
    // pointerEvents: 'none',
    // backgroundColor: 'white',
    ...theme.typography,
    color: theme.colors.secondaryTextColour,
    width: '80px',
    flexShrink: 0,
    marginBottom: 0,
    ...props?.style?.label,
  }),
  placeholder: {
    color: theme.colors.nimbus,
  },
  // Displayed at the bottom
  error: {
    fontSize: '12px',
    color: theme.colors.valencia,
  },
  errorText: {
    fontSize: '12px',
    color: theme.colors.valencia,
    margin: '4px 0 0 90px',
  },
  valueField: (props) => ({
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    border: `1px solid ${theme.colors.outlineColour}`,
    color: props?.disabled ? theme.colors.nimbus : theme.colors.mainTextColour,
    borderRadius: '6px',
    transition: 'background-color 0.1s',
    height: '36px',
    '&:hover': {
      backgroundColor: theme.colors.cloud,
    },
    ...props?.style?.valueField,
  }),
}));

export default useStyles;
