import { makeStyles } from '@material-ui/core';
import { getContrastTextColor } from '../../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '44px',
    position: 'relative',
    width: '100%',
    color: theme.colors?.mainTextColour,
  },
  preferredArea: {
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  selected: {
    backgroundColor: theme.colors.activeFieldsBackground,
    border: `1px solid ${theme.colors.charcoal}`,
    color: getContrastTextColor(theme.colors.activeFieldsBackground),
  },
}));

export default useStyles;
