import { Buffer } from 'buffer';
import moment from 'moment';
import packageInfo from '../../package.json';
import { colors } from '../theme';

/**
 *
 * @returns the app version for use in API calls
 */
export const getAppVersion = () => {
  return `web_${packageInfo.version.substring(0, packageInfo.version.lastIndexOf('.'))}`;
};

/**
 *
 * @param {string} hex
 * @param {number} limit
 * @returns true if the hex brightness value is greater than the limit
 */
export const isBright = (hex, limit = 150) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})(?:[a-f\d]{2})?$/i.exec(hex);
  if (result) {
    // 0.2126 R + 0.7152 G + 0.0722 B
    // console.log((0.2126 * parseInt(result[1], 16)) + (0.7152 * parseInt(result[2], 16)) + (0.0722 * parseInt(result[3], 16)))
    return (
      0.2126 * parseInt(result[1], 16) +
        0.7152 * parseInt(result[2], 16) +
        0.0722 * parseInt(result[3], 16) >
      limit
    );
  }
  return true;
};

export const getContrastTextColor = (color) => {
  if (isBright(color)) {
    return colors.charcoal;
  }

  return colors.white;
};

/**
 *
 * @param {number} minutes
 * @param {string} format
 * @returns e.g. 600 is formatted to 10:00pm (w/ default format). See https://momentjs.com/docs/#/displaying/format/ for different formats
 */
export const minutesToTimeString = (minutes, format = 'h:mma') => {
  // const timeAsInt = parseInt(minutes, 10);

  let timeAsInt = minutes;

  if (typeof minutes === 'string') {
    timeAsInt = parseInt(minutes, 10);
  }

  const hours = Math.floor(timeAsInt / 60);
  const mins = timeAsInt % 60;

  const bookingMoment = moment(`${hours}:${mins}`, 'h:m').format(format);

  return bookingMoment;

  // Bad - some days don't have 24 hours
  // moment().startOf('day').add(parseInt(minutes, 10), 'minutes').format(format);
};

export const timeStringToTime = (timeString, fromFormat = 'H:mma') => {
  return moment(timeString, fromFormat);
};

/**
 *
 * @param {*} arr
 * @param {*} key
 * @returns an array of objects based on a unique key property
 */
export const unique = (arr, key) => {
  const keys = new Set();
  return arr?.filter((el) => !keys.has(el[key]) && keys.add(el[key]));
};

/**
 * Filter the array to just the unique values
 */
export const filterArrayUnique = (arr) => {
  return arr.filter((value, index) => arr.indexOf(value) === index);
};

/**
 *
 * @param {*} a
 * @param {*} b
 * @param {*} property
 * @returns sorted array of objects based on the property supplied
 */
export const sortByProperty = (a, b, property) => {
  if (a[property] < b[property]) {
    return -1;
  }
  if (a[property] > b[property]) {
    return 1;
  }
  return 0;
};

export const formatIntFromTime = (time) => {
  const hours = parseInt(time.format('H'), 10);
  const minutes = parseInt(time.format('m'), 10);

  return hours * 60 + minutes;
};

/**
 * https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 */
export const shadeHex = (color, percent) => {
  if (!color) {
    return '';
  }

  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const isNil = (item) => {
  if (item === null || item === undefined) {
    return true;
  }
  return false;
};

export const isEmptyObject = (item) => {
  if (Object.getPrototypeOf(item) === Object.prototype) {
    return item && Object.keys(item).length === 0;
  }
  return false;
};

export const isEmptyArray = (item) => {
  if (item.constructor === Array) {
    return item.length === 0;
  }
  return false;
};

export const isEmptyString = (item) => {
  if (typeof item === 'string') {
    return item === '';
  }
  return false;
};

/**
 * Check for both null and undefined
 * */
export const isVoid = (item) => item === null || item === undefined;

/**
 *
 * @param {*} item
 * @returns {boolean}
 */
export const isEmpty = (item) => {
  if (isNil(item)) {
    return true;
  }

  if (isEmptyObject(item)) {
    return true;
  }

  if (isEmptyArray(item)) {
    return true;
  }

  return isEmptyString(item);
};

/**
 * Format moment to readable format
 * @param {moment} momentToFormat
 * @param {string=} formatToUse
 * @returns
 */
export const formatMomentToDate = (
  momentToFormat,
  formatToUse = process.env.REACT_APP_DATE_FORMAT,
) => momentToFormat.format(formatToUse);

/**
 * Format date to moment object
 * @param {string} momentToFormat
 * @param {string=} formatToUse
 * @returns
 */
export const formatDateToMoment = (dateToFormat, formatToUse = process.env.REACT_APP_DATE_FORMAT) =>
  moment(dateToFormat, formatToUse);

/**
 * Han decoder
 * @param {string} cipherText
 * @returns {string}
 */
export const decode = async (cipherText) => {
  const ciphertextBuffer = Buffer.from(cipherText, 'base64');
  const rawKey = Buffer.from(process.env.REACT_APP_DECODE_KEY, 'base64');

  const key = await window.crypto.subtle.importKey(
    'raw',
    rawKey,
    {
      name: 'AES-GCM',
    },
    false,
    ['decrypt'],
  );

  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: new Uint8Array(ciphertextBuffer.slice(0, 12)),
      tagLength: 128,
    },
    key,
    new Uint8Array(ciphertextBuffer.slice(12)),
  );

  return new TextDecoder('utf-8').decode(new Uint8Array(decrypted));
};

export const extract = (items, key) => {
  return items.find((item) => item?.[0] === key)?.[1];
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

/**
 * Finds the first object in the list where the given property matches the value
 * @param objects
 * @param id
 * @param property
 * @param fallbackObject
 * @returns {null|*}
 */
export const findObjectByProperty = (objects, id, property = 'objectId', fallbackObject = null) => {
  if (isEmpty(id)) {
    return fallbackObject;
  }

  return objects?.find((obj) => obj?.[property] === id) || fallbackObject;
};

export const findObjectIndexByProperty = (objects, id, property = 'objectId') =>
  objects?.findIndex((obj) => obj?.[property] === id);

/**
 * Group a list of objects by the property of that object.
 * e.g. group by an id
 * @param objects
 * @param groupBy
 * @param allowMultiple
 * @returns {*}
 */
export const groupObjects = (objects, groupBy, allowMultiple = true) =>
  objects?.reduce((accum, object) => {
    const updated = { ...accum };
    if (allowMultiple) {
      if (Object.hasOwnProperty.call(updated, object[groupBy])) {
        updated[object[groupBy]].push(object);
      } else {
        updated[object[groupBy]] = [object];
      }
    } else {
      updated[object[groupBy]] = object;
    }

    return updated;
  }, {});

/**
 * Group a list of objects by that property
 * TODO this name is not an accurate description of what it does. You may want groupObjects instead
 */
export const groupByProperty = (arr, property, propertyName = 'name') => {
  let groupedObjects = []; // array rather than object, to preserve order

  arr?.forEach((item) => {
    const index = findObjectIndexByProperty(groupedObjects, item?.[property], propertyName);

    // Add new if doesn't exist
    if (index < 0) {
      const newItem = { [propertyName]: item?.[property], items: [item] };
      groupedObjects = [...groupedObjects, newItem];
    } else {
      const newItems = [...groupedObjects?.[index]?.items, item];
      groupedObjects[index] = { ...groupedObjects?.[index], items: newItems };
    }
  });

  return groupedObjects;
};

export const nullIfNegative = (value) => (value < 0 ? null : value);

export const snakeCase = (string) =>
  string
    ?.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');

export const makeChunks = (array, chunkSize = 10) => {
  let chunks = [];

  if (chunkSize <= 0) {
    return array;
  }

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks = [...chunks, chunk];
  }

  return chunks;
};

export const pluralise = (string, number, pluralString) => {
  if (number === 1) {
    return string;
  }

  if (pluralString) {
    return pluralString;
  }

  return `${string}s`;
};

export const onEnter = (ev, callback) => {
  if (ev.key === 'Enter') {
    // Do code here
    ev.preventDefault();
    callback();
  }
};

export const range = (start, stop, step = 1, inclusive = true) =>
  Array(Math.ceil((stop - start) / step) + (inclusive ? 1 : 0))
    .fill(start)
    .map((x, y) => x + y * step);

export const setPageTitle = (restaurantName, newTitle = null) => {
  const pageTitle = newTitle || 'Make a booking';
  const titleDelimiter = pageTitle !== '' ? ' - ' : '';
  window.document.title = [restaurantName, pageTitle]
    .filter((part) => !isEmpty(part))
    .join(titleDelimiter);
};

export const setFavicon = (faviconUrl) => {
  if (isEmpty(faviconUrl)) {
    return;
  }

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = faviconUrl;
};

export const setPathname = (slug) => {
  if (isNil(slug)) {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search).toString();

  let urlWithParams = `/${slug}`;

  if (!isEmpty(searchParams)) {
    urlWithParams += `?${searchParams.toString()}`;
  }

  window.history.pushState({ path: urlWithParams }, '', urlWithParams);
};

/**
 * Gets the min value in an array of objects by property
 * @param {{}[]} array
 * @param {string} property
 * @returns {any}
 */
export const getMinInArrayOfObjects = (array, property) => {
  const min = array.reduce((prev, current) => {
    return prev[property] < current[property] ? prev : current;
  })[property];

  return min;
};

/**
 * Gets the max value in an array of objects by property
 * @param {{}[]} array
 * @param {string} property
 * @returns {any}
 */
export const getMaxInArrayOfObjects = (array, property) => {
  const max = array.reduce((prev, current) => {
    return prev[property] > current[property] ? prev : current;
  })[property];

  return max;
};

/**
 * Gets the min AND max value in an array of objects by property
 * @param {{}[]} array
 * @param {string} property
 * @returns {{ min; max }}
 */
export const getMinMaxInArrayOfObjects = (array, minProperty, maxProperty) => {
  const min = getMinInArrayOfObjects(array, minProperty);
  const max = getMaxInArrayOfObjects(array, maxProperty);

  return { min, max };
};

/**
 * Get the nearest object to the key value
 * @param {{}[]} array
 * @param {string} key
 * @param {*} value
 * @param {"<"|">"|null} condition "<" indicates get the nearest less than, ">" indicates get the nearest greater than. Defaults to null (just get the nearest)
 * @returns
 */
export const getNearestInArrayOfObjects = (array, key, value, condition = null) => {
  const nearest = array.reduce((acc, obj) => {
    let cond = true;

    if (condition === '<') {
      cond = obj[key] < value;
    }

    if (condition === '>') {
      cond = obj[key] > value;
    }

    return cond && Math.abs(value - obj[key]) < Math.abs(value - acc[key]) ? obj : acc;
  });

  return nearest;
};

export const safeDivision = (numerator, denominator) => {
  if (!numerator) {
    return 0;
  }

  if (!denominator) {
    return numerator;
  }

  return numerator / denominator;
};

/**
 * Returns a moment object to the next nearest minute interval
 */
export const nextNearestMinutes = (time, interval) => {
  const next = time.clone().add(interval, 'minutes');

  return next.minutes(Math.floor(safeDivision(next.minutes(), interval)) * interval);
};

export const noop = () => {};

/**
 * Gets grid styles with fixed column widths based on the number of columns and gap
 */
export const getFixedGridColumns = (numCols, gap) => {
  const gridTemplateColumns = `repeat(${numCols}, ${`calc(${100 / numCols}% - ${
    (gap * (numCols - 1)) / numCols
  }px)`})`;

  return {
    display: 'grid',
    gridTemplateColumns,
    gap: `${gap}px`,
  };
};

export const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null;
};

/**
 * https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#4-deep-equality
 * @param {*} object1
 * @param {*} object2
 * @returns
 */
export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  return keys1.every((key) => {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
    return true;
  });
};

export const isBetween = (num, start, end, inclusive = false) => {
  if (inclusive) {
    return num >= start && num <= end;
  }

  return num > start && num < end;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const calcHexTransparency = (hex, percentage = 100) => {
  let percentageHex = Math.floor((clamp(percentage, 0, 100) / 100) * 255).toString(16);

  if (percentageHex.length === 0) {
    percentageHex = `0${percentageHex}`;
  }

  return `${hex}${percentageHex}`;
};

export const trim = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.trim();
};

/**
 * Formats a number to a currency
 */
export const formatCurrency = (
  value,
  useDecimals = true,
  invalidValue = '$0',
  isInCents = false,
) => {
  if (value === null || Number.isNaN(value)) {
    return invalidValue;
  }

  let newValue = `${isInCents ? value / 100 : value}`;
  if (newValue.includes('$')) {
    newValue = newValue.replace('$', '');
  }

  const parsedValue = parseFloat(newValue);
  if (Number.isNaN(parsedValue)) {
    return invalidValue;
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: useDecimals ? 2 : 0,
    maximumFractionDigits: useDecimals ? 2 : 0,
  });

  return formatter.format(parsedValue).toLocaleString();
};

/**
 * Whether the booking starts in the session
 * @param booking
 * @param session
 * @returns {boolean}
 */
export const bookingStartsInSession = (booking, session) => {
  return (
    (booking?.areaId === session?.areaId || isVoid(session?.areaId) || booking?.areaId === 'all') && // global sessions don't have an areaId
    booking.startTime < session.endTime &&
    booking.startTime >= session.startTime
  );
};

/**
 * Whether the booking overlaps the session
 * @param booking
 * @param session
 * @returns {boolean}
 */
export const bookingIsInSession = (booking, session) => {
  return (
    (booking?.areaId === session?.areaId || isVoid(session?.areaId) || booking?.areaId === 'all') && // global sessions don't have an areaId
    booking.startTime < session.endTime &&
    booking.endTime > session.startTime
  );
};

/** Returns the FIRST session that this booking belongs to
 *
 * @param booking
 * @param sessions
 * @param startOnly The booking starts in the session, rather than overlaps the session
 */
export const getSessionForBooking = (booking, sessions, startOnly = true) => {
  if (startOnly) {
    return sessions.find((session) => bookingStartsInSession(booking, session));
  }

  return sessions.find((session) => bookingIsInSession(booking, session));
};

export const getSessionEndTime = (areas, areaId, time) => {
  const sessionsForArea = findObjectByProperty(areas, areaId, 'id')?.sessions || [];
  const sessionForTime = getSessionForBooking({ startTime: time, areaId }, sessionsForArea, true);

  return sessionForTime?.endTime;
};

export const getBookingDuration = (area, areas, time) => {
  const duration = area?.duration;

  // 0 is a custom amount that means "til end of session"
  if (duration === 0) {
    return Math.max(15, getSessionEndTime(areas, area?.id, time) - time);
  }

  return duration;
};

/**
 * Generate a random ID.
 * Useful for keys when mapping
 * @param length
 * @returns {string}
 */
export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};

/**
 * Updates all objects that match the given property.
 * Returns the updated array with those items replaced.
 *
 * NOTE: Does not try to update anything with a null value for the property
 * to prevent accidentally wiping multiple records
 */
export const updateObjectByProperty = (
  objects,
  updatedObject,
  property = 'objectId',
  merge = false,
) =>
  objects?.map((obj) => {
    if (!isVoid(updatedObject?.[property]) && obj?.[property] === updatedObject?.[property]) {
      if (merge) {
        return { ...obj, ...updatedObject };
      }

      return updatedObject;
    }

    return obj;
  });

/**
 * Join an array of strings, nicely
 * @param arr
 * @param delimiter
 * @param finalDelimiter
 * @returns {string|*[]|*}
 */
export const joinList = (arr, delimiter = ', ', finalDelimiter = ' & ') => {
  if (!Array.isArray(arr) || isEmpty(arr)) {
    return [];
  }

  const stringItems = arr?.filter((item) => !isEmpty(item))?.map((item) => `${item}`.trim());

  if (!isEmpty(finalDelimiter) && stringItems.length > 1) {
    const allButLast = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return allButLast.join(delimiter) + finalDelimiter + last;
  }

  return stringItems.join(delimiter);
};

/**
 * Does a flatten, but expects each item to be an array
 * Returns a flat list of all of those array items combined
 */
export const flattenArrayValues = (arr, key = 'id') => {
  if (!arr?.length) {
    return [];
  }

  let newList = [];

  arr?.forEach((item) => {
    if (Array.isArray(item?.[key])) {
      newList = [...newList, ...item?.[key]];
    }
  });

  return newList;
};

/**
 * Immutable deep merge.
 * Adapted from https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */
export const mergeDeep = (target, source, depth = 0, maxDepth = 20) => {
  const output = { ...target };
  if (depth < maxDepth && isObject(target) && isObject(source)) {
    // To prevent infinite recursion
    const newDepth = depth + 1;
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        // If we have a matching key, merge them
        if (!(key in target)) {
          // Key does not exist in target, so add it as new
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key], newDepth, maxDepth);
        }
      } else {
        // Not an object, so replace
        // TODO merging two lists should try to add list2 to list1 instead of replacing
        Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output;
};
