import PropTypes from 'prop-types';

export const bookingTermsPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    terms_exist: PropTypes.bool,
    daily_view_display: PropTypes.string,
    terms_conditions: PropTypes.string,
  }),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
  lastUsedVariables: PropTypes.shape({
    restId: PropTypes.string,
    areaId: PropTypes.number,
    date: PropTypes.string,
    time: PropTypes.number,
    size: PropTypes.number,
  }),
});
