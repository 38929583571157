import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Spacer } from '../../../../components/Spacer';
import { Typography } from '../../../../components/Typography';

/**
 * An item with a title, description and icon
 * TODO support dark mode (currently the need help section doesn't)
 * @param Icon
 * @param description
 * @param heading
 * @param show
 * @returns {JSX.Element}
 * @constructor
 */
const HelpfulInfoItem = ({ Icon, description, heading, show }) => {
  const theme = useTheme();

  if (!show) {
    return <></>;
  }

  return (
    <Spacer gap='m'>
      {React.cloneElement(Icon, { style: { flexShrink: 0, height: '40px', width: '40px' } })}

      <Spacer direction='vertical' gap={2}>
        <Typography variant='h3' style={{ fontWeight: theme.fontWeights.bold }}>
          {heading}
        </Typography>
        {description && (
          <Typography style={{ color: theme.colors.smoke }}>{description}</Typography>
        )}
      </Spacer>
    </Spacer>
  );
};

HelpfulInfoItem.defaultProps = {
  description: null,
};

HelpfulInfoItem.propTypes = {
  Icon: PropTypes.node.isRequired,
  description: PropTypes.string,
  heading: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default HelpfulInfoItem;
