import { useTheme } from '@material-ui/core';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconDate } from '../../../../../assets/icons/date.svg';
import { ReactComponent as IconGuests } from '../../../../../assets/icons/guests.svg';
import { ReactComponent as IconTime } from '../../../../../assets/icons/time.svg';
import { Button } from '../../../../../components/Button';
import { Modal } from '../../../../../components/Modal';
import { Spacer } from '../../../../../components/Spacer';
import { Typography } from '../../../../../components/Typography';
import {
  updateBookingAction,
  updateBookingStepAction,
} from '../../../../../data/actions/bookingAction';
import { bookingKeys, bookingPropTypes, bookingSteps } from '../../../../../data/models/Booking';
import { restaurantPropTypes } from '../../../../../data/models/Restaurant';
import { formatDateToMoment, getMinMaxInArrayOfObjects, range } from '../../../../../utils/helpers';
import { useIsMobile } from '../../../../../utils/hooks';
import { Carousel } from '../../Carousel';

const JoinWaitlistModal = ({
  booking,
  close,
  open,
  restaurant,
  updateBooking,
  updateBookingStep,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const allTimes = useMemo(() => {
    const sessions = restaurant.data.areas
      .map((area) => area.sessions)
      .flat()
      .filter((session) => {
        if (booking.areaId !== -1) {
          // note: filter times list based on area
          return session.areaId === booking.areaId;
        }

        return true;
      })
      .filter((session) => {
        if (booking.sessionId !== -1) {
          // note: filter times list based on session
          return session.id === booking.sessionId;
        }

        if (booking.date) {
          // note: filter times list based on day
          return (
            session.day === moment(booking.date).format('ddd').toLowerCase() &&
            !(session?.startTime === 0 && session?.endTime === 0)
          );
        }

        return true;
      });

    const { min, max } =
      sessions.length > 0
        ? getMinMaxInArrayOfObjects(sessions, 'startTime', 'endTime')
        : { min: 300, max: 1440 }; // fallback for loading state or no sessions exist for the venue

    const times = range(min, max, 15, false);

    // note: -1 is "All times"
    return times.map((time) => ({ time }));
  }, [booking.areaId, booking.date, booking.sessionId, restaurant.data.areas]);

  return (
    <Modal
      open={open}
      onClose={() => {
        updateBooking(bookingKeys.availabilitySuggestion, null);
        close();
      }}
      title='Join the waitlist'
      footer={[
        <Button
          size='large'
          variant='outlined'
          onClick={() => {
            close();
          }}
          fullWidth={isMobile}
        >
          Cancel
        </Button>,
        <Button
          size='large'
          onClick={() => {
            updateBooking('waitlist', true);
            updateBookingStep(bookingSteps.details);
          }}
          fullWidth={isMobile}
          color='primary'
        >
          Next: Add contact details
        </Button>,
      ]}
    >
      <Spacer direction='vertical' gap={40}>
        <Spacer direction='vertical' gap={24}>
          <Spacer direction='vertical'>
            <Typography style={{ fontWeight: theme.fontWeights.medium }}>
              {restaurant.data.name}
            </Typography>
            <Typography>{restaurant.data.address}</Typography>
          </Spacer>
          <Spacer gap='m' direction={isMobile ? 'vertical' : 'horizontal'}>
            <Spacer style={{ alignItems: 'center' }} gap='s'>
              <IconDate fill={restaurant.data.iconBgColour} />
              <Typography>{formatDateToMoment(booking.date).format('dddd, Do of MMMM')}</Typography>
            </Spacer>
            <Spacer style={{ alignItems: 'center' }} gap='s'>
              <IconGuests fill={restaurant.data.iconBgColour} />
              <Typography>
                {`${booking.guests} ${booking.guests > 1 ? 'guests' : 'guest'}`}
              </Typography>
            </Spacer>
            {booking.sessionId !== -1 && (
              <Spacer style={{ alignItems: 'center' }} gap='s'>
                <IconTime fill={restaurant.data.iconBgColour} />
                <Typography>{booking.sessionLabel}</Typography>
              </Spacer>
            )}
          </Spacer>
        </Spacer>
        <Spacer direction='vertical' gap={24}>
          <Typography variant='h3'>Select preferred time</Typography>

          <Carousel
            rows={isMobile ? 4 : 2}
            columns={isMobile ? 2 : 4}
            items={allTimes}
            mobileButtonsOnly
            skipAreaSelection
          />
        </Spacer>
      </Spacer>
    </Modal>
  );
};

JoinWaitlistModal.defaultProps = {
  open: false,
};

JoinWaitlistModal.propTypes = {
  booking: bookingPropTypes.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  restaurant: restaurantPropTypes.isRequired,
  updateBooking: PropTypes.func.isRequired,
  updateBookingStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  booking: state.booking,
  obeeBooking: state.obeeBooking,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBooking: updateBookingAction,
      updateBookingStep: updateBookingStepAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(JoinWaitlistModal);
