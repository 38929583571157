import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: ({ centered, variant }) => ({
    margin: 0,
    ...theme.baseTypography,
    ...theme.typography[variant],
    textAlign: centered ? 'center' : '',
  }),
}));
