import { Box } from '@eatclub-apps/ec-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './PinFieldStyles';

/**
 * Text that looks like an input
 * @param style
 * @param label
 * @param id
 * @param error
 * @returns {JSX.Element}
 * @constructor
 */
export const PinField = ({ label, id, error, style }) => {
  const classes = useStyles({ style });

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        {label && <Box className={classes.label}>{label}</Box>}
        <Box className={classes.valueField}>
          <div id={id} />
        </Box>
      </Box>

      {error && <Box className={classes.error}>{error}</Box>}
      {/* <div id={`errors_for_${id}`} className='error_message' /> */}
    </Box>
  );
};

PinField.defaultProps = {
  label: '',
  id: '',
  error: null,
  style: {},
};

PinField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  style: PropTypes.shape({}),
};
